import crypto from 'crypto'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'

import {
  AppLayout,
  createProviderStack,
  GlobalTooltip,
} from '@lattice/common/components'
import {
  ToastProvider,
  TokenPriceProvider,
  WalletProvider,
  UserProvider,
  RewardsHistoryProvider,
  NodeManagerProvider,
  SoftNodeProvider,
  SoftStakingProvider,
  DtmNodesProvider,
  RewardsProgramsProvider,
  LaunchpadProvider,
  OnChainStakingProvider,
  TestnetValidatorsProvider,
  AirdropsProvider,
  BountiesProvider,
  DebugDataProvider,
  UserOAuthProvider,
  LiquidityStakingProvider,
  HgtpMetagraphsProvider,
  WagmiProvider,
  TanStackQueryProvider,
  ConfirmActionProvider,
} from '@lattice/common/providers'
import {
  initializeDag4Lib,
  initializei18nextLib,
  initializeDayjsLib,
  buildWeb3Library,
  initializeReactGaLib,
} from '@lattice/utils'
import { routes } from '@lattice/views'
import '@lattice/common/styles/global.css'

import { reportWebVitals } from './reportWebVitals'
import { RuntimeErrorView } from './views/RuntimeErrorView'
import { IntegrationsNetProvider } from './common/providers/IntegrationsNetProvider'

document.documentElement.className = ''
document.documentElement.classList.add(`theme--dark`)

initializeDag4Lib()
initializei18nextLib()
initializeDayjsLib()
initializeReactGaLib()

const AppCoreProviderStack = createProviderStack()

AppCoreProviderStack.addProvider(ToastProvider, {})
AppCoreProviderStack.addProvider(ConfirmActionProvider, {})
AppCoreProviderStack.addProvider(WagmiProvider, {})
AppCoreProviderStack.addProvider(TanStackQueryProvider, {})
AppCoreProviderStack.addProvider(Web3ReactProvider, {
  getLibrary: buildWeb3Library,
})
AppCoreProviderStack.addProvider(TokenPriceProvider, {})
AppCoreProviderStack.addProvider(UserProvider, {})
AppCoreProviderStack.addProvider(WalletProvider, {})
// Must always be last
AppCoreProviderStack.addProvider(DebugDataProvider, {})

const AppRoutedProviderStack = createProviderStack()
AppRoutedProviderStack.addProvider(UserOAuthProvider, {})
AppRoutedProviderStack.addProvider(RewardsHistoryProvider, {})
AppRoutedProviderStack.addProvider(SoftNodeProvider, {})
AppRoutedProviderStack.addProvider(OnChainStakingProvider, {})
AppRoutedProviderStack.addProvider(NodeManagerProvider, {})
AppRoutedProviderStack.addProvider(SoftStakingProvider, {})
AppRoutedProviderStack.addProvider(DtmNodesProvider, {})
AppRoutedProviderStack.addProvider(RewardsProgramsProvider, {})
AppRoutedProviderStack.addProvider(LaunchpadProvider, {})
AppRoutedProviderStack.addProvider(NodeManagerProvider, {})
AppRoutedProviderStack.addProvider(TestnetValidatorsProvider, {})
AppRoutedProviderStack.addProvider(AirdropsProvider, {})
AppRoutedProviderStack.addProvider(BountiesProvider, {})
AppRoutedProviderStack.addProvider(LiquidityStakingProvider, {})
AppRoutedProviderStack.addProvider(IntegrationsNetProvider, {})
AppRoutedProviderStack.addProvider(HgtpMetagraphsProvider, {})

/**
 * @todo
 * TODO
 * Initialize Google Analytics
 */

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AppRoutedProviderStack>
        <GlobalTooltip />
        <AppLayout />
      </AppRoutedProviderStack>
    ),
    errorElement: <RuntimeErrorView />,
    children: [
      { element: <Navigate to="dashboard" />, index: true },
      ...routes,
    ],
  },
])

const root = createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <AppCoreProviderStack>
      <RouterProvider router={router} />
    </AppCoreProviderStack>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// @ts-ignore
window.test1725 = { crypto, Buffer }
