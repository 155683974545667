import { useMemo } from 'react'
import { ethers } from 'ethers'
import { Account, Chain, Client, Transport } from 'viem'
import { Config, useConnectorClient, Connector } from 'wagmi'
import { stargazerWalletWagmiConnector } from '@stardust-collective/web3-react-stargazer-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { useWeb3React as useWeb3ReactHook } from '@web3-react/core'

export const clientToProvider = (client: Client<Transport, Chain, Account>) => {
  const { chain, transport } = client
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  }
  const provider = new ethers.providers.Web3Provider(transport, network)
  return provider
}

/** Hook to convert a Viem Client to an ethers.js Signer. */
export const useEthersConnectorProvider = ({
  chainId,
}: { chainId?: number } = {}) => {
  const { data: client } = useConnectorClient<Config>({ chainId })
  return useMemo(
    () => (client ? clientToProvider(client) : undefined),
    [client]
  )
}

export const isWalletRejection = (e: any): boolean => {
  if (
    /* MetaMask */
    e.message &&
    /MetaMask Message Signature: User denied message signature/i.test(e.message)
  ) {
    return true
  } else if (
    /* Stargazer */
    e.message &&
    /User Rejected Request/i.test(e.message) &&
    e.name &&
    /Stargazer Wallet Error/i.test(e.name)
  ) {
    return true
  } else if (
    /* WalletConnect : MetaMask Mobile */
    e.message &&
    /MetaMask Personal Message Signature: User denied message signature/i.test(
      e.message
    )
  ) {
    return true
  } else if (
    /* Coinbase Wallet */
    e.message &&
    /User denied message signature/i.test(e.message)
  ) {
    return true
  } else if (
    /* Fortmatic Wallet */
    e.message &&
    /Fortmatic: User denied signing/i.test(e.message)
  ) {
    return true
  } else if (
    /* Portis Wallet */
    e &&
    /User denied message signature/i.test(e)
  ) {
    return true
  }
  return false
}

export const signMessageByAlternatives = async (
  signer: ethers.providers.JsonRpcSigner,
  message: string,
  account: string,
  connector: Connector
): Promise<string> => {
  const alternatives: [string, () => Promise<string>][] = []
  alternatives.push([
    'PersonalSign v2',
    async () => {
      if (connector.id === stargazerWalletWagmiConnector.id) {
        throw new Error(
          "StargazerConnector should not sign with 'PersonalSign v2'"
        )
      }
      if (connector instanceof PortisConnector) {
        message = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
      }
      return await signer.provider.send('personal_sign', [message, account])
    },
  ])
  alternatives.push([
    'PersonalSign v1',
    async () => {
      return await signer.provider.send('personal_sign', [account, message])
    },
  ])
  alternatives.push([
    'EthSign v1',
    async () => {
      return await signer.provider.send('eth_sign', [account, message])
    },
  ])

  for (const [name, alternative] of alternatives) {
    try {
      return await alternative()
    } catch (e) {
      if (isWalletRejection(e)) {
        throw new Error('User rejected the request')
      }
      console.log(`Unable to request signature by '${name}'`)
      console.log(e)
      console.dir(e)
    }
  }

  throw new Error(`Unable to sign message by alternatives`)
}

export const buildWeb3Library = (
  provider:
    | ethers.providers.ExternalProvider
    | ethers.providers.JsonRpcFetchFunc
): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

export const useWeb3React = (...args: Parameters<typeof useWeb3ReactHook>) =>
  useWeb3ReactHook<ethers.providers.Web3Provider>(...args)
