import { EvmChain, EvmContractProtocol } from '../web3'

import { RegisteredToken } from './consts'
import { IRegisteredTokenData } from './types'
import { buildUnavailableContractInstances } from './utils'

export const RegisteredTokens: Record<RegisteredToken, IRegisteredTokenData> = {
  [RegisteredToken.DTM]: {
    tokenProtocol: EvmContractProtocol.ERC721,
    decimals: 0,
    instances: {
      [EvmChain.ETHEREUM_MAINNET]: {
        chain: EvmChain.ETHEREUM_MAINNET,
        address: '0x9c21573d2cd8885a9221206dc3e7cf1d18d39998',
      },
      [EvmChain.ETHEREUM_TESTNET]: {
        chain: EvmChain.ETHEREUM_TESTNET,
        address: '0xef3275d3b794e7868a9b1569dac1ccaf220f515d',
      },
      ...buildUnavailableContractInstances(
        EvmChain.POLYGON_MAINNET,
        EvmChain.POLYGON_TESTNET,
        EvmChain.CONSTELLATION_MAINNET,
        EvmChain.CONSTELLATION_TESTNET
      ),
    },
  },
  [RegisteredToken.LTX]: {
    tokenProtocol: EvmContractProtocol.ERC20,
    decimals: 8,
    instances: {
      [EvmChain.ETHEREUM_MAINNET]: {
        chain: EvmChain.ETHEREUM_MAINNET,
        address: '0xa393473d64d2f9f026b60b6df7859a689715d092',
      },
      [EvmChain.ETHEREUM_TESTNET]: {
        chain: EvmChain.ETHEREUM_TESTNET,
        address: '0x40C83Dda7dd7CdDe18eabbBdEe0B1d4F033721b5',
      },
      ...buildUnavailableContractInstances(
        EvmChain.POLYGON_MAINNET,
        EvmChain.POLYGON_TESTNET,
        EvmChain.CONSTELLATION_MAINNET,
        EvmChain.CONSTELLATION_TESTNET
      ),
    },
  },
  [RegisteredToken.OBS]: {
    tokenProtocol: EvmContractProtocol.ERC20,
    decimals: 16,
    instances: {
      [EvmChain.ETHEREUM_MAINNET]: {
        chain: EvmChain.ETHEREUM_MAINNET,
        address: '0xafd9268fdfebee905f7439b12c943bc18ad293c2',
      },
      ...buildUnavailableContractInstances(
        EvmChain.ETHEREUM_TESTNET,
        EvmChain.POLYGON_MAINNET,
        EvmChain.POLYGON_TESTNET,
        EvmChain.CONSTELLATION_MAINNET,
        EvmChain.CONSTELLATION_TESTNET
      ),
    },
  },
  [RegisteredToken.veLTX]: {
    tokenProtocol: EvmContractProtocol.ERC20,
    decimals: 18,
    instances: {
      [EvmChain.ETHEREUM_MAINNET]: {
        chain: EvmChain.ETHEREUM_MAINNET,
        address: '0xc6a22cc9acd40b4f31467a3580d4d69c3387f349',
      },
      [EvmChain.ETHEREUM_TESTNET]: {
        chain: EvmChain.ETHEREUM_TESTNET,
        address: '0x9F89d9A1Ffe7B1414ebb9A11Cd266ACd7dA0521b',
      },
      ...buildUnavailableContractInstances(
        EvmChain.POLYGON_MAINNET,
        EvmChain.POLYGON_TESTNET,
        EvmChain.CONSTELLATION_MAINNET,
        EvmChain.CONSTELLATION_TESTNET
      ),
    },
  },
}
