import { apiRequest, APIError, CurrencyNetwork } from '@lattice/common/lib'

import { IWalletOwnershipToken } from '../WalletProvider'

import { IHgtpMetagraph, IHgtpNode } from './types'
import { DeploymentHgtpQueueMetagraphs, HgtpQueueMetagraph } from './consts'

export const requestMetagraph = async (metagraph: HgtpQueueMetagraph) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/hgtp-metagraphs/${DeploymentHgtpQueueMetagraphs[metagraph]}`,
      isAuthenticated: true,
    })
    return data as IHgtpMetagraph
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const requestNode = async (metagraph: HgtpQueueMetagraph) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/hgtp-metagraphs/${DeploymentHgtpQueueMetagraphs[metagraph]}/node`,
      isAuthenticated: true,
    })
    return data as IHgtpNode
  } catch (err) {
    console.log(err)
    if (err instanceof APIError) {
      return null
    }
    throw err
  }
}

export const executePostNode = async (
  metagraph: HgtpQueueMetagraph,
  body: {
    discordHandle: string
    network: CurrencyNetwork
    walletOwnershipToken: IWalletOwnershipToken
    address: string
  }
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/hgtp-metagraphs/${DeploymentHgtpQueueMetagraphs[metagraph]}/node`,
      body,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const executeDeleteNode = async (metagraph: HgtpQueueMetagraph) => {
  try {
    await apiRequest({
      method: 'DELETE',
      endpoint: `/hgtp-metagraphs/${DeploymentHgtpQueueMetagraphs[metagraph]}/node`,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const executePostNodeConfirmOperator = async (
  metagraph: HgtpQueueMetagraph,
  body: {
    operatorNodeId: string
    network: CurrencyNetwork
    walletOwnershipToken: IWalletOwnershipToken
    address: string
  }
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/hgtp-metagraphs/${DeploymentHgtpQueueMetagraphs[metagraph]}/node/confirm-operator`,
      body,
      isAuthenticated: true,
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}
