import * as ethers from 'ethers'

import { EthereumInterfaces } from '@lattice/common/consts'

import { EvmNetwork } from './consts'
import { getNetworkProvider } from './providers'

export const getNetworkTypedContract = <
  T extends keyof EthereumInterfaces.ContractTypes,
>(
  interfaceName: T,
  address: string,
  network: EvmNetwork
): EthereumInterfaces.ContractTypes[T] => {
  const contractABI = EthereumInterfaces.ContractABIs[interfaceName]
  if (contractABI) {
    return new ethers.Contract(
      address,
      contractABI,
      getNetworkProvider(network)
    ) as EthereumInterfaces.ContractTypes[T]
  } else {
    throw new Error(
      `Unable to find contract with interface name '${String(interfaceName)}'`
    )
  }
}
