import { DeploymentStage, EnvironmentContext } from '@lattice/runtime'

export enum HgtpMetagraphNodeOperatorStatus {
  REMOVED = 'removed',
  IN_QUEUE = 'in_queue',
  OPERATOR_REGISTERED = 'operator_registered',
  WHITELIST_OPERATOR = 'whitelist_operator',
  SELECTED = 'selected',
}

export enum HgtpQueueMetagraph {
  DOR = 'dor',
  PACA_SWAP = 'paca_swap',
}

export const DeploymentHgtpQueueMetagraphs: Record<HgtpQueueMetagraph, string> =
  {
    [HgtpQueueMetagraph.DOR]: {
      [DeploymentStage.PRODUCTION]: 'constellation:mainnet:dor',
      [DeploymentStage.STAGING]: 'constellation:integrationnet:dor',
      [DeploymentStage.ALPHA]: 'constellation:integrationnet:dor',
      [DeploymentStage.DELTA]: 'constellation:integrationnet:dor',
      [DeploymentStage.THETA]: 'constellation:integrationnet:dor',
      [DeploymentStage.TEST]: 'constellation:integrationnet:dor',
    }[EnvironmentContext.stage],
    [HgtpQueueMetagraph.PACA_SWAP]: {
      [DeploymentStage.PRODUCTION]: 'constellation:mainnet:elpaca',
      [DeploymentStage.STAGING]: 'constellation:integrationnet:elpaca',
      [DeploymentStage.ALPHA]: 'constellation:integrationnet:elpaca',
      [DeploymentStage.DELTA]: 'constellation:integrationnet:elpaca',
      [DeploymentStage.THETA]: 'constellation:integrationnet:elpaca',
      [DeploymentStage.TEST]: 'constellation:integrationnet:dor',
    }[EnvironmentContext.stage],
  }
