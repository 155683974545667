import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'

import {
  useSoftNodesProvider,
  useLaunchpadProvider,
  useAirdropsProvider,
  IAirdrop,
} from '@lattice/common/providers'
import { ILaunchpadProject } from '@lattice/common/providers/LaunchpadProvider/types'
import { StatusColors } from '@lattice/common/consts/programStatus'
import { formatNumberAndCurrency, NumberFormat } from '@lattice/utils'
import { getStatusBadgeData } from '@lattice/common/providers/LaunchpadProvider/utils'
import ConstellationLogo from '@lattice/assets/images/svgs/Constellation.svg'
import NodeOperatorBanner from '@lattice/assets/images/pngs/integrations_net/node-operator-banner.png'
import DtmImage from '@lattice/assets/images/pngs/dtmImage.png'
import { NetworkCurrenciesUtils } from '@lattice/common/lib'

export const useFeaturedCards = () => {
  const softNodesInfo = useSoftNodesProvider()
  const {
    projects: { resource: launchpadProjects },
    requestProjects,
  } = useLaunchpadProvider()

  const { airdrops, requestAirdropsPage: requestAirdrops } =
    useAirdropsProvider()

  const [launchpadProject, setLaunchpadProject] =
    useState<ILaunchpadProject | null>(null)
  const [airdrop, setAirdrop] = useState<IAirdrop | null>(null)

  useEffect(() => {
    softNodesInfo.requestProjects()
    requestProjects()
    requestAirdrops(0)
  }, [])

  useEffect(() => {
    const filtered = launchpadProjects
      .filter((project) => {
        return ['coming_soon', 'live', 'distributing'].includes(project.status)
      })
      .sort((a, b) => {
        return dayjs(a.startsAt).isAfter(b.startsAt) ? -1 : 1
      })

    setLaunchpadProject(filtered[0])
  }, [launchpadProjects])

  useEffect(() => {
    if (!airdrops.resource) return

    const filtered = airdrops.resource
      .filter((airdrop) => {
        return ['coming_soon', 'live', 'complete'].includes(airdrop.status)
      })
      .sort((a, b) => {
        return dayjs(a.distributionStartsAt).isAfter(b.distributionStartsAt)
          ? -1
          : 1
      })

    setAirdrop(filtered[0])
  }, [airdrops])

  const cards = [] as any[]

  cards.push({
    key: 'dtmProgram',
    headerImage: DtmImage,
    headerLogo: ConstellationLogo,
    data: {
      title: 'Dor Traffic Miner NFT',
      nodeType: 'dtm',
      buttonLabel: 'View program',
      to: '/rewards/bounties/dtm-nft',
      displayInfo: [
        {
          label: 'Status',
          badge: { text: 'LIVE', color: StatusColors.live },
        },
        { label: 'Program', text: 'Commissions' },
        {
          label: 'Rewards',
          text: 'Variable',
        },
        {
          label: 'Required',
          text: 'DTM NFT',
        },
        { label: 'Commission type', text: 'NFT holder' },
        { label: 'Timeline', text: 'Ongoing' },
      ],
    },
  })

  if (launchpadProject) {
    cards.push({
      key: 'launchpadProjectFeatured',
      headerImage: launchpadProject.bannerUrl,
      headerLogo: null,
      data: {
        title: launchpadProject.name,
        subTitle: launchpadProject.summaryText,
        nodeType: 'Soft Node',
        buttonLabel: 'View project',
        to: `/launchpad/${launchpadProject.slug}`,
        displayInfo: [
          {
            label: 'Status',
            badge: getStatusBadgeData(launchpadProject.status),
          },
          {
            label: 'Timeline',
            text: dayjs().isBefore(dayjs(launchpadProject.startsAt))
              ? `Starts ${dayjs(launchpadProject.startsAt).fromNow()}`
              : dayjs().isBefore(dayjs(launchpadProject.stakingEndsAt))
                ? `Ends ${dayjs(launchpadProject.stakingEndsAt).fromNow()}`
                : `Ended ${dayjs(launchpadProject.stakingEndsAt).fromNow()}`,
          },
          {
            label: 'Target Raise',
            text: formatNumberAndCurrency(
              launchpadProject.targetRaiseAmount,
              launchpadProject.targetRaiseCurrency,
              NumberFormat.MILLIFY
            ),
          },
          {
            label: 'Total Staked',
            text: formatNumberAndCurrency(
              launchpadProject.totalStaked['all'],
              'LTX',
              NumberFormat.MILLIFY
            ),
          },
        ],
      },
    })
  }

  if (airdrop && airdrop.status !== 'complete') {
    let timelineInfo = {}
    if (airdrop.status === 'coming_soon') {
      timelineInfo = {
        label: 'Starts at',
        text: dayjs(airdrop.distributionStartsAt).format('MMM DD, YYYY'),
      }
    }
    if (airdrop.status === 'live') {
      timelineInfo = {
        label: 'Vesting Period',
        text: dayjs.duration(airdrop.vestingPeriod).humanize(),
      }
    }

    cards.push({
      key: 'airdropFeatured',
      headerImage: airdrop.bannerUrl,
      headerLogo: airdrop.logoUrl,
      data: {
        title: airdrop.name,
        subTitle: airdrop.summaryText,
        nodeType: 'Airdrop',
        buttonLabel:
          airdrop.status === 'coming_soon' ? 'View airdrops' : 'Claim airdrop',
        to: '/governance/claim',
        displayInfo: [
          {
            label: 'Status',
            badge: getStatusBadgeData(airdrop.status),
          },
          {
            label: 'Program',
            text: 'Airdrops',
          },
          {
            label: 'Rewards',
            text: formatNumberAndCurrency(
              new Decimal(airdrop.rewardAmount),
              NetworkCurrenciesUtils[airdrop.rewardCurrency].symbol,
              NumberFormat.MILLIFY
            ),
          },
          {
            label: 'Required',
            text: 'veLTX tokens',
          },
          {
            label: 'Recipients',
            text: 'All holders',
          },
          timelineInfo,
        ],
      },
    })
  }

  cards.length < 4 &&
    cards.push({
      key: 'pacaswap-node-operator-program',
      headerImage:
        'https://lattice-exchange-assets.s3.amazonaws.com/hgtp_metagraphs/pacaswap-operator-banner.png',
      headerLogo: null,
      data: {
        title: 'Pacaswap Metagraph Operators',
        nodeType: 'dtm-node',
        buttonLabel: 'View program',
        to: '/rewards/hgtp-metagraphs/pacaswap/operators',
        displayInfo: [
          {
            label: 'Status',
            badge: { text: 'LIVE', color: StatusColors.live },
          },
          { label: 'Program', text: 'Commissions' },
          {
            label: 'Rewards',
            text: 'Variable',
          },
          {
            label: 'Requirements',
            text: 'Multiple',
          },
          { label: 'Commission Type', text: 'Daily Dist.' },
          { label: 'Timeline', text: 'Ongoing' },
        ],
      },
    })

  cards.length < 4 &&
    cards.push({
      key: 'IntegrationsNetProgram',
      headerImage: NodeOperatorBanner,
      headerLogo: null,
      data: {
        title: 'Node Operators',
        nodeType: 'integrationsNetNode',
        buttonLabel: 'View program',
        to: '/rewards/integrations-net/operators',
        displayInfo: [
          {
            label: 'Status',
            badge: { text: 'LIVE', color: StatusColors.live },
          },
          { label: 'Program', text: 'Commissions' },
          {
            label: 'Rewards',
            text: 'Variable',
          },
          {
            label: 'Required',
            text: '250K Collateral',
          },
          { label: 'Lockup', text: 'N/A' },
          { label: 'Timeline', text: 'Ongoing' },
        ],
      },
    })

  cards.length < 4 &&
    cards.push({
      key: 'dtm-commercial-bounty',
      headerImage:
        'https://lattice-exchange-assets.s3.amazonaws.com/bounties/dtm-commercial/dtm-commercial.bouties.banner.png',
      headerLogo: null,
      data: {
        title: 'DTM Commercial Commission',
        nodeType: 'dtm-node',
        buttonLabel: 'View program',
        to: '/rewards/bounties/dtm-commercial',
        displayInfo: [
          {
            label: 'Status',
            badge: { text: 'LIVE', color: StatusColors.live },
          },
          { label: 'Program', text: 'Commissions' },
          {
            label: 'Rewards',
            text: 'Variable',
          },
          {
            label: 'Requirements',
            text: 'Multiple',
          },
          { label: 'Commission Type', text: 'Daily Dist.' },
          { label: 'Timeline', text: 'Ongoing' },
        ],
      },
    })

  cards.length < 4 &&
    cards.push({
      key: 'dor-node-operator-program',
      headerImage:
        'https://lattice-exchange-assets.s3.amazonaws.com/hgtp_metagraphs/dor-operator-banner.png',
      headerLogo: null,
      data: {
        title: 'Dor Metagraph Operators',
        nodeType: 'dtm-node',
        buttonLabel: 'View program',
        to: '/rewards/hgtp-metagraphs/dor/operators',
        displayInfo: [
          {
            label: 'Status',
            badge: { text: 'LIVE', color: StatusColors.live },
          },
          { label: 'Program', text: 'Commissions' },
          {
            label: 'Rewards',
            text: 'Variable',
          },
          {
            label: 'Requirements',
            text: 'Multiple',
          },
          { label: 'Commission Type', text: 'Daily Dist.' },
          { label: 'Timeline', text: 'Ongoing' },
        ],
      },
    })

  return { cards }
}
