import axios from 'axios'

import { EnvironmentContext } from '@lattice/runtime'

export const SecuredProxiedApi = axios.create({
  baseURL: EnvironmentContext.SecuredProxiedApiUrl,
})

export const SecuredProxyApiConfig = {
  tokenKey: 'spa-token',
  tokenHeader: 'x-lattice-req-token',
  signatureHeader: 'x-lattice-sig',
}
