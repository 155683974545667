import dayjs from 'dayjs'

const dateRange = (dateA: Date, dateB: Date): string => {
  const removedTimeDateA = dayjs(dateA)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
  const removedTimeDateB = dayjs(dateB)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
  if (removedTimeDateA.diff(removedTimeDateB, 'd') === 0) {
    return timeRange(dateA, dateB)
  }

  return dateA.getUTCMonth() === dateB.getUTCMonth() &&
    dateA.getUTCFullYear() === dateB.getUTCFullYear()
    ? `${dayjs(dateA)
        .format('MMM D - ##, YYYY')
        .replace('##', dayjs(dateB).format('D'))}`
    : dateA.getUTCFullYear() === dateB.getUTCFullYear()
      ? `${dayjs(dateA)
          .format('MMM D - ##, YYYY')
          .replace('##', dayjs(dateB).format('MMM D'))}`
      : `${dayjs(dateA).format('MMM D, YYYY')} - ${dayjs(dateB).format(
          'MMM D, YYYY'
        )}`
}

const getDateRangeWithoutYear = (dateA: Date, dateB: Date): string => {
  const dateString = dateRange(dateA, dateB)

  const dateStringSplit = dateString.split(',')
  return dateStringSplit[0]
}

const getDiffInDays = (dateA: Date, dateB: Date): number => {
  return dayjs(dateA).diff(dateB, 'd')
}

const getDiffInMinutes = (dateA: Date, dateB: Date): number => {
  return dayjs(dateA).diff(dateB, 'm')
}

const dateToUTC = (date: Date) => {
  return dayjs(date).subtract(date.getTimezoneOffset(), 'm').toDate()
}

const parseUTCISODate = (date: string) => {
  return dateToUTC(dayjs(date).toDate())
}

const getISODateFromUTC = (dateUTC: Date) => {
  return dayjs(dateUTC)
    .subtract(-dateUTC.getTimezoneOffset(), 'm')
    .format('YYYY-MM-DD')
}

enum DateUnit {
  YEARS,
  MONTHS,
  DAYS,
  HOURS,
  MINUTES,
  SECONDS,
}

const diffInMaxUnit = (dateA: Date, dateB: Date, maxUnit: DateUnit): string => {
  const removedTimeDateA = dayjs(dateA)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
  const removedTimeDateB = dayjs(dateB)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)

  if (maxUnit === DateUnit.YEARS) {
    const diff = dayjs(dateA).year() - dayjs(dateB).year()
    if (diff !== 0) {
      return `${diff} year${Math.abs(diff) !== 1 ? 's' : ''}`
    }

    maxUnit = DateUnit.MONTHS
  }

  if (maxUnit === DateUnit.MONTHS) {
    const diff = Math.abs(
      removedTimeDateA.day(1).diff(removedTimeDateB.day(1), 'M')
    )

    if (diff !== 0) {
      return `${diff} month${Math.abs(diff) !== 1 ? 's' : ''}`
    }

    maxUnit = DateUnit.DAYS
  }

  if (maxUnit === DateUnit.DAYS) {
    const diff = Math.abs(removedTimeDateA.diff(removedTimeDateB, 'd'))
    if (diff !== 0) {
      return `${diff} day${Math.abs(diff) !== 1 ? 's' : ''}`
    }

    maxUnit = DateUnit.HOURS
  }

  if (maxUnit === DateUnit.HOURS) {
    const diff = dayjs(dateA).diff(dateB, 'h')
    if (diff !== 0) {
      return `${diff} hour${Math.abs(diff) !== 1 ? 's' : ''}`
    }

    maxUnit = DateUnit.MINUTES
  }

  if (maxUnit === DateUnit.MINUTES) {
    const diff = dayjs(dateA).diff(dateB, 'm')
    if (diff !== 0) {
      return `${diff} minut${Math.abs(diff) !== 1 ? 'es' : ''}`
    }

    maxUnit = DateUnit.SECONDS
  }

  const diff = dayjs(dateA).diff(dateB, 's')
  return `${diff} second${Math.abs(diff) !== 1 ? 's' : ''}`
}

const timeRange = (dateA: Date, dateB: Date): string => {
  return `${dayjs(dateA).format('HH:mm:ss')} - ${dayjs(dateB).format(
    'HH:mm:ss'
  )}`
}

export {
  dateRange,
  getDateRangeWithoutYear,
  getDiffInDays,
  getDiffInMinutes,
  dateToUTC,
  parseUTCISODate,
  getISODateFromUTC,
  DateUnit,
  diffInMaxUnit,
  timeRange,
}
