import { FetchStatus } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/common/lib'

import { IWalletOwnershipToken } from '../WalletProvider'

import { IDtmNode, IDtmStats, IDtmUser, INftRedeemResponseData } from './types'

const requestDtmStats = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/dtmnodes/stats',
    })
    return data as IDtmStats
  } catch (err) {
    throw err
  }
}

const requestDtmUser = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/dtmnodes/user`,
      isAuthenticated: true,
    })
    return data as IDtmUser
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const createDtmUser = async (address: string) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/dtmnodes/user`,
      isAuthenticated: true,
      body: {
        address,
      },
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const createDtmNode = async (
  address: string,
  walletOwnershipToken: IWalletOwnershipToken
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/dtmnodes/node`,
      isAuthenticated: true,
      body: {
        address,
        walletOwnershipToken,
      },
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const requestUserDtmNftData = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/dtmnodes/user/nfts',
      isAuthenticated: true,
    })
    return data as INftRedeemResponseData
  } catch (err) {
    throw err
  }
}

const redeemDtmNfts = async (
  shippingData: any,
  nodes: IDtmNode[],
  walletOwnershipToken: IWalletOwnershipToken,
  address: string
) => {
  try {
    const response = await apiRequest({
      method: 'POST',
      endpoint: `/dtmnodes/redeem`,
      isAuthenticated: true,
      body: {
        shippingData,
        nodes,
        walletOwnershipToken,
        address,
      },
    })
    return response
  } catch (err) {
    console.log(err)
    throw err
  }
}

export {
  requestDtmStats,
  requestDtmUser,
  createDtmNode,
  createDtmUser,
  requestUserDtmNftData,
  redeemDtmNfts,
}
