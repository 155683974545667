import React from 'react'
import cls from 'classnames'

import { BaseCard, Button } from '@lattice/common/components'
import { useIntegrationsNetProvider } from '@lattice/common/providers/IntegrationsNetProvider'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import { APIError } from '@lattice/common/lib'
import { FetchStatus } from '@lattice/common/consts'

import styles from './component.module.scss'

const NodeOperatorQueuePositionCard = () => {
  const { applicant, executeApplicantOptOut, requestApplicant } =
    useIntegrationsNetProvider()

  const executeApplicantOptOutOperation = useFetchableOperation()
  const executeApplicantOptOutProgressToasts = useProgressToasts()

  const doExecuteApplicantOptOut = executeApplicantOptOutOperation.wrappedFetch(
    executeApplicantOptOutProgressToasts.wrappedErrorsAsync(async () => {
      try {
        const result = window.confirm(
          'Leaving the queue will remove your spot as a potential IntegrationNet Node Operator. If you choose to rejoin later, you will be placed at the end of the queue.'
        )

        if (!result) {
          return
        }

        executeApplicantOptOutProgressToasts.progress(
          'Leaving queue',
          'info',
          null
        )

        await executeApplicantOptOut()

        executeApplicantOptOutProgressToasts.progress(
          'You have opted-out of the program',
          'success',
          10000
        )

        requestApplicant()
      } catch (e) {
        if (
          e instanceof APIError &&
          e.errorCode === 403 &&
          /User is forbidden to take this action/i.test(e.message)
        ) {
          throw new Error(
            "Oops, it seems you're not allowed to execute this action. Contact support for further details."
          )
        } else {
          throw new Error('An error ocurred')
        }
      }
    })
  )

  if (!applicant.resource) {
    return null
  }

  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        <div className={cls(styles.supertitleText, styles.greenText)}>
          {applicant.resource.queuePosition}/
          {applicant.resource.queueLength ?? 0}
        </div>
        <div className={styles.subtitleText}>Your queue position</div>
        <Button
          variants={['outlined', 'full-width']}
          type="button"
          disabled={
            executeApplicantOptOutOperation.status === FetchStatus.PENDING
          }
          loading={
            executeApplicantOptOutOperation.status === FetchStatus.PENDING
          }
          onClick={() => doExecuteApplicantOptOut()}
        >
          Leave the queue
        </Button>
      </div>
    </BaseCard>
  )
}

export { NodeOperatorQueuePositionCard }
