import Decimal from 'decimal.js'

import { apiRequest, CurrencyNetwork } from '@lattice/common/lib'

import { ILiquidityStakingProgramAndContext } from './types'
import { LiquidityStakingProgramStatus } from './consts'

const encodeParam = (value: any) => {
  if (value instanceof Decimal) {
    return `decimal:${value.toFixed()}`
  }

  if (
    ['string', 'boolean', 'number', 'object'].includes(typeof value) ||
    value === null
  ) {
    return `json:${JSON.stringify(value)}`
  }

  throw new Error('Unable to find encoder for value => ' + String(value))
}

const decodeParam = (value: string) => {
  const [decodeAs] = value.split(':')
  const rawValue = value.split(':').slice(1).join(':')

  if (decodeAs === 'json') {
    return JSON.parse(rawValue)
  }

  if (decodeAs === 'decimal') {
    return new Decimal(rawValue)
  }

  throw new Error('Unable to find decoder for value => ' + String(value))
}

const requestPrograms = async (parameters: {
  address?: string
  status?: LiquidityStakingProgramStatus
  contractNetwork?: CurrencyNetwork
  stakedOnly?: boolean
}) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/liquidity-staking',
      parameters,
    })
    return data as ILiquidityStakingProgramAndContext[]
  } catch (err) {
    console.log(err)
    throw err
  }
}

const requestProgramStrategy = async (
  programId: string,
  method: string,
  params: any[]
) => {
  try {
    const {
      data: { result },
    }: { data: { result: any } } = await apiRequest({
      method: 'GET',
      endpoint: `/liquidity-staking/${programId}/strategy`,
      parameters: {
        method,
        params: JSON.stringify(params.map((param) => encodeParam(param))),
      },
    })

    return decodeParam(result)
  } catch (err) {
    console.log(err)
    throw err
  }
}

export { encodeParam, decodeParam, requestPrograms, requestProgramStrategy }
