import { dag4 } from '@stardust-collective/dag4'
import Decimal from 'decimal.js'
import { ethers } from 'ethers'

import { CurrencyNetwork } from '../currencies'
import {
  DeploymentEvmChain,
  EvmNetwork,
  getNetworkProvider,
  getNetworkTypedContract,
  isEvmNetwork,
} from '../web3'

import { RegisteredTokens } from './registered'
import { RegisteredToken } from './consts'

enum NetworkCurrency {
  ETHEREUM__VELTX = 'ethereum:veltx',
  ETHEREUM__LTX = 'ethereum:ltx',
  ETHEREUM__ETH = 'ethereum:eth',
  ETHEREUM__LTX_LOCKED = 'ethereum:ltx-locked',
  ETHEREUM__OBS = 'ethereum:obs',
  CONSTELLATION__DAG = 'constellation:dag',
  ETHEREUM__ADS = 'ethereum:ads',
  ETHEREUM__JAM = 'ethereum:jam',
  ETHEREUM__LEET = 'ethereum:leet',
  POLYGON_MATIC = 'polygon:matic',
  POLYGON__JCO = 'polygon:jco',
}

type NetworkCurrencyBalance = {
  balance: Decimal
  balanceRaw: Decimal
  currencyUtil: NetworkCurrencyUtil
}

type NetworkCurrencyUtil = {
  network: CurrencyNetwork
  decimals: number
  decimalFactor: Decimal
  symbol: string
  getAddressBalance: (address: string) => Promise<NetworkCurrencyBalance>
}

type RegisteredNetworkCurrencyUtil = NetworkCurrencyUtil & {
  currency: NetworkCurrency
}

type NativeNetworkCurrencyUtil = RegisteredNetworkCurrencyUtil & {
  isValidAddress: (address: string) => address is string
}

const NetworkCurrenciesUtils: Record<
  NetworkCurrency,
  RegisteredNetworkCurrencyUtil
> = {
  [NetworkCurrency.CONSTELLATION__DAG]: {
    currency: NetworkCurrency.CONSTELLATION__DAG,
    network: CurrencyNetwork.CONSTELLATION,
    decimals: 8,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'DAG',
    getAddressBalance: async function getAddressBalance(address) {
      const networkBalance =
        (await dag4.network.getAddressBalance(address))?.balance ?? 0

      const balanceRaw = new Decimal(networkBalance)
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.ETHEREUM__ETH]: {
    currency: NetworkCurrency.ETHEREUM__ETH,
    network: CurrencyNetwork.ETHEREUM,
    decimals: 18,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'ETH',
    getAddressBalance: async function getAddressBalance(address) {
      const provider = getNetworkProvider(EvmNetwork.ETHEREUM)
      const networkBalance = provider.getBalance(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.ETHEREUM__LTX]: {
    currency: NetworkCurrency.ETHEREUM__LTX,
    network: CurrencyNetwork.ETHEREUM,
    decimals: 8,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'LTX',
    getAddressBalance: async function getAddressBalance(address: string) {
      const ltxToken = getNetworkTypedContract(
        'ERC20',
        RegisteredTokens[RegisteredToken.LTX].instances[DeploymentEvmChain]
          .address,
        EvmNetwork.ETHEREUM
      )
      const networkBalance = await ltxToken.balanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.ETHEREUM__OBS]: {
    currency: NetworkCurrency.ETHEREUM__OBS,
    network: CurrencyNetwork.ETHEREUM,
    decimals: 16,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'OBS',
    getAddressBalance: async function getAddressBalance(address: string) {
      const token = getNetworkTypedContract(
        'ERC20',
        RegisteredTokens[RegisteredToken.OBS].instances[DeploymentEvmChain]
          .address,
        EvmNetwork.ETHEREUM
      )
      const networkBalance = await token.balanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.ETHEREUM__VELTX]: {
    currency: NetworkCurrency.ETHEREUM__VELTX,
    network: CurrencyNetwork.ETHEREUM,
    decimals: 18,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'veLTX',
    getAddressBalance: async function getAddressBalance(address: string) {
      const ltxToken = getNetworkTypedContract(
        'ERC20',
        RegisteredTokens[RegisteredToken.veLTX].instances[DeploymentEvmChain]
          .address,
        EvmNetwork.ETHEREUM
      )
      const networkBalance = await ltxToken.balanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.ETHEREUM__ADS]: {
    currency: NetworkCurrency.ETHEREUM__ADS,
    network: CurrencyNetwork.ETHEREUM,
    decimals: 18,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'ADS',
    getAddressBalance: async function getAddressBalance(address: string) {
      const erc20Token = getNetworkTypedContract(
        'ERC20',
        '0x3106a0a076bedae847652f42ef07fd58589e001f',
        EvmNetwork.ETHEREUM
      )
      const networkBalance = await erc20Token.balanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.ETHEREUM__JAM]: {
    currency: NetworkCurrency.ETHEREUM__JAM,
    network: CurrencyNetwork.ETHEREUM,
    decimals: 18,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'JAM',
    getAddressBalance: async function getAddressBalance(address: string) {
      const erc20Token = getNetworkTypedContract(
        'ERC20',
        '0x23894dc9da6c94ecb439911caf7d337746575a72',
        EvmNetwork.ETHEREUM
      )
      const networkBalance = await erc20Token.balanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.ETHEREUM__LTX_LOCKED]: {
    currency: NetworkCurrency.ETHEREUM__LTX_LOCKED,
    network: CurrencyNetwork.ETHEREUM,
    decimals: 8,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'Locked LTX',
    getAddressBalance: async function getAddressBalance(address: string) {
      const ltxToken = getNetworkTypedContract(
        'LatticeGovernanceToken',
        RegisteredTokens[RegisteredToken.veLTX].instances[DeploymentEvmChain]
          .address,
        EvmNetwork.ETHEREUM
      )
      const networkBalance = await ltxToken.ltxLockedBalanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.ETHEREUM__LEET]: {
    currency: NetworkCurrency.ETHEREUM__LEET,
    network: CurrencyNetwork.ETHEREUM,
    decimals: 18,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'LEET',
    getAddressBalance: async function getAddressBalance(address: string) {
      const token = getNetworkTypedContract(
        'ERC20',
        '0x6758647a4cd6b4225b922b456be5c05359012032',
        EvmNetwork.ETHEREUM
      )
      const networkBalance = await token.balanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.POLYGON_MATIC]: {
    currency: NetworkCurrency.POLYGON_MATIC,
    network: CurrencyNetwork.POLYGON,
    decimals: 18,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'MATIC',
    getAddressBalance: async function getAddressBalance(address) {
      const provider = getNetworkProvider(EvmNetwork.POLYGON)
      const networkBalance = provider.getBalance(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
  [NetworkCurrency.POLYGON__JCO]: {
    currency: NetworkCurrency.POLYGON__JCO,
    network: CurrencyNetwork.POLYGON,
    decimals: 18,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol: 'JCO',
    getAddressBalance: async function getAddressBalance(address: string) {
      const token = getNetworkTypedContract(
        'ERC20',
        '0x8105f88e77a5d102099bf73db4469d3f1e3b0cd6',
        EvmNetwork.POLYGON
      )
      const networkBalance = await token.balanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, this.decimals))

      return {
        balance,
        balanceRaw,
        currency: this.currency,
        currencyUtil: this,
      }
    },
  },
}

const NativeNetworkCurrencies: Record<
  CurrencyNetwork,
  NativeNetworkCurrencyUtil
> = {
  [CurrencyNetwork.CONSTELLATION]: {
    ...NetworkCurrenciesUtils[NetworkCurrency.CONSTELLATION__DAG],
    isValidAddress: (address: any): address is string =>
      dag4.keyStore.validateDagAddress(address),
  },
  [CurrencyNetwork.ETHEREUM]: {
    ...NetworkCurrenciesUtils[NetworkCurrency.ETHEREUM__ETH],
    isValidAddress: (address: any): address is string =>
      ethers.utils.isAddress(address),
  },
  [CurrencyNetwork.POLYGON]: {
    ...NetworkCurrenciesUtils[NetworkCurrency.POLYGON_MATIC],
    isValidAddress: (address: any): address is string =>
      ethers.utils.isAddress(address),
  },
}

const getERC20NetworkCurrencyUtil = async (
  symbol: string,
  contractAddress: string,
  network: CurrencyNetwork
): Promise<NetworkCurrencyUtil> => {
  if (!isEvmNetwork(network)) {
    throw new Error(`Invalid RPC network => ${network}`)
  }

  const currencyContract = getNetworkTypedContract(
    'ERC20',
    contractAddress,
    network
  )

  const currencyDecimals = await currencyContract.decimals()

  const currencyUtil: NetworkCurrencyUtil = {
    network,
    decimals: currencyDecimals,
    get decimalFactor() {
      return Decimal.pow(10, this.decimals)
    },
    symbol,
    getAddressBalance: async function getAddressBalance(address) {
      const networkBalance = await currencyContract.balanceOf(address)

      const balanceRaw = new Decimal(networkBalance.toString())
      const balance = balanceRaw.div(Decimal.pow(10, currencyDecimals))

      return {
        balance,
        balanceRaw,
        currencyUtil: this,
      }
    },
  }

  return currencyUtil
}

const isValidNetwork = (value: any): value is CurrencyNetwork =>
  typeof value === 'string' &&
  Object.values(CurrencyNetwork).includes(value as any)

const isValidNetworkCurrency = (value: any): value is NetworkCurrency =>
  typeof value === 'string' &&
  Object.values(NetworkCurrency).includes(value as any)

export {
  NetworkCurrency,
  NetworkCurrencyBalance,
  NetworkCurrencyUtil,
  NativeNetworkCurrencyUtil,
  NetworkCurrenciesUtils,
  NativeNetworkCurrencies,
  isValidNetwork,
  isValidNetworkCurrency,
  getERC20NetworkCurrencyUtil,
}
