import { buildIsValidEnumMember } from '@lattice/utils'

export enum RegisteredToken {
  DTM = 'DTM',
  LTX = 'LTX',
  OBS = 'OBS',
  veLTX = 'veLTX',
}

export const isRegisteredToken =
  buildIsValidEnumMember<RegisteredToken>(RegisteredToken)
