import { HgtpNetworksBlockExplorerApi } from './be_api'
import { HgtpNetwork } from './consts'

export type IBEAddress = {
  ordinal: number
  balance: number
  address: string
}

export class HgtpNetworkAddresses {
  static async getBEAddressBalance(
    network: HgtpNetwork,
    walletAddress: string,
    ordinal?: number
  ) {
    const response = await HgtpNetworksBlockExplorerApi[network].get<{
      data: IBEAddress
    }>(`/addresses/${walletAddress}/balance`, { params: { ordinal } })

    return response.data.data
  }

  static async getMetagraphBEAddressBalance(
    network: HgtpNetwork,
    metagraphId: string,
    walletAddress: string,
    ordinal?: number
  ) {
    const response = await HgtpNetworksBlockExplorerApi[network].get<{
      data: IBEAddress
    }>(`/currency/${metagraphId}/addresses/${walletAddress}/balance`, {
      params: { ordinal },
    })

    return response.data.data
  }
}
