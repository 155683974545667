import { CognitoUserPool } from 'amazon-cognito-identity-js'
import axios, { AxiosError, isAxiosError } from 'axios'

import { AWSCognitoConfig } from '@lattice/common/consts'
import { EnvironmentContext } from '@lattice/runtime'

export type apiRequestOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  endpoint: string
  body?: Record<string, any>
  isAuthenticated?: boolean
  headers?: Record<string, string>
  parameters?: Record<string, any>
}

const getCurrentAuthenticatedUser = async () => {
  const userPool = new CognitoUserPool(AWSCognitoConfig)
  const user = userPool.getCurrentUser()

  if (!user) {
    return null
  }

  await new Promise<void>((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      }
      resolve(session)
    })
  })

  return user
}

export class APIError extends Error {
  public errorCode: 400 | 404 | 401 | 403 | 500
  public originalError: AxiosError | null

  constructor(
    message: string,
    errorCode: 400 | 404 | 401 | 403 | 500,
    originalError?: AxiosError
  ) {
    super(message)
    this.errorCode = errorCode
    this.originalError = originalError ?? null
  }
}

export const apiRequest = async ({
  method,
  endpoint,
  body,
  isAuthenticated = false,
  headers = {},
  parameters = {},
}: apiRequestOptions): Promise<{ data: any; meta?: any }> => {
  try {
    if (isAuthenticated) {
      const user = await getCurrentAuthenticatedUser()
      if (!user) {
        throw new APIError('User is not logged in', 401)
      }
      const accessToken = user
        .getSignInUserSession()
        ?.getAccessToken()
        .getJwtToken()

      if (!accessToken) {
        throw new APIError('User is not logged in', 401)
      }

      headers.Authorization = `Bearer ${accessToken}`
    }

    const { status, statusText, data } = await axios({
      method,
      url: `${EnvironmentContext.ApiBaseUrl}${endpoint}`,
      headers,
      data: body,
      params: parameters,
    })

    if (status < 200 || status >= 400) {
      throw new APIError(statusText, status as any)
    }

    return {
      data: data.data,
      meta: data.meta,
    }
  } catch (err) {
    if (isAxiosError(err)) {
      throw new APIError(
        JSON.stringify(err.response?.data),
        err.response?.status as any,
        err
      )
    }

    throw err
  }
}
