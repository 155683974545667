import { FetchStatus } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/common/lib'

import { ITestnetValidatorStats, ITestnetRewardsUserData } from './types'

const getTestnetRewardsStats = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/testnet-rewards/stats`,
      isAuthenticated: false,
    })
    return data as ITestnetValidatorStats
  } catch (err) {
    console.log(err)
    throw err
  }
}

const getTestnetRewardsUser = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/testnet-rewards/user`,
      isAuthenticated: true,
    })
    return data as ITestnetRewardsUserData
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

const setTestnetRewardsUser = async (address: string) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/testnet-rewards/user`,
      isAuthenticated: true,
      body: {
        address,
      },
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const setTestnetRewardsNode = async (nodeToken: string) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/testnet-rewards/node`,
      isAuthenticated: true,
      body: {
        nodeToken,
      },
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export {
  getTestnetRewardsUser,
  getTestnetRewardsStats,
  setTestnetRewardsUser,
  setTestnetRewardsNode,
}
