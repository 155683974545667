export enum DeploymentStage {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DELTA = 'delta',
  ALPHA = 'alpha',
  THETA = 'theta',
  TEST = 'test',
}

export const DeploymentRegions: Record<DeploymentStage, string> = {
  [DeploymentStage.PRODUCTION]: 'us-east-1',
  [DeploymentStage.STAGING]: 'us-east-2',
  [DeploymentStage.DELTA]: 'us-west-1',
  [DeploymentStage.ALPHA]: 'us-west-2',
  [DeploymentStage.THETA]: 'ca-central-1',
  [DeploymentStage.TEST]: 'us-west-1',
}

export const isDeploymentStage = (value: unknown): value is DeploymentStage =>
  typeof value === 'string' &&
  Object.values(DeploymentStage).includes(value as any)
