import { DeploymentStage } from './deployment'
import { getEnvOrError } from './get_env'

const makeEnvironmentContext = <E extends Record<string, () => any>>(
  envDefinition: E
) => {
  return new Proxy(
    {},
    {
      get: (t, p) => {
        if (typeof p !== 'string') {
          throw new Error('Inconsistency error')
        }

        return envDefinition[p]()
      },
    }
  ) as { [K in keyof E]: ReturnType<E[K]> }
}

const expandByPrefixes = (prefixes: string[]) => (term: string) =>
  prefixes.map((prefix) => `${prefix}${term}`)

const expandByCommonClientPrefixes = expandByPrefixes([
  'REACT_APP_',
  'NEXT_PUBLIC_',
])

export const EnvironmentContext = makeEnvironmentContext({
  nodeEnv: () => getEnvOrError<string>('NODE_ENV'),
  stage: () =>
    getEnvOrError<DeploymentStage>(
      'SST_STAGE',
      ...expandByCommonClientPrefixes('STAGE')
    ),
  EcosystemApiUrl: () =>
    getEnvOrError<string>(...expandByCommonClientPrefixes('ECOSYSTEM_API_URL')),
  WalletConnectProjectId: () =>
    getEnvOrError<string>(
      ...expandByCommonClientPrefixes('WALLET_CONNECT_PROJECT_ID')
    ),
  cognitoUserPoolId: () =>
    getEnvOrError<string>(
      ...expandByCommonClientPrefixes('COGNITO_USER_POOL_ID')
    ),
  cognitoUserPoolClientId: () =>
    getEnvOrError<string>(
      ...expandByCommonClientPrefixes('COGNITO_WEB_CLIENT_ID')
    ),
  ApiBaseUrl: () =>
    getEnvOrError<string>(...expandByCommonClientPrefixes('API_BASE_URL')),
  ApiSharedKey: () =>
    getEnvOrError<string>(...expandByCommonClientPrefixes('SHARED_KEY')),
  SecuredProxiedApiKey: () =>
    getEnvOrError<string>(
      ...expandByCommonClientPrefixes('SECURED_PROXIED_API_KEY')
    ),
  SecuredProxiedApiUrl: () =>
    getEnvOrError<string>(
      ...expandByCommonClientPrefixes('SECURED_PROXIED_API_URL')
    ),
  ExtApiKeyFortmatic: () =>
    getEnvOrError<string>(...expandByCommonClientPrefixes('FORTMATIC_KEY')),
  ExtApiKeyPortis: () =>
    getEnvOrError<string>(...expandByCommonClientPrefixes('PORTIS_ID')),
  ExtApiKeyVeriff: () =>
    getEnvOrError<string>(...expandByCommonClientPrefixes('VERIFF_API_KEY')),
  ExtApiUrlVeriff: () =>
    getEnvOrError<string>(...expandByCommonClientPrefixes('VERIFF_BASE_URL')),
})
