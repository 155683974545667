import React from 'react'
import { Link } from 'react-router-dom'

import {
  HeroCard,
  Content,
  Typography,
  GridContainer,
  Banner,
} from '@lattice/common/components'

import { GeneralInfoContainer } from './components/GeneralInfoContainer'
import { Separator } from './components/Separator'
import { GovernanceCard } from './components/GovernanceCard'
import { GettingStartedCard } from './components/GettingStartedCard'
import { useFeaturedCards } from './hooks'

const DashboardView = () => {
  const { cards } = useFeaturedCards()

  return (
    <>
      <Banner>
        Don't forget to link your X account and DAG wallet to earn El Paca!{' '}
        <Link to="/user/myaccount/socials-wallets">Link Accounts →</Link>
      </Banner>
      <Content>
        <Typography.PageTitle>Dashboard</Typography.PageTitle>
        <GeneralInfoContainer>
          <GettingStartedCard />
          <GovernanceCard />
        </GeneralInfoContainer>
        <Separator>Featured Programs</Separator>
        <GridContainer>
          {cards.map((cardData) => {
            return (
              <HeroCard
                key={cardData.key}
                headerImage={cardData.headerImage}
                headerLogo={cardData.headerLogo}
                data={cardData.data}
                variant={'flexContent'}
              />
            )
          })}
        </GridContainer>
      </Content>
    </>
  )
}

export { DashboardView }
