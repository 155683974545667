/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useState } from 'react'
import axios, { AxiosResponse, isAxiosError } from 'axios'
import { escapeRegExp } from 'lodash'

import { FetchStatus } from '@lattice/common/consts'

import { FetchableResource, useFetchableResource } from './useFetchableResource'

const inflateUrlWithPathParams = (
  url: string,
  pathParams: Record<string, any>
) => {
  for (const [key, value] of Object.entries(pathParams)) {
    const regex = new RegExp(`:${escapeRegExp(key)}`, 'g')
    url = url.replace(regex, String(value))
  }
  return url
}

type APIResponse = {
  data: any[]
  meta?: {
    limit: number
    offset: number
    total: number
  }
}

type Resource<
  ResourceType,
  InitialState,
  PathParams extends Record<any, any>,
  QueryParams extends Record<any, any>,
  RequestData extends Record<any, any>,
> = {
  apiUrl: string
  method?: string
  pathParams: PathParams
  queryParams: QueryParams
  requestData?: RequestData
  responseProcessor?: (
    response: AxiosResponse
  ) => Promise<ResourceType> | ResourceType
  resource: ResourceType
  initialState: InitialState
  pagination?: {
    size: number
  }
}

type IResourceInContext<R extends Resource<any, any, any, any, any>> = {
  fetch: (
    pathParams: R['pathParams'],
    queryParams: R['queryParams'],
    options?: { pageSize?: number }
  ) => Promise<R['resource']>
  send: (
    requestData: R['requestData'],
    pathParams: R['pathParams'],
    queryParams: R['queryParams'],
    options?: { pageSize?: number }
  ) => Promise<R['resource']>
  goToPage: (
    number: number,
    options?: { pageSize?: number; isOffset?: boolean }
  ) => Promise<R['resource']>
  pagination: {
    currentPage: number
    pageSize: number
    totalPages: number
    totalItems: number
  }
  resource: FetchableResource<R['resource'] | R['initialState']>
}

type IResourcesProviderContext<
  Resources extends Record<string, Resource<any, any, any, any, any>>,
> = {
  [K in keyof Resources]: IResourceInContext<Resources[K]>
}

export const createResourcesProvider = <
  Resources extends Record<string, Resource<any, any, any, any, any>>,
>(
  providerName: string,
  resources: Resources
) => {
  const ResourcesProviderContext =
    React.createContext<IResourcesProviderContext<Resources> | null>(null)

  const ResourcesProvider = ({ children }: { children: React.ReactNode }) => {
    const ctx: IResourcesProviderContext<Resources> =
      {} as IResourcesProviderContext<Resources>

    for (const [key, resource] of Object.entries(resources)) {
      const fetchableResource = useFetchableResource<
        typeof resource.resource & typeof resource.initialState
      >(resource.initialState)
      const [currentPage, setCurrentPage] = useState(0)
      const [currentPageSize, setCurrentPageSize] = useState(
        resource.pagination?.size ?? 0
      )
      const [totalItems, setTotalItems] = useState<null | number>(null)
      const [currentParams, setCurrentParams] = useState<null | Record<
        'pathParams' | 'queryParams',
        any
      >>(null)

      const totalPages =
        totalItems && currentPageSize !== 0
          ? Math.ceil(totalItems / currentPageSize)
          : null

      const resourceInContext: IResourceInContext<any> = {
        fetch: fetchableResource.wrappedFetch(
          async (pathParams, queryParams, options) => {
            const resolvedPageSize = options?.pageSize ?? currentPageSize

            try {
              const response = await axios.request<APIResponse>({
                method: resource.method,
                url: inflateUrlWithPathParams(resource.apiUrl, pathParams),
                params: Object.assign(
                  queryParams,
                  resolvedPageSize > 0
                    ? {
                        limit: resolvedPageSize,
                        offset: 0,
                      }
                    : {}
                ),
              })

              if (resource.responseProcessor) {
                return resource.responseProcessor(response)
              }

              setCurrentPage(0)
              setCurrentPageSize(resolvedPageSize)
              setCurrentParams({ pathParams, queryParams })
              setTotalItems(
                response.data.meta?.total ?? response.data.data.length ?? 0
              )

              return response.data.data
            } catch (e) {
              if (isAxiosError(e)) {
                if (e.response?.status === 404) {
                  return FetchStatus.NOT_FOUND
                }
              }
              throw e
            }
          }
        ),
        send: fetchableResource.wrappedFetch(
          async (requestData, pathParams, queryParams, options) => {
            const resolvedPageSize = options?.pageSize ?? currentPageSize

            try {
              const response = await axios.request<APIResponse>({
                method: resource.method,
                url: inflateUrlWithPathParams(resource.apiUrl, pathParams),
                params: Object.assign(
                  queryParams,
                  resolvedPageSize > 0
                    ? {
                        limit: resolvedPageSize,
                        offset: 0,
                      }
                    : {}
                ),
                data: requestData,
              })

              if (resource.responseProcessor) {
                return resource.responseProcessor(response)
              }

              setCurrentPage(0)
              setCurrentPageSize(resolvedPageSize)
              setCurrentParams({ pathParams, queryParams })
              setTotalItems(
                response.data.meta?.total ?? response.data.data.length ?? 0
              )

              return response.data.data
            } catch (e) {
              if (isAxiosError(e)) {
                if (e.response?.status === 404) {
                  return FetchStatus.NOT_FOUND
                }
              }
              throw e
            }
          }
        ),
        goToPage: fetchableResource.wrappedFetch(async (number, options) => {
          if (!totalPages) {
            console.warn(
              `ResourcesProvider<${providerName}>: Pagination cannot be used until total items are available or pagination size > 0`
            )
            return
          }

          const resolvedPageSize = options?.pageSize ?? currentPageSize

          const targetPage = Math.max(
            Math.min(
              options?.isOffset ? currentPage + number : number,
              totalPages - 1
            ),
            0
          )
          const targetOffset = targetPage * resolvedPageSize

          try {
            const response = await axios.request<APIResponse>({
              method: resource.method,
              url: inflateUrlWithPathParams(
                resource.apiUrl,
                currentParams?.pathParams ?? {}
              ),
              params: Object.assign(currentParams?.queryParams ?? {}, {
                limit: resolvedPageSize,
                offset: targetOffset,
              }),
            })

            if (resource.responseProcessor) {
              return resource.responseProcessor(response)
            }

            setCurrentPage(targetPage)
            setCurrentPageSize(resolvedPageSize)
            setTotalItems(
              response.data.meta?.total ?? response.data.data.length ?? 0
            )

            return response.data.data
          } catch (e) {
            if (isAxiosError(e)) {
              if (e.response?.status === 404) {
                return FetchStatus.NOT_FOUND
              }
            }
            throw e
          }
        }),
        pagination: {
          currentPage,
          pageSize: currentPageSize,
          totalPages: totalPages ?? 0,
          totalItems: totalItems ?? 0,
        },
        resource: fetchableResource,
      }

      ctx[key as keyof Resources] = resourceInContext
    }

    return (
      <ResourcesProviderContext.Provider value={ctx}>
        {children}
      </ResourcesProviderContext.Provider>
    )
  }

  const useResourcesProvider = () => {
    const ctx = useContext(ResourcesProviderContext)

    if (!ctx) {
      throw new Error(
        `${providerName} must be used under a <${providerName}/> component`
      )
    }

    return ctx
  }

  Object.defineProperty(useResourcesProvider, 'name', {
    value: `use${providerName}`,
  })

  Object.defineProperty(ResourcesProvider, 'displayName', {
    value: providerName,
  })

  return [ResourcesProvider, useResourcesProvider] as const
}
