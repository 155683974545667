import {
  CognitoUser,
  CognitoUserAttribute,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js'

import { APIError, apiRequest } from '@lattice/common/lib'

import { IRequestContext } from '../types/queries'
import { FetchStatus } from '../consts'
import { IWalletOwnershipToken } from '../providers'

import { IAPIUserWallet } from './types'

export const getUserAttributes = async (
  user: CognitoUser,
  __ctx: IRequestContext
) => {
  return new Promise<
    Record<string, (string & CognitoUserAttribute) | undefined>
  >((rs, rj) => {
    user.getUserAttributes((err, result) => {
      if (err) {
        rj(err)
        return
      }

      const attributes: Record<
        string,
        (string & CognitoUserAttribute) | undefined
      > = {}

      if (!result) {
        rs(attributes)
        return
      }

      for (const attribute of result) {
        attributes[attribute.Name] = Object.assign(attribute.Value, attribute)
      }

      rs(attributes)
    })
  })
}

export const setUserAttributes = async (
  user: CognitoUser,
  attributes: Record<string, string>,
  __ctx: IRequestContext
) => {
  const prepared: ICognitoUserAttributeData[] = []

  for (const [key, value] of Object.entries(attributes)) {
    prepared.push({ Name: key, Value: value })
  }

  return new Promise<void>((rs, rj) => {
    user.updateAttributes(prepared, (err) => {
      if (err) {
        rj(err)
        return
      }
      rs()
    })
  })
}

export const setUserPassword = async (
  user: CognitoUser,
  oldPassword: string,
  newPassword: string,
  __ctx: IRequestContext
) => {
  return new Promise<void>((resolve, reject) => {
    user.changePassword(oldPassword, newPassword, (err) => {
      if (err) {
        reject(err)
        return
      }
      resolve()
    })
  })
}

export const getUserWallets = async (__ctx: IRequestContext) => {
  try {
    const response = await apiRequest({
      method: 'GET',
      endpoint: '/users/wallets',
      isAuthenticated: true,
    })
    return response.data as IAPIUserWallet[]
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const getUserTwitterXStats = async (__ctx: IRequestContext) => {
  try {
    const response = await apiRequest({
      method: 'GET',
      endpoint: '/users/integrations/twitterx/user',
      isAuthenticated: true,
    })
    return response.data as {
      followersCount: number | null
    }
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const getUserSocialRankStats = async (__ctx: IRequestContext) => {
  try {
    const response = await apiRequest({
      method: 'GET',
      endpoint: '/users/integrations/socialrank/user',
      isAuthenticated: true,
    })
    return response.data as {
      rankPosition: number | null
      requirementSocialRank: boolean
      requirementOptIn: boolean
      requirementTwitterLinked: boolean
      requirementWalletLinked: boolean
    }
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const registerOptinSocialWallets = async (
  consent: boolean,
  __ctx: IRequestContext
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: '/users/wallets-social-optin',
      isAuthenticated: true,
      body: {
        consent,
      },
    })
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const registerUserWallet = async (
  address: string,
  network: string,
  primary: boolean,
  __ctx: IRequestContext
) => {
  try {
    const response = await apiRequest({
      method: 'POST',
      endpoint: '/users/wallets',
      isAuthenticated: true,
      body: {
        address,
        network,
        primary,
      },
    })
    return response.data as IAPIUserWallet
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const verifyUserWallet = async (
  address: string,
  network: string,
  walletOwnershipToken: IWalletOwnershipToken,
  __ctx: IRequestContext
) => {
  try {
    const response = await apiRequest({
      method: 'POST',
      endpoint: `/users/wallets/${address}/verify`,
      isAuthenticated: true,
      body: {
        address,
        network,
        walletOwnershipToken,
      },
    })
    return response.data as IAPIUserWallet
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}

export const unlinkUserWallet = async (
  address: string,
  network: string,
  __ctx: IRequestContext
) => {
  try {
    const response = await apiRequest({
      method: 'POST',
      endpoint: `/users/wallets/${address}/unlink`,
      isAuthenticated: true,
      body: {
        address,
        network,
      },
    })
    return response.data as IAPIUserWallet
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        console.log('404 err')
        return FetchStatus.NOT_FOUND
      }
    }
    throw err
  }
}
