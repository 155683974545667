import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ImpulseSpinner } from 'react-spinners-kit'

import { BaseCard } from '@lattice/common/components'
import { DeploymentStage, EnvironmentContext } from '@lattice/runtime'

import styles from './view.module.scss'
import './view.simplex.scss'

declare global {
  interface Window {
    Simplex: any
    simplex: any
  }
}

const BuySimplexView = () => {
  const { t } = useTranslation()
  const bodyRef = useRef<HTMLDivElement>(null)
  const formScriptElement = useMemo(() => {
    const scriptElement = document.createElement('script')
    scriptElement.src =
      EnvironmentContext.stage === DeploymentStage.PRODUCTION
        ? 'https://iframe.simplex-affiliates.com/form-sdk.js'
        : 'https://iframe.sandbox.test-simplexcc.com/form-sdk.js'
    scriptElement.type = 'text/javascript'
    return scriptElement
  }, [])
  const trackingScriptElement = useMemo(() => {
    const scriptElement = document.createElement('script')
    scriptElement.src = 'https://checkout.simplexcc.com/splx.js'
    scriptElement.type = 'text/javascript'
    return scriptElement
  }, [])
  const [hasLoaded, setHasLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errorLoadingIframe, setErrorLoadingIframe] = useState(false)

  const checkIframe = () => {
    const formElement = document.getElementById('simplex-form')?.childNodes
    const errorElement = document.getElementById('error-box')
    let iframeCount = 0

    formElement?.forEach((element) => {
      if (element.nodeName === 'IFRAME') {
        iframeCount++
      }
    })

    if (formElement?.length === 3 && iframeCount === 2 && !errorElement) {
      setLoading(false)
      return
    }

    if (errorElement) {
      setLoading(false)
      setErrorLoadingIframe(true)
      return
    }

    setTimeout(checkIframe, 2000)
  }

  useEffect(() => {
    if (bodyRef.current !== null && !hasLoaded) {
      document.body.appendChild(trackingScriptElement)
      bodyRef.current.appendChild(formScriptElement)

      setTimeout(() => window.simplex.createForm(), 2000)
      setHasLoaded(true)
      checkIframe()
    }

    return () => {
      formScriptElement.remove()
      trackingScriptElement.remove()
    }
  }, [bodyRef.current])

  return (
    <BaseCard
      variants={['header-title']}
      className={{
        root: styles.root,
        header: styles.header,
        body: styles.body,
      }}
      header={<>{t('views.SwapBuyView.components.Buy.buy', 'Buy')}</>}
    >
      <p>
        {t(
          'views.SwapBuyView.components.Buy.ourPartnershipWith',
          'Our partnership with Simplex enables the purchase of DAG/LTX among 130+ cryptocurrencies with a wide range of payment methods, including Visa, MasterCard, Apple Pay, SWIFT, SEPA and more!'
        )}
      </p>
      <div ref={bodyRef} className={styles.simplexFormContainer}>
        <form id="simplex-form" className={styles.simplexForm}>
          <div id="checkout-element" className={styles.checkoutElement}></div>
        </form>
        {loading && (
          <div className={styles.spinnerContainer}>
            <ImpulseSpinner size={24} frontColor={'#fc0'} />
          </div>
        )}
        {errorLoadingIframe && (
          <p>
            {t(
              'views.SwapBuyView.components.Buy.errorLoadingIframe',
              `Cannot load Simplex service`
            )}
          </p>
        )}
      </div>
    </BaseCard>
  )
}

export { BuySimplexView }
