import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ILaunchpadProject,
  ILaunchpadProjectPool,
  ILaunchpadProjectUser,
  ILaunchpadUserAllocation,
  useWalletProvider,
} from '@lattice/common/providers'
import { formatNumber, NumberFormat, shortenAddress } from '@lattice/utils'
import {
  useFetchableOperation,
  useFetchableResource,
} from '@lattice/common/hooks'
import { useProgressToasts } from '@lattice/common/hooks/useProgressToasts'
import { FetchStatus } from '@lattice/common/consts'
import { Button } from '@lattice/common/components'
import { EvmNetwork, getNetworkTypedContract } from '@lattice/common/lib'

import { PoolTabs } from '../PoolTabs/component'
import { StakedInfoCard } from '../StakedInfoCard'
import { StakingDetailsCard } from '../StakingDetailsCard'

import styles from './component.module.scss'

type IStakingPool = {
  limit: number | null
  currencyPools: {
    [Key in ILaunchpadProjectPool['stakingCurrency']]?: ILaunchpadProjectPool
  }
}

const FormerStakingPool = ({
  project,
  projectUser,
  userAllocation,
}: {
  project: ILaunchpadProject
  projectUser: ILaunchpadProjectUser | null
  userAllocation: ILaunchpadUserAllocation
}) => {
  const { t } = useTranslation()
  const { activeWallet, accountsNetworkAssets } = useWalletProvider()
  const stakingContract = useMemo(
    () =>
      project.onChainContractAddress
        ? getNetworkTypedContract(
            'LatticeStakingPool',
            project.onChainContractAddress,
            EvmNetwork.ETHEREUM
          )
        : null,
    [project.onChainContractAddress]
  )
  const didUserWithdrawFromPool = useFetchableResource<boolean | null>(null)

  const withdrawFromPoolOperation = useFetchableOperation()
  const withdrawFromPoolOperationProgressToasts = useProgressToasts()

  const [activePool, setActivePool] = useState(0)
  const pools = useMemo(() => {
    const collections: Set<IStakingPool> = new Set()
    for (const pool of project.pools) {
      const collection = [...collections].find(
        (collection) => collection.limit === pool.stakingLimit
      ) ?? { limit: pool.stakingLimit, currencyPools: {} }

      collection.currencyPools[pool.stakingCurrency] = pool
      collections.add(collection)
    }
    return [...collections].sort(
      (a, b) => -(a.limit ?? Infinity) + (b.limit ?? Infinity)
    )
  }, [project.pools])

  const withdrawFromPool = withdrawFromPoolOperation.wrappedFetch(
    withdrawFromPoolOperationProgressToasts.wrappedErrorsAsync(async () => {
      const stakingPool = pools[activePool].currencyPools.ltx

      if (!stakingPool) {
        throw new Error('Invalid  pool state')
      }

      if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
        throw new Error('Invalid wallet state state')
      }

      if (!stakingContract) {
        throw new Error('Invalid staking contract state')
      }

      if (
        typeof project.onChainProjectId !== 'number' ||
        typeof stakingPool.onChainPoolId !== 'number'
      ) {
        throw new Error('Invalid project or pool state')
      }

      const userStakingContract = stakingContract.connect(
        activeWallet.ethereum.library.getSigner()
      )

      withdrawFromPoolOperationProgressToasts.progress(
        'Requesting transaction approval',
        'info',
        null
      )

      const trx = await userStakingContract.withdraw(
        project.onChainProjectId,
        stakingPool.onChainPoolId
      )

      withdrawFromPoolOperationProgressToasts.progress(
        'Awaiting network confirmation',
        'info',
        null
      )

      await trx.wait(3)

      withdrawFromPoolOperationProgressToasts.progress(
        'Withdraw successful',
        'success',
        15000
      )

      fetchWithdrawalStatusFromPool()
    })
  )

  const fetchWithdrawalStatusFromPool = didUserWithdrawFromPool.wrappedFetch(
    async () => {
      const stakingPool = pools[activePool].currencyPools.ltx

      if (!stakingPool) {
        throw new Error('Invalid  pool state')
      }

      if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
        throw new Error('Invalid wallet state state')
      }

      if (!stakingContract) {
        throw new Error('Invalid staking contract state')
      }

      if (
        typeof project.onChainProjectId !== 'number' ||
        typeof stakingPool.onChainPoolId !== 'number'
      ) {
        throw new Error('Invalid project or pool state')
      }

      const stakedZeroInPool = (
        await stakingContract.getAmountStakedByUserInPool(
          project.onChainProjectId,
          stakingPool.onChainPoolId,
          activeWallet.ethereum.account
        )
      ).isZero()

      if (stakedZeroInPool) {
        return null
      }

      const userDidWithdraw = await stakingContract.didUserWithdrawFunds(
        project.onChainProjectId,
        stakingPool.onChainPoolId,
        activeWallet.ethereum.account
      )

      return userDidWithdraw
    }
  )

  useEffect(() => {
    fetchWithdrawalStatusFromPool()
  }, [pools, project, activeWallet, activePool])

  if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
    return null
  }

  const allocatedLtxBalanceInPool = parseInt(
    String(
      projectUser
        ?.find(
          (wallet) =>
            wallet.address === activeWallet.ethereum.account &&
            wallet.currency === 'ltx'
        )
        ?.allocations.find(
          (allocation) =>
            allocation.lpProjectPoolId ===
            pools[activePool].currencyPools.ltx?.id
        )?.currentAmount ?? 0
    )
  )

  const availableLtxBalance = Math.max(
    0,
    (accountsNetworkAssets['ethereum:ltx']?.balance.toNumber() ?? 0) -
      (parseFloat(userAllocation.ltx) ?? 0)
  )

  return (
    <div className={styles.stakingPoolContainer}>
      <PoolTabs
        tabs={pools.map(
          (pool) =>
            `${
              pool.limit
                ? formatNumber(pool.limit, NumberFormat.MILLIFY)
                : t('views.Launchpad.componenrs.StakingPool.no', 'No')
            } ${t('views.Launchpad.componenrs.StakingPool.limit', 'limit')}`
        )}
        selectTab={setActivePool}
        activeTab={activePool}
      />
      <div className={styles.stakingPoolBody}>
        <div className={styles.stakingSection}>
          <StakedInfoCard
            stakeInfo={{
              label: 'Total staked in pool',
              stakedToken: {
                amount:
                  formatNumber(
                    Object.values(pools[activePool].currencyPools).reduce(
                      (total, cpool) => total + cpool.totalStaked,
                      0
                    ),
                    NumberFormat.WHOLE
                  ) ?? '--',
                ticker: 'LTX',
              },
              className: {
                container: styles.badgeContainer,
                badgeText: styles.badgeText,
                amountText: styles.amountText,
              },
            }}
          />
        </div>
        <div className={styles.stakingSection}>
          <StakingDetailsCard
            stakeDetails={{
              label: 'Your staking details',
              helpInfo: '',
              address: shortenAddress(
                activeWallet.ethereum.account,
                5,
                4,
                '•••'
              ),
              stakeStatuses: [
                {
                  ticker: 'LTX',
                  balance: formatNumber(
                    availableLtxBalance,
                    NumberFormat.WHOLE
                  ),
                  stakedAmount: formatNumber(
                    allocatedLtxBalanceInPool,
                    NumberFormat.WHOLE
                  ),
                  stakedPercentage: formatNumber(
                    (allocatedLtxBalanceInPool /
                      Object.values(pools[activePool].currencyPools).reduce(
                        (total, cpool) => total + cpool.totalStaked,
                        0
                      )) *
                      100,
                    NumberFormat.DECIMALS
                  ),
                  revoked: false,
                },
              ],
            }}
          />
        </div>
        <div className={styles.stakingSection}>
          <div className={styles.stakingActionSection}>
            {didUserWithdrawFromPool.status === FetchStatus.DONE &&
              didUserWithdrawFromPool.resource === false && (
                <Button
                  variants={['primary', 'full-width']}
                  loading={
                    withdrawFromPoolOperation.status === FetchStatus.PENDING
                  }
                  disabled={
                    withdrawFromPoolOperation.status === FetchStatus.PENDING
                  }
                  onClick={
                    withdrawFromPoolOperation.status === FetchStatus.PENDING
                      ? undefined
                      : withdrawFromPool
                  }
                >
                  Withdraw from pool
                </Button>
              )}
            {didUserWithdrawFromPool.status === FetchStatus.DONE &&
              didUserWithdrawFromPool.resource === true && (
                <Button disabled variants={['primary', 'full-width']}>
                  Already withdrawn from pool
                </Button>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { FormerStakingPool }
