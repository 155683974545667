import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { dag4 } from '@stardust-collective/dag4'

import {
  Button,
  Content,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  InfoRowsContainer,
  InputRow,
  NoContent,
  PendingContent,
  ProgramDetailProjectContainer,
  Tabs,
  Typography,
} from '@lattice/common/components'
import {
  useNodeManagerProvider,
  useUserOAuthProvider,
} from '@lattice/common/providers'
import { FetchStatus, OAuthProviderURI } from '@lattice/common/consts'
import { DetailsBaseCardContainer } from '@lattice/common/components/DetailsBaseCardContainer/component'
import { CardSectionContainer } from '@lattice/common/components/CardSectionContainer'
import { CardLabelContainer } from '@lattice/common/components/CardLabelContainer'
import { InfoRow } from '@lattice/views/SwapBuyView/views/SwapExolixView/components/common/InfoRow'
import { shortenAddress } from '@lattice/utils'
import { useNFTAttributeField } from '@lattice/common/hooks/useNFTAttribute'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import {
  CurrencyNetwork,
  DeploymentEvmChain,
  EvmChain,
  getNodeProperty,
  NodePropertyName,
  RegisteredToken,
  RegisteredTokens,
} from '@lattice/common/lib'

import { DtmNodeDeviceSelect, GeneralDetails } from './components'
import styles from './view.module.scss'

const DtmNodeDetailsView = () => {
  const params = useParams()
  const { userOAuthProviderStatuses, requestUserOAuthStatusForProvider } =
    useUserOAuthProvider()
  const { userNode, requestUserNode, setRewardTargetForNode } =
    useNodeManagerProvider()
  const redeemedNFTAttribute = useNFTAttributeField(
    'Redeemed',
    userNode.resource?.externalNode.nftSerial ?? null,
    RegisteredTokens[RegisteredToken.DTM].instances[DeploymentEvmChain].address
  )

  const [rewardsAddressEdit, setRewardsAddressEdit] = useState(false)
  const [rewardsAddress, setRewardsAddress] = useState('')
  const [rewardsAddressError, setRewardsAddressError] = useState('')
  const saveRewardsAddressOperation = useFetchableOperation()
  const saveRewardsAddressOperationProgressToasts = useProgressToasts()

  const doSaveRewardsAddress = saveRewardsAddressOperation.wrappedFetch(
    saveRewardsAddressOperationProgressToasts.wrappedErrorsAsync(async () => {
      if (!userNode.resource) {
        throw new Error('Invalid user node state')
      }

      const validAddress = dag4.keyStore.validateDagAddress(rewardsAddress)

      if (!validAddress) {
        throw new Error('Invalid rewards address')
      }

      saveRewardsAddressOperationProgressToasts.progress(
        'Saving rewards address',
        'info'
      )

      await setRewardTargetForNode(
        userNode.resource.slug,
        CurrencyNetwork.CONSTELLATION,
        rewardsAddress
      )

      setRewardsAddressEdit(false)
      setRewardsAddress('')
      setRewardsAddressError('')

      saveRewardsAddressOperationProgressToasts.progress(
        'Successfully saved address',
        'success',
        15000
      )

      params.slug && requestUserNode(params.slug)
    })
  )

  useEffect(() => {
    requestUserOAuthStatusForProvider(OAuthProviderURI.DOR_DTM)
    params.slug && requestUserNode(params.slug)
  }, [params.slug])

  return (
    <Content>
      {userNode.status === FetchStatus.PENDING && <PendingContent />}
      {userNode.status === FetchStatus.DONE && userNode.resource && (
        <>
          <Typography.DetailsTitle
            childNameTitle={userNode.resource.name}
            parentNameTitle={'Node Manager / DTM Nodes'}
          />
          <ProgramDetailProjectContainer>
            <DetailsMainCardContainer>
              <GeneralDetails />
              <Tabs>
                <div></div>
              </Tabs>
            </DetailsMainCardContainer>
            <DetailsSideCardsContainer>
              <DetailsBaseCardContainer variants={['full-width']}>
                <CardSectionContainer>
                  <a
                    href={`${
                      DeploymentEvmChain === EvmChain.ETHEREUM_MAINNET
                        ? 'https://opensea.io/assets/ethereum/'
                        : 'https://testnets.opensea.io/assets/sepolia/'
                    }${
                      RegisteredTokens[RegisteredToken.DTM].instances[
                        DeploymentEvmChain
                      ].address
                    }/${userNode.resource.externalNode.nftSerial}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={styles.nftImage}
                      src={`https://constellation-nfts-assets.s3.amazonaws.com/dtm/${String(
                        userNode.resource.externalNode.nftSerial
                      ).padStart(4, '0')}.png`}
                    />
                  </a>
                  <CardLabelContainer>Node details</CardLabelContainer>
                  <InfoRowsContainer>
                    <InfoRow
                      variants={['small-font']}
                      leftSide="Linked devices"
                      rightSide={`${
                        getNodeProperty(
                          userNode.resource,
                          NodePropertyName.DTM_NODE__DTM_DEVICES,
                          []
                        ).length
                      }`}
                    />
                    <InfoRow
                      variants={['small-font']}
                      leftSide="Active commissions"
                      rightSide={
                        userNode.resource.bountyNodes.length > 0
                          ? userNode.resource.bountyNodes.length
                          : 'No'
                      }
                    />
                    <InfoRow
                      variants={['small-font']}
                      leftSide="Redeemed?"
                      rightSide={
                        redeemedNFTAttribute.resource === 'Yes' ? 'Yes' : 'No'
                      }
                    />
                    <InfoRow
                      variants={['small-font']}
                      leftSide="Rewards address"
                      rightSide={
                        userNode.resource.rewardTarget ? (
                          <>
                            {shortenAddress(
                              userNode.resource.rewardTarget.wallet.address,
                              5,
                              5,
                              '•••'
                            )}{' '}
                            (
                            <span
                              className={styles.editRewardsAddress}
                              onClick={() => setRewardsAddressEdit((s) => !s)}
                            >
                              edit
                            </span>
                            )
                          </>
                        ) : (
                          <>
                            Not specified (
                            <span
                              className={styles.editRewardsAddress}
                              onClick={() => setRewardsAddressEdit((s) => !s)}
                            >
                              add
                            </span>
                            )
                          </>
                        )
                      }
                      bottomSide={
                        rewardsAddressEdit && (
                          <div className={styles.editRewardsAddressInput}>
                            <InputRow
                              variants={['full-width']}
                              placeholder={shortenAddress(
                                'DAG4pGXSUAn9ytfPcQCwmk3jqdwaurgU1YxxwTbw',
                                6,
                                6,
                                '•••'
                              )}
                              value={rewardsAddress}
                              onChange={(e) => {
                                setRewardsAddress(e.target.value)
                                const validAddress =
                                  dag4.keyStore.validateDagAddress(
                                    e.target.value
                                  )
                                validAddress
                                  ? setRewardsAddressError('')
                                  : setRewardsAddressError(
                                      'Please enter a valid DAG address'
                                    )
                              }}
                              error={rewardsAddressError}
                            />
                            <Button
                              variants={['secondary']}
                              disabled={
                                saveRewardsAddressOperation.status ===
                                FetchStatus.PENDING
                              }
                              loading={
                                saveRewardsAddressOperation.status ===
                                FetchStatus.PENDING
                              }
                              onClick={
                                saveRewardsAddressOperation.status ===
                                FetchStatus.PENDING
                                  ? () => void 0
                                  : doSaveRewardsAddress
                              }
                            >
                              Save
                            </Button>
                          </div>
                        )
                      }
                    />
                  </InfoRowsContainer>
                </CardSectionContainer>
              </DetailsBaseCardContainer>
              {userOAuthProviderStatuses.resource[OAuthProviderURI.DOR_DTM]
                ?.status === 'registered' &&
                getNodeProperty(
                  userNode.resource,
                  NodePropertyName.DTM_NODE__DTM_DEVICES,
                  []
                ).length === 0 &&
                redeemedNFTAttribute.resource === 'Yes' && (
                  <DetailsBaseCardContainer variants={['full-width']}>
                    <CardSectionContainer>
                      <DtmNodeDeviceSelect />
                    </CardSectionContainer>
                  </DetailsBaseCardContainer>
                )}
            </DetailsSideCardsContainer>
          </ProgramDetailProjectContainer>
        </>
      )}
      {userNode.status !== FetchStatus.PENDING && !userNode.resource && (
        <NoContent />
      )}
    </Content>
  )
}

export { DtmNodeDetailsView }
