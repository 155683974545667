export enum NodeType {
  SOFT_NODE = 'soft_node',
  DTM_NODE = 'dtm_node',
  TESTNET_VALIDATOR_NODE = 'testnet_validator_node',
  SOFT_STAKING_NODE = 'soft_staking_node',
}

export enum NodePropertyName {
  DTM_NODE__DTM_DEVICES = 'lattice.nodes.dtmnode::dorapi.dtm.linkedDevices',
}
