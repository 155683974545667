import React, { useEffect } from 'react'
import cls from 'classnames'

import {
  BaseCard,
  Button,
  Chip,
  InputRow,
  PendingContent,
} from '@lattice/common/components'
import { ReactComponent as ConstellationIcon } from '@lattice/assets/icons/custom/constellation.svg'
import { ReactComponent as EthereumIcon } from '@lattice/assets/icons/custom/ethereum.svg'
import { ReactComponent as SwitchOffIcon } from '@lattice/assets/icons/custom/switch-off.svg'
import { ReactComponent as SwitchOnIcon } from '@lattice/assets/icons/custom/switch-on.svg'
import { ReactComponent as TwitterXIcon } from '@lattice/assets/icons/custom/twitter_x.svg'
// import { ReactComponent as YouTubeIcon } from '@lattice/assets/icons/custom/youtube.svg'
import { ReactComponent as TrophyIcon } from '@lattice/assets/icons/carbon/trophy.svg'
import { ReactComponent as CheckmarkOutlineIcon } from '@lattice/assets/icons/carbon/checkmark-outline.svg'
import { ReactComponent as CloseOutlineIcon } from '@lattice/assets/icons/carbon/close-outline.svg'
import { useUserWalletsQueries } from '@lattice/common/queries/bundles'
import {
  formatNumber,
  NumberFormat,
  shortenAddress,
  useAsyncAction,
} from '@lattice/utils'
import {
  useConfirmActionProvider,
  useWalletProvider,
  useUserOAuthProvider,
  useUserProvider,
} from '@lattice/common/providers'
import {
  FetchStatus,
  isFetchStatus,
  OAuthProviderURI,
} from '@lattice/common/consts'
import { useUserQueries } from '@lattice/common/queries/bundles/user'
import { CurrencyNetwork } from '@lattice/common/lib'

import styles from './view.module.scss'

const SocialRankRequirement = ({
  name,
  fulfilled,
}: {
  name: React.ReactNode
  fulfilled?: boolean
}) => (
  <div className="flex flex-col gap-2 md:flex-row md:justify-between">
    <span className="text-[#F3F4F6]">{name}</span>
    {fulfilled ? (
      <span className="text-[#34D399] flex gap-2 items-center">
        <CheckmarkOutlineIcon className="lg:hidden h-5 w-5" />
        Yes
        <CheckmarkOutlineIcon className="hidden lg:inline h-5 w-5" />
      </span>
    ) : (
      <span className="text-[#B6B9C8] flex gap-2 items-center">
        <CloseOutlineIcon className="lg:hidden h-5 w-5" />
        No
        <CloseOutlineIcon className="hidden lg:inline h-5 w-5" />
      </span>
    )}
  </div>
)

export const SocialsWalletsView = () => {
  const { user, userDbData } = useUserProvider()

  const {
    operations,
    userOAuthProviderStatuses,
    doRequestOAuthFlowForProvider,
    requestUserOAuthStatusForProvider,
    unregisterUserOAuthToken,
  } = useUserOAuthProvider()
  const { requestConfirmation } = useConfirmActionProvider()
  const { activeWallet, requestConnectorActivation } = useWalletProvider()
  const wallets = useUserWalletsQueries()
  const userQueries = useUserQueries()

  const doUnlinkAccount = async (provider: OAuthProviderURI) => {
    const confirmation = await requestConfirmation({
      title: 'Unlink account',
      description:
        provider === OAuthProviderURI.TWITTER_X
          ? 'Are you sure that you want to unlink your X account? You will need to link a new account to start collecting rewards again.'
          : 'Are you sure that you want to unlink your YouTube account? You will need to link a new account to start collecting rewards again.',
      negativeButtonContent: 'Cancel',
      positiveButtonContent: 'Yes, unlink',
    })

    if (!confirmation) {
      return
    }

    await unregisterUserOAuthToken(provider)
    requestUserOAuthStatusForProvider(provider)
  }

  const [doRegisterWallet, { loading: loadingRegister }] = useAsyncAction(
    async (network: CurrencyNetwork, primary: boolean) => {
      if (activeWallet.status !== 'connected') {
        await requestConnectorActivation()
        return
      }

      if (
        network === CurrencyNetwork.CONSTELLATION &&
        activeWallet.constellation
      ) {
        const ownershipToken =
          await activeWallet.constellation.requestWalletOwnershipToken()

        await wallets.walletRegisterMut.mutateAsync({
          address: activeWallet.constellation.account,
          network: CurrencyNetwork.CONSTELLATION,
          primary,
        })

        await wallets.walletVerifyMut.mutateAsync({
          address: activeWallet.constellation.account,
          network: CurrencyNetwork.CONSTELLATION,
          ownershipToken,
        })
        return
      }

      if (network === CurrencyNetwork.ETHEREUM && activeWallet.ethereum) {
        const ownershipToken =
          await activeWallet.ethereum.requestWalletOwnershipToken()

        await wallets.walletRegisterMut.mutateAsync({
          address: activeWallet.ethereum.account,
          network: CurrencyNetwork.ETHEREUM,
          primary,
        })

        await wallets.walletVerifyMut.mutateAsync({
          address: activeWallet.ethereum.account,
          network: CurrencyNetwork.ETHEREUM,
          ownershipToken,
        })
        return
      }
    },
    []
  )

  const [doUnlinkWallet, { loading: loadingUnlink }] = useAsyncAction(
    async (address: string, network: CurrencyNetwork) => {
      const confirmation = await requestConfirmation({
        title: 'Unlink wallet',
        description:
          'Are you sure that you want to unlink your  wallet? You will need to link a new wallet to start collecting rewards again.',
        negativeButtonContent: 'Cancel',
        positiveButtonContent: 'Yes, unlink',
      })

      if (!confirmation) {
        return
      }

      await wallets.walletUnlinkMut.mutateAsync({
        address,
        network,
      })
    },
    []
  )

  const providerStatus_twitter =
    userOAuthProviderStatuses.resource[OAuthProviderURI.TWITTER_X]

  // const providerStatus_google =
  //   userOAuthProviderStatuses.resource[OAuthProviderURI.GOOGLE]

  const primaryConstellationWallet =
    wallets.wallets.data !== FetchStatus.NOT_FOUND
      ? wallets.wallets.data?.find(
          (wallet) =>
            wallet.network === CurrencyNetwork.CONSTELLATION && wallet.primary
        ) ?? null
      : null

  const primaryEthereumWallet =
    wallets.wallets.data !== FetchStatus.NOT_FOUND
      ? wallets.wallets.data?.find(
          (wallet) =>
            wallet.network === CurrencyNetwork.ETHEREUM && wallet.primary
        ) ?? null
      : null

  const additionalEthereumWallets =
    wallets.wallets.data !== FetchStatus.NOT_FOUND
      ? wallets.wallets.data?.filter(
          (wallet) =>
            wallet.network === CurrencyNetwork.ETHEREUM && !wallet.primary
        ) ?? []
      : []

  const isMobile = window.innerWidth < 768

  useEffect(() => {
    requestUserOAuthStatusForProvider(OAuthProviderURI.TWITTER_X)
    requestUserOAuthStatusForProvider(OAuthProviderURI.GOOGLE)
  }, [user])

  return (
    <section className={styles.main}>
      <BaseCard className={{ root: styles.card, body: styles.body }}>
        <div className={styles.title}>
          <h2>Social Accounts</h2>
          <span>Link your social accounts to start getting rewards.</span>
        </div>
        <div className={styles.items}>
          <div className={styles.link}>
            <h4>Twitter / X</h4>
            {[
              operations.checkAndFinishOAuthFlow.status === FetchStatus.PENDING,
              operations.unregisterUserOAuthToken.status ===
                FetchStatus.PENDING,
              operations.doRequestOAuthFlowForProvider.status !==
                FetchStatus.PENDING &&
                userOAuthProviderStatuses.status === FetchStatus.PENDING,
            ].some((c) => c) && <PendingContent variants={['min-height']} />}

            {operations.checkAndFinishOAuthFlow.status !==
              FetchStatus.PENDING &&
              operations.unregisterUserOAuthToken.status !==
                FetchStatus.PENDING && (
                <>
                  {providerStatus_twitter?.status === 'not_registered' && (
                    <Button
                      variants={['primary-outlined', 'full-width']}
                      onClick={() =>
                        doRequestOAuthFlowForProvider(
                          OAuthProviderURI.TWITTER_X
                        )
                      }
                      loading={
                        operations.doRequestOAuthFlowForProvider.status ===
                        FetchStatus.PENDING
                      }
                    >
                      <TwitterXIcon style={{ color: '#F0C519' }} />
                      Connect account
                    </Button>
                  )}
                  {providerStatus_twitter?.status === 'registered' && (
                    <InputRow
                      variants={['full-width']}
                      readOnly
                      value={'@' + providerStatus_twitter.publicRecord.username}
                      icon={<Chip variants={['mini']}>Unlink</Chip>}
                      onIconClick={() =>
                        doUnlinkAccount(OAuthProviderURI.TWITTER_X)
                      }
                    />
                  )}
                </>
              )}
          </div>
          {providerStatus_twitter?.status === 'registered' && (
            <div className={styles.link}>
              <h4>Social Reach Stats</h4>
              {userQueries.twitterxStats.isLoading ? (
                <PendingContent variants={['min-height']} />
              ) : (
                <>
                  <div className={styles.reachStat}>
                    <div className={styles.info}>
                      <div className={styles.title}>
                        <TwitterXIcon
                          style={{
                            color: '#FFF',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <span>
                          Followers{' '}
                          <span className={styles.sub}>(Twitter/X)</span>
                        </span>
                      </div>
                      <div className={styles.description}>
                        This is the number of followers you have on Twitter/X .
                        The count updates daily, so there might be a slight
                        delay in reflecting recent changes.
                      </div>
                    </div>
                    <div className={styles.value}>
                      {!isFetchStatus(userQueries.twitterxStats.data) &&
                      typeof userQueries.twitterxStats.data?.followersCount ===
                        'number'
                        ? formatNumber(
                            userQueries.twitterxStats.data.followersCount,
                            NumberFormat.WHOLE
                          )
                        : 'Processing your information, it will be available soon...'}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        {/* { (
          <div className={styles.items}>
            <div className={styles.link}>
              <h4>YouTube</h4>
              {[
                operations.checkAndFinishOAuthFlow.status ===
                  FetchStatus.PENDING,
                operations.unregisterUserOAuthToken.status ===
                  FetchStatus.PENDING,
                operations.doRequestOAuthFlowForProvider.status !==
                  FetchStatus.PENDING &&
                  userOAuthProviderStatuses.status === FetchStatus.PENDING,
              ].some((c) => c) && <PendingContent variants={['min-height']} />}
              {operations.checkAndFinishOAuthFlow.status !==
                FetchStatus.PENDING &&
                operations.unregisterUserOAuthToken.status !==
                  FetchStatus.PENDING && (
                  <>
                    {providerStatus_google?.status === 'not_registered' && (
                      <Button
                        variants={['primary-outlined', 'full-width']}
                        onClick={() =>
                          doRequestOAuthFlowForProvider(OAuthProviderURI.GOOGLE)
                        }
                        loading={
                          operations.doRequestOAuthFlowForProvider.status ===
                          FetchStatus.PENDING
                        }
                      >
                        <YouTubeIcon
                          style={{ color: '#F0C519' }}
                          className="size-6"
                        />
                        Connect account
                      </Button>
                    )}
                    {providerStatus_google?.status === 'registered' && (
                      <InputRow
                        variants={['full-width']}
                        readOnly
                        value={providerStatus_google.publicRecord.username}
                        icon={<Chip variants={['mini']}>Unlink</Chip>}
                        onIconClick={() =>
                          doUnlinkAccount(OAuthProviderURI.GOOGLE)
                        }
                      />
                    )}
                  </>
                )}
            </div>
          </div>
        )} */}
      </BaseCard>
      <BaseCard className={{ root: styles.card, body: styles.body }}>
        <div className={styles.title}>
          <h2>Wallet Addresses</h2>
          <span>
            Link your wallet address from different networks to start getting
            rewards.
          </span>
        </div>
        <div className={styles.items}>
          {(wallets.wallets.isLoading || loadingUnlink) && (
            <PendingContent variants={['min-height']} />
          )}
          {!wallets.wallets.isLoading && (
            <>
              <div className={styles.link}>
                <h4>Constellation</h4>
                <h5>Primary address</h5>
                {primaryConstellationWallet === null && (
                  <Button
                    variants={['primary-outlined', 'full-width']}
                    onClick={() =>
                      doRegisterWallet(CurrencyNetwork.CONSTELLATION, true)
                    }
                    loading={loadingRegister}
                  >
                    <ConstellationIcon />
                    Link Wallet
                  </Button>
                )}
                {primaryConstellationWallet !== null && (
                  <InputRow
                    variants={['full-width']}
                    readOnly
                    value={
                      isMobile
                        ? shortenAddress(
                            primaryConstellationWallet.address,
                            6,
                            6
                          )
                        : primaryConstellationWallet.address
                    }
                    icon={<Chip variants={['mini']}>Unlink</Chip>}
                    onIconClick={() =>
                      doUnlinkWallet(
                        primaryConstellationWallet.address,
                        primaryConstellationWallet.network
                      )
                    }
                  />
                )}
              </div>
              <div className={styles.link}>
                <h4>Ethereum / EVM</h4>
                <h5>Primary address</h5>
                {primaryEthereumWallet === null && (
                  <Button
                    variants={['primary-outlined', 'full-width']}
                    onClick={() =>
                      doRegisterWallet(CurrencyNetwork.ETHEREUM, true)
                    }
                    loading={loadingRegister}
                  >
                    <EthereumIcon />
                    Link Wallet
                  </Button>
                )}
                {primaryEthereumWallet !== null && (
                  <InputRow
                    variants={['full-width']}
                    readOnly
                    value={
                      isMobile
                        ? shortenAddress(primaryEthereumWallet.address, 6, 6)
                        : primaryEthereumWallet.address
                    }
                    icon={<Chip variants={['mini']}>Unlink</Chip>}
                    onIconClick={() =>
                      doUnlinkWallet(
                        primaryEthereumWallet.address,
                        primaryEthereumWallet.network
                      )
                    }
                  />
                )}
                {(primaryEthereumWallet !== null ||
                  additionalEthereumWallets.length > 0) && (
                  <>
                    <h5>Additional addresses</h5>
                    {additionalEthereumWallets.map((wallet) => (
                      <InputRow
                        key={wallet.id}
                        variants={['full-width']}
                        readOnly
                        value={
                          isMobile
                            ? shortenAddress(wallet.address, 6, 6)
                            : wallet.address
                        }
                        icon={<Chip variants={['mini']}>Unlink</Chip>}
                        onIconClick={() =>
                          doUnlinkWallet(wallet.address, wallet.network)
                        }
                      />
                    ))}
                    {primaryEthereumWallet !== null && (
                      <Button
                        variants={['primary-outlined', 'full-width']}
                        onClick={() =>
                          doRegisterWallet(CurrencyNetwork.ETHEREUM, false)
                        }
                        loading={loadingRegister}
                      >
                        <EthereumIcon />
                        Add Ethereum Wallet
                      </Button>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </BaseCard>
      <BaseCard className={{ root: styles.card, body: styles.body }}>
        <div className={styles.title}>
          <h4>Allow wallet addresses and social accounts to be Public</h4>
          <span className={styles.description}>
            This information will be shared with the El Paca Metagraph to be
            able to send rewards to your primary DAG address based on your
            twitter activity and EVM/ETH wallet activity.
            <br />
            <br />
            By enabling this setting, you are agreeing to have your socials and
            wallet address shared with 3rd party projects, in order to be used
            according to their own needs.
          </span>
        </div>
        <div className={styles.items}>
          <div className={styles.optin}>
            {userDbData.status === FetchStatus.PENDING ||
            userQueries.optinSocialWalletsMut.isPending ? (
              <PendingContent variants={['min-height']} />
            ) : (
              <>
                {userDbData.resource?.optinSocialWalletsInformationAt ===
                null ? (
                  <SwitchOffIcon
                    onClick={() =>
                      userQueries.optinSocialWalletsMut.mutate({
                        consent: true,
                      })
                    }
                  />
                ) : (
                  <SwitchOnIcon
                    onClick={() =>
                      userQueries.optinSocialWalletsMut.mutate({
                        consent: false,
                      })
                    }
                  />
                )}{' '}
                <span>Opt-in (Public)</span>
              </>
            )}
          </div>
        </div>
      </BaseCard>
      <BaseCard className={{ root: styles.card, body: styles.body }}>
        <div className={styles.title}>
          <h4>Rewards eligibility</h4>
          <span className={styles.description}>
            To be eligible for $PACA rewards from posting, you’ll need to meet
            these 4 requirements:
          </span>
        </div>
        <div className={styles.items}>
          <div className={styles.link}>
            {userQueries.socialRankStats.isLoading ||
            isFetchStatus(userQueries.socialRankStats.data) ? (
              <PendingContent variants={['min-height']} />
            ) : (
              <>
                <div className="flex flex-col p-4 border border-gray-700 rounded-md gap-4 bg-[#2D313D]">
                  <SocialRankRequirement
                    name="A valid linked X account"
                    fulfilled={
                      userQueries.socialRankStats.data?.requirementTwitterLinked
                    }
                  />
                  <SocialRankRequirement
                    name="A valid DAG address linked"
                    fulfilled={
                      userQueries.socialRankStats.data?.requirementWalletLinked
                    }
                  />
                  <SocialRankRequirement
                    name="Opt-in to sharing information"
                    fulfilled={
                      userQueries.socialRankStats.data?.requirementOptIn
                    }
                  />
                  <SocialRankRequirement
                    name="In the Top 150 Social Rank of eligible users"
                    fulfilled={
                      userQueries.socialRankStats.data?.requirementSocialRank
                    }
                  />
                </div>
                <div className={cls(styles.reachStat, 'bg-[#2D313D]')}>
                  <div className={styles.info}>
                    <div className={styles.title}>
                      <TrophyIcon />
                      <span>Social Rank</span>
                    </div>
                    <div className={styles.description}>
                      Your rank shows how your Twitter/X follower count stacks
                      up against others on Lattice, and unlock access to
                      exclusive El Paca community activities.
                    </div>
                  </div>
                  <div className={styles.value}>
                    {!isFetchStatus(userQueries.socialRankStats.data) &&
                    typeof userQueries.socialRankStats.data?.rankPosition ===
                      'number'
                      ? '#' +
                        formatNumber(
                          userQueries.socialRankStats.data.rankPosition,
                          NumberFormat.WHOLE
                        )
                      : '--'}
                  </div>
                </div>
                <div className="flex flex-col p-4 border border-gray-700 rounded-md gap-1 bg-[#2D313D]">
                  {(() => {
                    const requirementCounts = [
                      userQueries.socialRankStats.data?.requirementOptIn,
                      userQueries.socialRankStats.data?.requirementSocialRank,
                      userQueries.socialRankStats.data
                        ?.requirementTwitterLinked,

                      userQueries.socialRankStats.data?.requirementWalletLinked,
                    ].reduce((pv, cv) => pv + (cv ? 1 : 0), 0)

                    return (
                      <>
                        <span className="text-[#EED03A] text-sm">
                          {requirementCounts === 0 && (
                            <>Let’s get you started 🚀</>
                          )}
                          {requirementCounts === 1 && (
                            <>1 down, 3 more to go 🙂 </>
                          )}
                          {requirementCounts === 2 && (
                            <>You’re halfway there 😀</>
                          )}
                          {requirementCounts === 3 && (
                            <>You’re almost there 😯</>
                          )}
                          {requirementCounts === 4 && (
                            <>Congrats, you’re eligible 😎</>
                          )}
                        </span>
                        <span className="text-[#F3F4F6] text-xs">
                          {requirementCounts === 0 && (
                            <>
                              You're only 4 steps away from unlocking $PACA
                              rewards!
                            </>
                          )}
                          {requirementCounts === 1 && (
                            <>
                              You’re making progress towards being eligible for
                              $PACA rewards!
                            </>
                          )}
                          {requirementCounts === 2 && (
                            <>
                              Only 2 more requirements left to be eligible for
                              rewards!
                            </>
                          )}
                          {requirementCounts === 3 && (
                            <>
                              Just one more qualifier to be eligible for
                              rewards!
                            </>
                          )}
                          {requirementCounts === 4 && (
                            <>
                              Now you can start collecting daily rewards when
                              posting with any of the following tags:
                              <br />
                              1. $DAG
                              <br />
                              2. #AmericasBlockchain
                            </>
                          )}
                        </span>
                      </>
                    )
                  })()}
                </div>
              </>
            )}
          </div>
        </div>
      </BaseCard>
    </section>
  )
}
