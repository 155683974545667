import React, { useState } from 'react'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

import { IFilter } from '@lattice/common/types'
import { apiRequest, APIError } from '@lattice/common/lib'
import { FetchStatus } from '@lattice/common/consts'

import { useUserProvider } from '../UserProvider'

import {
  RewardsHistoryGraphType,
  RewardsHistoryGraphPeriod,
  RewardsHistoryGraphAggregate,
  RewardsHistoryField,
  NodeRewardDataType,
  NodeReward_TimeVsRewards_GraphPoint,
  IRewardsHistoryContext,
  NodeRewardType,
} from './types'

const RewardRangeMap: Map<
  IRewardsHistoryContext['range'],
  {
    value: (t: TFunction) => string
    period: IRewardsHistoryContext['graphPeriod']
  }
> = new Map([
  [
    'P7D',
    {
      value: (t) => t('containers.RewardsHistory.rewardsRangeMap.7d', '7D'),
      period: RewardsHistoryGraphPeriod.day,
    },
  ],
  [
    'P30D',
    {
      value: (t) => t('containers.RewardsHistory.rewardsRangeMap.30d', '30D'),
      period: RewardsHistoryGraphPeriod.day,
    },
  ],
  [
    'P90D',
    {
      value: (t) => t('containers.RewardsHistory.rewardsRangeMap.90d', '90D'),
      period: RewardsHistoryGraphPeriod.day,
    },
  ],
  [
    'YTD',
    {
      value: (t) => t('containers.RewardsHistory.rewardsRangeMap.ytd', 'YTD'),
      period: RewardsHistoryGraphPeriod.month,
    },
  ],
  [
    null,
    {
      value: (t) => t('containers.RewardsHistory.rewardsRangeMap.all', 'All'),
      period: RewardsHistoryGraphPeriod.month,
    },
  ],
])

const RewardsHistoryProviderContext =
  React.createContext<IRewardsHistoryContext | null>(null)

const RewardsHistoryProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { user } = useUserProvider()
  const [nodeTypes, setNodeTypes] = useState<NodeRewardType[]>([])
  const [nodeRewards, setNodeRewards] = useState<NodeRewardDataType[]>([])
  const [nodeRewardsTotal, setNodeRewardsTotal] = useState(0)
  const [nodeRewardsFetchStatus, setNodeRewardsFetchStatus] = useState(
    FetchStatus.IDLE
  )
  const [nodeRewardsGraphPoints, setNodeRewardsGraphPoints] = useState<
    NodeReward_TimeVsRewards_GraphPoint[]
  >([])
  const [
    nodeRewardsGraphPointsFetchStatus,
    setNodeRewardsGraphPointsFetchStatus,
  ] = useState(FetchStatus.IDLE)

  const [limit, setLimit] = useState<IRewardsHistoryContext['limit']>(25)
  const [offset, setOffset] = useState<IRewardsHistoryContext['offset']>(0)

  const [sortedColumn, setSortedColumn] = useState<
    IRewardsHistoryContext['sortedColumn']
  >([RewardsHistoryField.transactionSentAt, 'DESC'])
  const [rewardType, setRewardType] =
    useState<IRewardsHistoryContext['rewardType']>(null)
  const [range, setRange] = useState<IRewardsHistoryContext['range']>('YTD')

  const [graphPeriod, setGraphPeriod] = useState<
    IRewardsHistoryContext['graphPeriod']
  >(RewardsHistoryGraphPeriod.month)

  const buildFilter = (): IFilter | null => {
    if (rewardType === null) {
      return null
    }

    return { $key: RewardsHistoryField.nodeType, $value: rewardType }
  }

  const fetchNodeTypes = async () => {
    if (!user) {
      setNodeTypes([])
      return
    }

    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/rewards-history/node-types`,
      isAuthenticated: true,
    })

    setNodeTypes(data)
  }

  const fetchHistoricalData = async () => {
    if (!user) {
      setNodeRewards([])
      return
    }
    const filter = buildFilter()
    const baseDate = dayjs().utc()

    try {
      setNodeRewardsFetchStatus(FetchStatus.PENDING)
      const params = new URLSearchParams()
      params.set('limit', String(limit))
      params.set('offset', String(offset))
      params.set('sortedBy', sortedColumn.join('::'))
      range &&
        (range === 'YTD'
          ? params.set(
              'range',
              [
                baseDate.startOf('year').toISOString(),
                baseDate.startOf('year').add(1, 'year').toISOString(),
              ].join('::')
            )
          : params.set(
              'range',
              [
                baseDate.subtract(dayjs.duration(range)).toISOString(),
                baseDate.toISOString(),
              ].join('::')
            ))
      filter && params.set('filter', JSON.stringify(filter))

      const { data, meta } = await apiRequest({
        method: 'GET',
        endpoint: `/rewards-history/historic?${params}`,
        isAuthenticated: true,
      })
      setNodeRewards(data)
      setNodeRewardsTotal(meta.total)
      setNodeRewardsFetchStatus(FetchStatus.DONE)
    } catch (e) {
      console.error(e)
      if (e instanceof APIError) {
        if (e.errorCode === 404) {
          setNodeRewardsFetchStatus(FetchStatus.NOT_FOUND)
          return
        }
      }

      setNodeRewardsFetchStatus(FetchStatus.SERVER_ERROR)
      throw e
    }
  }

  const fetchGraphPoints = async () => {
    const filter = buildFilter()
    const baseDate = dayjs().utc()
    if (!user) {
      setNodeRewardsGraphPoints([])
      return
    }
    try {
      setNodeRewardsGraphPointsFetchStatus(FetchStatus.PENDING)
      const params = new URLSearchParams()
      params.set('type', RewardsHistoryGraphType.time_vs_rewards)
      params.set('period', graphPeriod)
      params.set('aggregate', RewardsHistoryGraphAggregate.sum)
      range &&
        (range === 'YTD'
          ? params.set(
              'range',
              [
                baseDate.startOf('year').toISOString(),
                baseDate.toISOString(),
              ].join('::')
            )
          : params.set(
              'range',
              [
                baseDate.subtract(dayjs.duration(range)).toISOString(),
                baseDate.toISOString(),
              ].join('::')
            ))
      filter && params.set('filter', JSON.stringify(filter))

      const { data } = await apiRequest({
        method: 'GET',
        endpoint: `/rewards-history/graphs?${params}`,
        isAuthenticated: true,
      })
      setNodeRewardsGraphPoints(data)
      setNodeRewardsGraphPointsFetchStatus(FetchStatus.DONE)
    } catch (e) {
      console.error(e)
      if (e instanceof APIError) {
        if (e.errorCode === 404) {
          setNodeRewardsGraphPointsFetchStatus(FetchStatus.NOT_FOUND)
          return
        }
      }

      setNodeRewardsGraphPointsFetchStatus(FetchStatus.SERVER_ERROR)
      throw e
    }
  }

  const ctx: IRewardsHistoryContext = {
    nodeTypes,
    nodeRewards,
    nodeRewardsFetchStatus,
    nodeRewardsGraphPoints,
    nodeRewardsGraphPointsFetchStatus,
    fetchNodeTypes,
    fetchGraphPoints,
    fetchHistoricalData,

    limit,
    setLimit,
    offset,
    setOffset,

    sortedColumn,
    setSortedColumn: (column, sort) => setSortedColumn([column, sort]),
    rewardType,
    setRewardType,

    range,
    setRange,
    graphPeriod,
    setGraphPeriod,

    currentPage: Math.floor(offset / limit) + 1,

    hasNextPage: offset + limit < Math.ceil(nodeRewardsTotal / limit) * limit,
    nextPage: () => setOffset(offset + limit),

    hasPreviousPage: offset > 0,
    previousPage: () => setOffset(offset - limit),
  }

  return (
    <RewardsHistoryProviderContext.Provider value={ctx}>
      {children}
    </RewardsHistoryProviderContext.Provider>
  )
}

export { RewardsHistoryProvider, RewardsHistoryProviderContext, RewardRangeMap }
