import crypto from 'crypto'

import dayjs from 'dayjs'

import { EnvironmentContext } from '@lattice/runtime'
import { cachedValueOrNull, setCachedValue } from '@lattice/utils'

import { IRequestParams } from './types'
import {
  SecuredProxiedApi as SecuredProxiedApi,
  SecuredProxyApiConfig,
} from './consts'

export const fetchRequestToken = async () => {
  const tokenResponse = await SecuredProxiedApi.get<{
    data: { token: string }
  }>('/external-requests/token', {
    headers: { 'x-lattice-api-key': EnvironmentContext.SecuredProxiedApiKey },
  })

  return tokenResponse.data.data.token
}

export const getOrFetchRequestToken = async () => {
  let token = cachedValueOrNull<string>(
    SecuredProxyApiConfig.tokenKey,
    'session'
  )
  if (!token) {
    token = await fetchRequestToken()
    setCachedValue(
      SecuredProxyApiConfig.tokenKey,
      'session',
      token,
      dayjs().add(55, 'minutes').valueOf()
    )
  }
  return token
}

export const generateRequestPayload = (
  token: string,
  requestParams: IRequestParams
) => {
  return Buffer.concat([
    Buffer.from(token),
    Buffer.from(requestParams.service),
    Buffer.from(requestParams.path),
    Buffer.from(requestParams.searchParams),
    Buffer.from(requestParams.rawBody ?? ''),
  ])
}

export const generateRequestHash = (
  token: string,
  requestParams: IRequestParams
) => {
  const payload = generateRequestPayload(token, requestParams)
  return crypto.createHash('sha256').update(payload).digest('base64')
}

export const generateRequestSignature = (
  token: string,
  requestParams: IRequestParams
) => {
  const payload = generateRequestPayload(token, requestParams)
  return crypto.createHmac('sha256', token).update(payload).digest('base64')
}
