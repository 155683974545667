import { HgtpNetworksBlockExplorerApi } from './be_api'
import { HgtpNetwork } from './consts'

export type IBESnapshot = {
  hash: string
  ordinal: number
  height: number
  subHeight: number
  lastSnapshotHash: string
  blocks: []
  timestamp: IISODate
  fee?: number
  ownerAddress?: string
  stakingAddress?: string | null
  sizeInKB?: number
}

export class HgtpNetworkSnapshots {
  static async getLatestBESnapshot(network: HgtpNetwork) {
    const response = await HgtpNetworksBlockExplorerApi[network].get<{
      data: IBESnapshot
    }>(`/global-snapshots/latest`)

    return response.data.data
  }

  static async getBESnapshot(network: HgtpNetwork, ordinal: number) {
    const response = await HgtpNetworksBlockExplorerApi[network].get<{
      data: IBESnapshot
    }>(`/global-snapshots/${ordinal}`)

    return response.data.data
  }

  static async getMetagraphLatestBESnapshot(
    network: HgtpNetwork,
    metagraphId: string
  ) {
    const response = await HgtpNetworksBlockExplorerApi[network].get<{
      data: IBESnapshot
    }>(`/currency/${metagraphId}/snapshots/latest`)

    return response.data.data
  }

  static async getMetagraphBESnapshot(
    network: HgtpNetwork,
    metagraphId: string,
    ordinal: number
  ) {
    const response = await HgtpNetworksBlockExplorerApi[network].get<{
      data: IBESnapshot
    }>(`/currency/${metagraphId}/snapshots/${ordinal}`)

    return response.data.data
  }
}
