import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import Decimal from 'decimal.js'
import dayjs from 'dayjs'

import {
  IUserWalletSoftNodeAndPrograms,
  useSoftNodesProvider,
} from '@lattice/common/providers'
import {
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  ITabSection,
  NoContent,
  PendingContent,
  ProgramDetailProjectContainer,
  Table,
} from '@lattice/common/components'
import { FetchStatus } from '@lattice/common/consts'
import { NotFoundView } from '@lattice/views/NotFoundView'
import { formatNumber, NumberFormat } from '@lattice/utils'
import { ReactComponent as CheckFilledIcon } from '@lattice/assets/icons/carbon/checkmark-filled.svg'
import { ReactComponent as ErrorFilledIcon } from '@lattice/assets/icons/custom/error-filled.svg'
import { NetworkCurrenciesUtils } from '@lattice/common/lib'

import { getNodeLifetimeRewards, formatFracPercentage } from '../../utils'
import { NodeDetailsCard, NodeProgramsCard } from '../../components'

import styles from './view.module.scss'

type IProgramRecord = {
  program: [string, string]
  shards: [number, number]
  fracShards: [[number, number], number]
  totalStaked: [number, number, number, number]
  accruedRewards: number | null
}

const NodeDetailsView = () => {
  const { t } = useTranslation()
  const params = useParams()
  const { userHistoryProjects, requestUserHistoryProjects } =
    useSoftNodesProvider()

  const [selectedNode, setSelectedNode] = useState<
    IUserWalletSoftNodeAndPrograms | undefined
  >()
  const [programsInfo, setProgramsInfo] = useState<IProgramRecord[]>([])

  const sections: ITabSection[] = []
  sections.push({
    name: 'Program info',
    content: <NoContent />,
  })

  useEffect(() => {
    if (params.softNode) {
      requestUserHistoryProjects()
    }
  }, [])

  useEffect(() => {
    if (userHistoryProjects.status === FetchStatus.DONE) {
      const foundNode = userHistoryProjects.resource.find(
        (node) => node.alias === params.softNode
      )
      const programsInfo: IProgramRecord[] = []
      foundNode?.programs.forEach((program) =>
        programsInfo.push({
          program: [program.name, program.slug],
          shards: program.shards,
          fracShards: [program.frac, program.nodePrice],
          totalStaked: [
            program.shards[0],
            program.shards[1],
            program.frac[1],
            program.nodePrice,
          ],
          accruedRewards:
            dayjs(program.stakingStartsAt).isBefore(dayjs()) &&
            dayjs(program.stakingWindowEndsAt).isAfter(dayjs())
              ? null
              : program.userTotalAccruedRewards,
        })
      )
      setProgramsInfo(programsInfo)
      setSelectedNode(foundNode)
    }
  }, [userHistoryProjects])

  if (userHistoryProjects.status === FetchStatus.DONE && !selectedNode) {
    return <NotFoundView />
  }

  return userHistoryProjects.status === FetchStatus.PENDING || !selectedNode ? (
    <PendingContent />
  ) : (
    <ProgramDetailProjectContainer>
      <DetailsMainCardContainer>
        <NodeProgramsCard
          lifetimeRewards={getNodeLifetimeRewards(selectedNode)}
          variants={['full-width']}
          title={selectedNode.alias}
          bannerUrl={selectedNode.programs[0].bannerUrl}
          walletAddress={selectedNode.walletAddress}
        >
          <div className={styles.tableContainer}>
            <div className={styles.tableUpperInfoRow}>
              <div className={styles.tableTitle}>
                {t(
                  'views.NodeManager.views.NodeDetails.programHistory',
                  'Program history'
                )}
              </div>
              <Link to={'/user/rewards'}>
                {t(
                  'views.NodeManager.views.NodeDetails.rewardsHistory',
                  'view rewards history ->'
                )}
              </Link>
            </div>
            <Table
              className={{
                headerCell: styles.headerCells,
                bodyCell: styles.fixedBody,
                bodyRow: styles.bodyRow,
                cells: {
                  header: {
                    accruedRewards: {
                      cell: styles.accruedColumn,
                    },
                  },
                  body: {
                    accruedRewards: {
                      cell: styles.accruedColumn,
                    },
                  },
                },
              }}
              primaryKey="program"
              data={programsInfo}
              emptyState={{
                program: '---',
                shards: '---',
                fracShards: '---',
                totalStaked: '---',
                accruedRewards: '---',
              }}
              titles={{
                program: {
                  content: t(
                    'views.NodeManager.views.NodeDetails.programsTable.columns.program',
                    'Program'
                  ),
                  sortable: false,
                },
                shards: {
                  content: t(
                    'views.NodeManager.views.NodeDetails.programsTable.columns.shards',
                    'Shards'
                  ),
                  sortable: false,
                },
                fracShards: {
                  content: t(
                    'views.NodeManager.views.NodeDetails.programsTable.columns.fracShards',
                    'Frac. shard'
                  ),
                  sortable: false,
                },
                totalStaked: {
                  content: t(
                    'views.NodeManager.views.NodeDetails.programsTable.columns.totalStaked',
                    'Total Staked'
                  ),
                  sortable: false,
                },
                accruedRewards: {
                  content: t(
                    'views.NodeManager.views.NodeDetails.programsTable.columns.accruedRewards',
                    'Accrued Rewards'
                  ),
                  sortable: false,
                },
              }}
              formatData={{
                program: (value) => (
                  <Link
                    className={styles.programLink}
                    to={'/rewards/soft-nodes/' + value[1]}
                  >
                    {value[0]}
                  </Link>
                ),
                shards: (value) => {
                  const totalShards = value[0] + value[1]
                  const validShards = value[0]

                  return (
                    <div className={styles.iconField}>
                      {totalShards === validShards ? (
                        <CheckFilledIcon color="#19BD39" />
                      ) : totalShards === 0 ? undefined : (
                        <ErrorFilledIcon color="#FFCC00" />
                      )}
                      {validShards + ' of ' + totalShards}
                    </div>
                  )
                },
                fracShards: (value) => {
                  const initialFracPercentage = (
                    (value[0][0] / value[1]) *
                    100
                  ).toFixed(2)

                  const currentFracPercentage = (
                    (value[0][1] / value[1]) *
                    100
                  ).toFixed(2)
                  return (
                    <div className={styles.iconField}>
                      {initialFracPercentage === currentFracPercentage ? (
                        <CheckFilledIcon
                          color="#19BD39"
                          height={'14px'}
                          width={'14px'}
                        />
                      ) : (
                        <ErrorFilledIcon
                          color="#FFCC00"
                          height={'14px'}
                          width={'14px'}
                        />
                      )}
                      {formatFracPercentage(
                        initialFracPercentage,
                        currentFracPercentage
                      )}
                    </div>
                  )
                },
                totalStaked: (value) => {
                  const validShards = value[0]
                  const currentFracBalance = value[2]
                  const nodePrice = value[3]

                  return (
                    formatNumber(
                      new Decimal(
                        validShards * nodePrice + currentFracBalance
                      ).div(
                        Decimal.pow(
                          10,
                          NetworkCurrenciesUtils['constellation:dag'].decimals
                        )
                      ),
                      NumberFormat.MILLIFY
                    ) + ' DAG'
                  )
                },
                accruedRewards: (value) =>
                  value !== null
                    ? formatNumber(
                        new Decimal(value).div(
                          Decimal.pow(
                            10,
                            NetworkCurrenciesUtils['constellation:dag'].decimals
                          )
                        ),
                        NumberFormat.DECIMALS
                      ) + ' DAG'
                    : '--',
              }}
            />
          </div>
        </NodeProgramsCard>
      </DetailsMainCardContainer>
      <DetailsSideCardsContainer>
        <NodeDetailsCard title={'Soft Node Details'} node={selectedNode} />
      </DetailsSideCardsContainer>
    </ProgramDetailProjectContainer>
  )
}

export { NodeDetailsView }
