// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CIJJMcuNvRvpdqXo9yxq{display:flex;flex-direction:column;align-items:center;width:100%;gap:14px;padding:30px}.buzQ6fXW7hf78jjxyS5C{font-family:IBM Plex Mono;font-size:32px;font-weight:400;line-height:40px;letter-spacing:0em;text-align:center}.n7iQLaKiIleAuUSOKctY{font-family:TWK Lausanne;font-size:16px;font-weight:400;line-height:24px;letter-spacing:.200000003px;text-align:left;color:#fff}.I8m9VnCbgIc24ZWTjqny{color:#22c55e}`, "",{"version":3,"sources":["webpack://./src/views/RewardsView/views/PacaSwapNodeOperatorProgramDetailView/components/NodeOperatorQueuePositionCard/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,QAAA,CACA,YAAA,CAGF,sBACE,yBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBAEE,wBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,2BAAA,CACA,eAAA,CACA,UAAA,CAGF,sBACE,aAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 14px;\n  padding: 30px;\n}\n\n.supertitleText {\n  font-family: IBM Plex Mono;\n  font-size: 32px;\n  font-weight: 400;\n  line-height: 40px;\n  letter-spacing: 0em;\n  text-align: center;\n}\n\n.subtitleText {\n  //styleName: Subtitle 1;\n  font-family: TWK Lausanne;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0.20000000298023224px;\n  text-align: left;\n  color: #ffffff;\n}\n\n.greenText {\n  color: #22c55e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CIJJMcuNvRvpdqXo9yxq`,
	"supertitleText": `buzQ6fXW7hf78jjxyS5C`,
	"subtitleText": `n7iQLaKiIleAuUSOKctY`,
	"greenText": `I8m9VnCbgIc24ZWTjqny`
};
export default ___CSS_LOADER_EXPORT___;
