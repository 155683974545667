import axios from 'axios'

import { addRetryBehavior } from '@lattice/utils'

import { HgtpNetwork, HgtpNetworksUrls } from './consts'

export const HgtpNetworksBlockExplorerApi = {
  [HgtpNetwork.MAINNET]: addRetryBehavior(
    axios.create({
      baseURL: HgtpNetworksUrls[HgtpNetwork.MAINNET].BlockExplorerUrl,
      timeout: 10 * 1000,
    })
  ),
  [HgtpNetwork.INTEGRATIONNET]: addRetryBehavior(
    axios.create({
      baseURL: HgtpNetworksUrls[HgtpNetwork.INTEGRATIONNET].BlockExplorerUrl,
      timeout: 10 * 1000,
    })
  ),
  [HgtpNetwork.TESTNET]: addRetryBehavior(
    axios.create({
      baseURL: HgtpNetworksUrls[HgtpNetwork.TESTNET].BlockExplorerUrl,
      timeout: 10 * 1000,
    })
  ),
}
