import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js'
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import { useTranslation } from 'react-i18next'

import { AWSCognitoConfig, FetchStatus } from '@lattice/common/consts'
import { Awaitable } from '@lattice/utils'
import {
  useFetchableOperations,
  useFetchableResource,
} from '@lattice/common/hooks'
import { EnvironmentContext } from '@lattice/runtime'
import { apiRequest } from '@lattice/common/lib'

import { useToastProvider } from '../ToastProvider'

import {
  IUserDbData,
  IUserMfaPreference,
  IUserMfaRequiredFunction,
  IUserProviderContext,
} from './types'
import { isUserMfaRequiredFunction } from './utils'

const UserProviderContext = React.createContext<IUserProviderContext | null>(
  null
)

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation()
  const { addToast } = useToastProvider()

  const operations = useFetchableOperations([
    'signIn',
    'signOut',
    'signUp',
    'passwordReset',
    'passwordResetConfirm',
    'confirmSignUp',
    'kycVerification',
  ])

  const userPool = useMemo(() => new CognitoUserPool(AWSCognitoConfig), [])

  const [user, setUser] = useState<CognitoUser | null>(
    userPool.getCurrentUser()
  )
  const userSession = useFetchableResource<CognitoUserSession | null>(null)
  const userAttributes = useFetchableResource<CognitoUserAttribute[] | null>(
    null
  )
  const userDbData = useFetchableResource<IUserDbData | null>(null)
  const userMfaPreference = useFetchableResource<IUserMfaPreference | null>(
    null
  )

  const fetchUserSession = userSession.wrappedFetch(async () => {
    if (!user) {
      throw new Error('User is not logged in')
    }

    const response = new Awaitable<CognitoUserSession>()

    user.getSession((err, session) => {
      if (err) {
        response.reject(err)
        return
      }
      response.resolve(session)
    })

    return response
  })

  const providerContext: IUserProviderContext = {
    operations,
    user,
    userSession,
    userAttributes,
    userDbData,
    userMfaPreference,
    doUserSignIn: operations.signIn.wrappedFetch(async (username, password) => {
      const authData = new AuthenticationDetails({
        Username: username,
        Password: password,
      })

      const user = new CognitoUser({
        Username: username,
        Pool: userPool,
      })

      let contextAwaitable: Awaitable<
        CognitoUserSession | IUserMfaRequiredFunction
      > = new Awaitable()

      user.authenticateUser(authData, {
        onSuccess: function (session) {
          contextAwaitable.resolve(session)
        },
        onFailure: function (err) {
          contextAwaitable.reject(err)
        },
        totpRequired: function (challengeName) {
          const mfaRequiredFn: IUserMfaRequiredFunction = Object.assign(
            operations.signIn.wrappedFetch(async (code: string) => {
              contextAwaitable = new Awaitable()
              user.sendMFACode(code, this, 'SOFTWARE_TOKEN_MFA')

              const userSessionOrMfaFn = await contextAwaitable

              if (isUserMfaRequiredFunction(userSessionOrMfaFn)) {
                throw new Error('Unknown cognito return error')
              }

              setUser(user)
              userSession.fetch(
                Promise.resolve({
                  value: userSessionOrMfaFn,
                  status: FetchStatus.DONE,
                })
              )
              return user
            }),
            {
              challengeName,
              isMfaRequired: true as const,
            }
          )

          contextAwaitable.resolve(mfaRequiredFn)
        },
      })

      const userSessionOrMfaFn = await contextAwaitable

      if (isUserMfaRequiredFunction(userSessionOrMfaFn)) {
        return userSessionOrMfaFn
      }

      setUser(user)
      userSession.fetch(
        Promise.resolve({ value: userSessionOrMfaFn, status: FetchStatus.DONE })
      )
      return user
    }),
    doUserSignUp: operations.signUp.wrappedFetch(async (username, password) => {
      const attributes = [
        new CognitoUserAttribute({ Name: 'email', Value: username }),
      ]

      const response = new Awaitable<CognitoUser>()

      userPool.signUp(username, password, attributes, [], (err, result) => {
        if (err) {
          addToast(String(err), 'error', 30000)
          response.reject(err)
          return
        }
        result && response.resolve(result.user)
      })

      const user = await response

      return user
    }),
    doUserSignOut: operations.signOut.wrappedFetch(async () => {
      if (user) {
        setUser(null)
        userSession.fetch(
          Promise.resolve({ value: null, status: FetchStatus.IDLE })
        )
        userAttributes.fetch(
          Promise.resolve({ value: null, status: FetchStatus.IDLE })
        )
        userDbData.fetch(
          Promise.resolve({ value: null, status: FetchStatus.IDLE })
        )
        await new Promise<void>((resolve) => {
          user.signOut(() => resolve())
        })
        return true
      }
      return false
    }),
    doUserPasswordReset: operations.passwordReset.wrappedFetch(
      async (username) => {
        const user = new CognitoUser({
          Username: username,
          Pool: userPool,
        })

        const response = new Awaitable<void>()

        user.forgotPassword({
          onSuccess: () => {
            response.resolve()
          },
          onFailure: (err) => {
            response.reject(err)
            addToast(String(err), 'error', 30000)
          },
        })

        await response
      }
    ),
    doUserPasswordResetConfirm: operations.passwordResetConfirm.wrappedFetch(
      async (username, verificationCode, password) => {
        const user = new CognitoUser({
          Username: username,
          Pool: userPool,
        })

        const response = new Awaitable<void>()

        user.confirmPassword(verificationCode, password, {
          onSuccess: () => {
            response.resolve()
          },
          onFailure: (err) => {
            response.reject(err)
            addToast(String(err), 'error', 30000)
          },
        })

        await response
      }
    ),
    doUserKycVerification: operations.kycVerification.wrappedFetch(
      async (firstName, lastName) => {
        if (!user) {
          throw new Error('Cognito User not present')
        }

        const VeriffApiBaseUrl = EnvironmentContext.ExtApiUrlVeriff
        const VeriffApiPubKey = EnvironmentContext.ExtApiKeyVeriff

        if (
          typeof VeriffApiBaseUrl !== 'string' ||
          VeriffApiBaseUrl.trim() === ''
        ) {
          throw new Error('Veriff API Base Url not present')
        }

        if (
          typeof VeriffApiPubKey !== 'string' ||
          VeriffApiPubKey.trim() === ''
        ) {
          throw new Error('Veriff API Pub Key not present')
        }

        const { data: sessionResponse } = await axios.post<{
          status: string
          verification: {
            id: string
            url: string
            vendorData: string
            host: string
            status: string
            sessionToken: string
          }
        }>(
          `${VeriffApiBaseUrl}/v1/sessions`,
          {
            verification: {
              callback:
                EnvironmentContext.nodeEnv === 'development'
                  ? 'https://lattice.is'
                  : location.origin,
              person: { firstName, lastName },
              vendorData: user.getUsername(),
              timestamp: dayjs().toISOString(),
            },
          },
          { headers: { 'X-AUTH-CLIENT': VeriffApiPubKey } }
        )

        const veriffAwaitable = new Awaitable<void>()

        createVeriffFrame({
          url: sessionResponse.verification.url,
          lang: i18n.resolvedLanguage,
          onEvent: (msg) => {
            if (msg === MESSAGES.CANCELED) {
              veriffAwaitable.reject(
                new Error('User canceled the verification process')
              )
              return
            }

            if (msg === MESSAGES.FINISHED) {
              veriffAwaitable.resolve()
              return
            }
          },
        })

        return veriffAwaitable
      }
    ),
    doFetchUserAttributes: userAttributes.wrappedFetch(async () => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      const response = new Awaitable<CognitoUserAttribute[]>()

      user.getUserAttributes((err, result) => {
        if (err) {
          response.reject(err)
          return
        }
        result && response.resolve(result)
      })

      return response
    }),
    doFetchUserDbData: userDbData.wrappedFetch(async () => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      const { data: userDbData } = await apiRequest({
        method: 'GET',
        endpoint: `/users/${user.getUsername()}`,
        isAuthenticated: true,
      })

      return userDbData as IUserDbData
    }),
    /**
     * @see {@link https://github.com/aws-amplify/amplify-js/blob/4756ea038f7ccfe36c30fe9282ab5d04e325f269/packages/auth/src/Auth.ts#L888}
     */
    doFetchUserMfaPreference: userMfaPreference.wrappedFetch(async () => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      const response = new Awaitable<IUserMfaPreference>()

      user.getUserData(
        (err, userData) => {
          if (err) {
            response.reject(err)
            return
          }

          if (!userData) {
            response.reject(new Error('No user data returned'))
            return
          }

          if (userData.PreferredMfaSetting) {
            response.resolve(userData.PreferredMfaSetting as IUserMfaPreference)
            return
          }

          if (
            !userData.UserMFASettingList ||
            userData.UserMFASettingList.length === 0
          ) {
            response.resolve('NOT_SET')
            return
          }

          response.reject(new Error('Invalid Mfa Preference'))
        },
        { bypassCache: true }
      )

      return response
    }),
    doGenerateMfaTotpSecret: () => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      const response = new Awaitable<string>()

      user.associateSoftwareToken({
        associateSecretCode: (secret) => response.resolve(secret),
        onFailure: (err) => response.reject(err),
      })

      return response
    },
    doVerifyMfaTotpToken: (mfaCode: string) => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      const response = new Awaitable<void>()

      user.verifySoftwareToken(mfaCode, '', {
        onSuccess: () => response.resolve(),
        onFailure: (err) => response.reject(err),
      })

      return response
    },
    doSetMfaPreference: async (preference) => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      const response = new Awaitable<void>()

      user.setUserMfaPreference(
        null,
        preference === 'NOT_SET'
          ? { Enabled: false, PreferredMfa: false }
          : { Enabled: true, PreferredMfa: true },
        (err) => {
          if (err) {
            response.reject(err)
          }
          response.resolve()
        }
      )

      await response

      return preference
    },
    confirmSignUp: operations.confirmSignUp.wrappedFetch(
      async (code: string | null) => {
        if (!user) {
          throw new Error('User is not logged in')
        }
        if (!code) {
          throw new Error('Invalid code')
        }
        await fetchUserSession()
        await new Promise<void>((resolve, reject) => {
          user.verifyAttribute('email', code, {
            onSuccess: async () => {
              providerContext.doFetchUserAttributes()
              resolve()
            },
            onFailure: (err) => {
              console.log('error', err)
              reject(err)
              addToast(String(err), 'error', 30000)
            },
          })
          user.confirmRegistration(code, false, async (err) => {
            if (err) {
              reject(err)
            }
            resolve()
          })
        })
      }
    ),
  }

  useEffect(() => {
    ;(async () => {
      if (user && userSession.resource) {
        try {
          await Promise.all([
            providerContext.doFetchUserAttributes(),
            providerContext.doFetchUserDbData(),
            providerContext.doFetchUserMfaPreference(),
          ])
        } catch (e) {
          userAttributes.fetch(
            Promise.resolve({ value: null, status: FetchStatus.ERROR })
          )
          userDbData.fetch(
            Promise.resolve({ value: null, status: FetchStatus.ERROR })
          )
          userMfaPreference.fetch(
            Promise.resolve({ value: null, status: FetchStatus.ERROR })
          )
        }
      } else {
        userAttributes.fetch(
          Promise.resolve({ value: null, status: FetchStatus.IDLE })
        )
        userDbData.fetch(
          Promise.resolve({ value: null, status: FetchStatus.IDLE })
        )
        userMfaPreference.fetch(
          Promise.resolve({ value: null, status: FetchStatus.IDLE })
        )
      }
    })()
  }, [user, userSession.resource])

  useEffect(() => {
    if (user) {
      fetchUserSession()
    }
  }, [userPool])

  return (
    <UserProviderContext.Provider value={providerContext}>
      {children}
    </UserProviderContext.Provider>
  )
}

export { UserProvider, UserProviderContext }
