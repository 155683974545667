import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  HorizontalBar,
  InlineStat,
  InlineStats,
  MainProjectCard,
  Tabs,
  Typography,
} from '@lattice/common/components'
import {
  INodeManagerGeneralNode,
  useBountiesProvider,
  useDtmNodesProvider,
  useNodeManagerProvider,
} from '@lattice/common/providers'
import { DtmNodeCardGrid } from '@lattice/views/RewardsView/views/DtmNodesDetailsView/components'
import {
  ProgramStatus,
  StatusColors,
} from '@lattice/common/consts/programStatus'
import { FetchStatus } from '@lattice/common/consts'
import { getNodeProperty, NodePropertyName } from '@lattice/common/lib'

import styles from './view.module.scss'

const GeneralDetailsView = () => {
  const params = useParams()
  const { bountyWithNodes, requestBountyWithNodes, bounty } =
    useBountiesProvider()
  const { requestUserNodes } = useNodeManagerProvider()
  const { dtmNftsInfo, requestDtmUser, requestUserDtmNftData } =
    useDtmNodesProvider()

  const [userRedeemedNfts, setUserRedeemedNfts] = useState<string[]>([])
  const [userNodes, setUserNodes] = useState<INodeManagerGeneralNode[]>([])
  const [devicesQuantity, setDevicesQuantity] = useState<number>(0)

  useEffect(() => {
    params.slug && requestBountyWithNodes(params.slug)
    requestDtmUser()
    requestUserDtmNftData()
    requestUserNodes()
  }, [])

  useEffect(() => {
    if (bountyWithNodes.resource) {
      setUserNodes([...bountyWithNodes.resource.userNodes])
      let currentDevicesQuantity = 0
      for (const node of bountyWithNodes.resource.userNodes) {
        currentDevicesQuantity += getNodeProperty(
          node,
          NodePropertyName.DTM_NODE__DTM_DEVICES,
          []
        ).length
      }
      setDevicesQuantity(currentDevicesQuantity)
    }
  }, [bountyWithNodes.resource])

  useEffect(() => {
    if (dtmNftsInfo.status === FetchStatus.DONE && dtmNftsInfo.resource) {
      const redeemedNfts: string[] = []
      dtmNftsInfo.resource.nfts.map((nft) => {
        const redeemedAttribute = nft.attributes.find(
          (attribute) => attribute.traitType === 'Redeemed'
        )
        if (redeemedAttribute?.jsonValue === 'Yes') {
          redeemedNfts.push(nft.serial.toString())
        }
      })
      setUserRedeemedNfts(redeemedNfts)
    }
  }, [dtmNftsInfo])

  if (!bounty.resource) {
    throw new Error(
      '<GeneralDetailsView/> must only be rendered when a bounty is fetched'
    )
  }

  return (
    <MainProjectCard
      title={bounty.resource.name}
      bannerUrl={bounty.resource.bannerUrl ?? ''}
      badge={{
        text: ProgramStatus[bounty.resource.status],
        color: StatusColors[bounty.resource.status],
      }}
    >
      <Tabs>
        <Tabs.Tab
          label="Overview"
          content={
            <Typography.MarkdownContent>
              {bounty.resource.longSummaryText}
            </Typography.MarkdownContent>
          }
        />
        <Tabs.Tab
          label="Your Nodes"
          isDefault={userNodes.length > 0}
          content={
            <div className={styles.yourDtms}>
              <div className={styles.inlineStats}>
                <InlineStats>
                  <InlineStat label="Active nodes" value={userNodes.length} />
                  <InlineStat label="Linked devices" value={devicesQuantity} />
                  <div className={styles.bountyAmountContainer}>
                    <span> -- </span>
                    <div>
                      <span>DAG</span>
                    </div>
                  </div>
                </InlineStats>
              </div>
              <HorizontalBar />
              {userNodes.length === 0 && (
                <span>Connect a node to opt into this commission</span>
              )}
              {userNodes.length > 0 && (
                <>
                  <DtmNodeCardGrid
                    nodes={userNodes}
                    redeemedNfts={userRedeemedNfts}
                  />
                </>
              )}
            </div>
          }
        />
      </Tabs>
    </MainProjectCard>
  )
}

export { GeneralDetailsView }
