import React, { useState, useEffect } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import Decimal from 'decimal.js'
import dayjs from 'dayjs'

import {
  BaseCard,
  Table,
  TitleBar,
  Typography,
} from '@lattice/common/components'
import {
  formatNumber,
  formatNumberAndCurrency,
  NumberFormat,
  shortenAddress,
} from '@lattice/utils'
import {
  DeploymentEvmChain,
  EvmNetwork,
  getEthereumBELink,
  getNetworkTypedContract,
  RegisteredToken,
  RegisteredTokens,
} from '@lattice/common/lib'

import styles from './view.module.scss'
import { GovernanceContent } from './content'

type ITransactionRecord = {
  date: number
  hash: string
  type: 'lock' | 'unlock'
  amount: number
}

const StatsView = () => {
  const { t } = useTranslation()
  const [totalLtxLocked, setTotalLtxLocked] = useState<number | null>(null)
  const [totalVeltxDistributed, setTotalVeltxDistributed] = useState<
    number | null
  >(null)
  const [transactionRecords, setTransactionRecords] = useState<
    ITransactionRecord[]
  >([])

  const fetchStats = async () => {
    const EtherscanApiEndpoint = process.env.REACT_APP_ETHERSCAN_API_ENDPOINT
    const EtherscanApiKey = process.env.REACT_APP_ETHERSCAN_API_KEY

    if (!EtherscanApiEndpoint || !EtherscanApiKey) {
      throw new Error('Etherscan integration details not available')
    }

    const veltxToken = getNetworkTypedContract(
      'LatticeGovernanceToken',
      RegisteredTokens[RegisteredToken.veLTX].instances[DeploymentEvmChain]
        .address,
      EvmNetwork.ETHEREUM
    )

    const [veltxTotal, ltxTotal] = await Promise.all([
      veltxToken.totalSupply(),
      veltxToken.totalLtxLockedSupply(),
    ])

    setTotalVeltxDistributed(
      new Decimal(veltxTotal.toString())
        .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.veLTX].decimals))
        .toNumber()
    )

    setTotalLtxLocked(
      new Decimal(ltxTotal.toString())
        .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals))
        .toNumber()
    )

    const transactionRecords: ITransactionRecord[] = []
    const lockedEventFilter = veltxToken.filters.Locked()
    const unlockedEventFilter = veltxToken.filters.Unlocked()

    const lockedEventLogs = await veltxToken.queryFilter(
      lockedEventFilter,
      -10000,
      'latest'
    )

    const unlockedEventLogs = await veltxToken.queryFilter(
      unlockedEventFilter,
      -10000,
      'latest'
    )

    for (const lockedEventLog of lockedEventLogs) {
      transactionRecords.push({
        date: lockedEventLog.args.timestamp.toNumber(),
        hash: lockedEventLog.transactionHash,
        type: 'lock',
        amount: new Decimal(lockedEventLog.args.amountLocked.toString())
          .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals))
          .toNumber(),
      })
    }

    for (const unlockedEventLog of unlockedEventLogs) {
      transactionRecords.push({
        date: unlockedEventLog.args.timestamp.toNumber(),
        hash: unlockedEventLog.transactionHash,
        type: 'unlock',
        amount: new Decimal(unlockedEventLog.args.amountUnlocked.toString())
          .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals))
          .toNumber(),
      })
    }

    transactionRecords.sort((a, b) => -a.date + b.date)

    setTransactionRecords(transactionRecords.splice(0, 20))
  }

  useEffect(() => {
    fetchStats()
    const iid = window.setInterval(fetchStats, 30000)
    return () => {
      window.clearInterval(iid)
    }
  }, [])

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.statsCardsContainer}>
          <BaseCard
            variants={['bordered', 'full-width']}
            className={{
              root: cls(styles.statCard, styles.statTextCard),
              body: cls(styles.body),
            }}
          >
            <span>
              {t('views.Governance.views.Stats.governace', 'Governance')}
            </span>
            <div className={styles.governanceContent}>
              <Typography.MarkdownContent>
                {GovernanceContent}
              </Typography.MarkdownContent>
            </div>
          </BaseCard>
          <BaseCard
            variants={['bordered', 'full-width']}
            className={{ root: styles.statCard, body: styles.body }}
          >
            <span>
              {t('views.Governance.views.Stats.totalLTX', 'Total LTX locked')}
            </span>
            <span className={styles.stat}>
              {totalLtxLocked !== null
                ? formatNumber(totalLtxLocked, NumberFormat.WHOLE)
                : '--'}
            </span>
          </BaseCard>
          <BaseCard
            variants={['bordered', 'full-width']}
            className={{ root: styles.statCard, body: styles.body }}
          >
            <span>
              {t(
                'views.Governance.views.Stats.totalVeLTX',
                'Total veLTX distributed'
              )}
            </span>
            <span className={styles.stat}>
              {totalVeltxDistributed !== null
                ? formatNumber(totalVeltxDistributed, NumberFormat.WHOLE)
                : '--'}
            </span>
          </BaseCard>
        </div>
        <div className={styles.tableContainer}>
          <TitleBar>
            {t(
              'views.Governance.views.Stats.recentActivity',
              'Recent activity'
            )}
          </TitleBar>
          <Table
            className={{ root: styles.table }}
            data={transactionRecords}
            primaryKey={'hash'}
            emptyState={{
              date: '---',
              hash: '---',
              type: '---',
              amount: '---',
            }}
            titles={{
              date: {
                content: t(
                  'views.Governance.views.Stats.table.columns.date',
                  'Date'
                ),
                sortable: false,
              },
              hash: {
                content: t(
                  'views.Governance.views.Stats.table.columns.hash',
                  'Txn Hash'
                ),
                sortable: false,
              },
              type: {
                content: t(
                  'views.Governance.views.Stats.table.columns.type',
                  'Txn Type'
                ),
                sortable: false,
              },
              amount: {
                content: t(
                  'views.Governance.views.Stats.table.columns.amount',
                  'Amount'
                ),
                sortable: false,
              },
            }}
            formatData={{
              date: (value) => dayjs.unix(value).format('MMM/DD/YY HH:mm Z'),
              hash: (value) => (
                <a
                  href={getEthereumBELink(
                    DeploymentEvmChain,
                    value,
                    'transaction'
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {shortenAddress(value, 5, 5, '•••')}
                </a>
              ),
              type: (value) =>
                value === 'lock'
                  ? t('views.Governance.views.Stats.table.content.lock', 'Lock')
                  : t(
                      'views.Governance.views.Stats.table.content.claim',
                      'Claim'
                    ),
              amount: (value) =>
                formatNumberAndCurrency(value, 'LTX', NumberFormat.DECIMALS),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export { StatsView }
