import React from 'react'
import { useTranslation } from 'react-i18next'
import Decimal from 'decimal.js'
import dayjs from 'dayjs'

import {
  Badge,
  BadgeColors,
  BaseCard,
  BlockStat,
  InfoRowsContainer,
  InlineStatsCard,
  CardSectionContainer,
  DetailsCardWrapper,
  CardLabelContainer,
  FlexCenteredContainer,
} from '@lattice/common/components'
import { IUserWalletSoftNodeAndPrograms } from '@lattice/common/providers'
import { NumberFormat, formatNumber } from '@lattice/utils'
import { NetworkCurrenciesUtils } from '@lattice/common/lib'
import { InfoRow } from '@lattice/views/SwapBuyView/views/SwapExolixView/components/common/InfoRow'
import { TitledHeader } from '@lattice/common/components/TitledHeaderCard'
import { ReactComponent as CheckFilledIcon } from '@lattice/assets/icons/carbon/checkmark-filled.svg'
import { ReactComponent as ErrorFilledIcon } from '@lattice/assets/icons/custom/error-filled.svg'

import { formatFracPercentage } from '../../utils'

import styles from './component.module.scss'

const NodeDetailsCard = ({
  title,
  node,
  variants,
}: {
  title: string
  node: IUserWalletSoftNodeAndPrograms
  variants?: (
    | 'narrow'
    | 'wide'
    | 'medium'
    | 'section-bar'
    | 'full-width'
    | 'bordered'
  )[]
}) => {
  const { t } = useTranslation()

  const initialFracPercentage = (
    (node.programs[0].frac[0] / node.programs[0].nodePrice) *
    100
  ).toFixed(2)

  const currentFracPercentage = (
    (node.programs[0].frac[1] / node.programs[0].nodePrice) *
    100
  ).toFixed(2)

  const nodeHasFullShards =
    node.programs[0].shards[0] + node.programs[0].shards[1] > 0

  const nodeHasFracShards = node.programs[0].frac[0] > 0

  return (
    <BaseCard variants={variants} className={{ root: styles.baseCardRoot }}>
      <DetailsCardWrapper>
        <CardSectionContainer>
          <TitledHeader>{title}</TitledHeader>
          <CardLabelContainer>
            <InlineStatsCard>
              <BlockStat
                key={1}
                label={'Accrued rewards'}
                value={
                  <FlexCenteredContainer>
                    <span>
                      {dayjs(node.programs[0].stakingStartsAt).isBefore(
                        dayjs()
                      ) &&
                      dayjs(node.programs[0].stakingWindowEndsAt).isAfter(
                        dayjs()
                      )
                        ? '--'
                        : formatNumber(
                            new Decimal(
                              node.programs[0].userTotalAccruedRewards
                            ).div(
                              Decimal.pow(
                                10,
                                NetworkCurrenciesUtils['constellation:dag']
                                  .decimals
                              )
                            ),
                            NumberFormat.DECIMALS
                          ) || '--'}
                    </span>
                    <Badge
                      text={'DAG'}
                      color={BadgeColors.green}
                      className={{ container: styles.greenBadgeContainer }}
                    />
                  </FlexCenteredContainer>
                }
              />
            </InlineStatsCard>
          </CardLabelContainer>
          <InfoRowsContainer
            title={t(
              'views.NodeManager.views.NodeDetailsCard.infoRows.titles.programInfo',
              'Program info'
            )}
          >
            <InfoRow
              leftSide={t(
                'views.NodeManager.views.NodeDetailsCard.infoRows.labels.activeProgram',
                'Active program'
              )}
              rightSide={`${node.programs[0].name} →`}
              linkTo={'/rewards/soft-nodes/' + node.programs[0].slug}
              className={{
                leftSide: styles.leftSideFont,
                rightSide: styles.rightSideFont,
              }}
            />
            <InfoRow
              leftSide={
                dayjs(node.programs[0].stakingEndsAt).diff(dayjs()) > 0
                  ? t(
                      'views.NodeManager.views.NodeDetailsCard.infoRows.labels.stakingEnds',
                      'Staking ends in'
                    )
                  : t(
                      'views.NodeManager.views.NodeDetailsCard.infoRows.labels.stakingEnded',
                      'Staking'
                    )
              }
              rightSide={
                dayjs(node.programs[0].stakingEndsAt).diff(dayjs()) > 0
                  ? dayjs
                      .duration(
                        dayjs(node.programs[0].stakingEndsAt).diff(dayjs())
                      )
                      .humanize()
                  : 'Ended'
              }
              className={{
                leftSide: styles.leftSideFont,
                rightSide: styles.rightSideFont,
              }}
            />
          </InfoRowsContainer>
          <InfoRowsContainer
            title={t(
              'views.NodeManager.views.NodeDetailsCard.infoRows.titles.shards',
              'Shards'
            )}
            // icon={<Question height={'14px'} width={'14px'} color={'#5A5D6B'} />}
          >
            <InfoRow
              leftSide={t(
                'views.NodeManager.views.NodeDetailsCard.infoRows.labels.initialStake',
                'Initial stake'
              )}
              rightSide={
                nodeHasFullShards
                  ? formatNumber(
                      new Decimal(
                        (node.programs[0].shards[0] +
                          node.programs[0].shards[1]) *
                          node.programs[0].nodePrice
                      ).div(
                        Decimal.pow(
                          10,
                          NetworkCurrenciesUtils['constellation:dag'].decimals
                        )
                      ),
                      NumberFormat.MILLIFY
                    ) + ' DAG'
                  : '--'
              }
              className={{
                leftSide: styles.leftSideFont,
                rightSide: styles.rightSideFont,
              }}
            />
            <InfoRow
              leftSide={t(
                'views.NodeManager.views.NodeDetailsCard.infoRows.labels.activeShards',
                'Active shards'
              )}
              rightSide={
                nodeHasFullShards
                  ? node.programs[0].shards[0].toString() +
                    ' of ' +
                    (node.programs[0].shards[1] + node.programs[0].shards[0])
                  : '--'
              }
              rightSideIcon={
                nodeHasFullShards ? (
                  node.programs[0].shards[0] ===
                  node.programs[0].shards[1] + node.programs[0].shards[0] ? (
                    <CheckFilledIcon
                      color="#19BD39"
                      height={'14px'}
                      width={'14px'}
                    />
                  ) : (
                    <ErrorFilledIcon
                      color={'#FFCC00'}
                      height={'14px'}
                      width={'14px'}
                    />
                  )
                ) : undefined
              }
              className={{
                leftSide: styles.leftSideFont,
                rightSide: styles.rightSideFont,
              }}
            />
            <InfoRow
              leftSide={t(
                'views.NodeManager.views.NodeDetailsCard.infoRows.labels.accruedRewards',
                'Accrued rewards'
              )}
              rightSide={
                dayjs(node.programs[0].stakingStartsAt).isBefore(dayjs()) &&
                dayjs(node.programs[0].stakingWindowEndsAt).isAfter(dayjs())
                  ? '--'
                  : nodeHasFullShards
                    ? formatNumber(
                        new Decimal(
                          node.programs[0].userFullShardsAccruedRewards
                        ).div(
                          Decimal.pow(
                            10,
                            NetworkCurrenciesUtils['constellation:dag'].decimals
                          )
                        ),
                        NumberFormat.DECIMALS
                      ) + ' DAG'
                    : '--'
              }
              className={{
                leftSide: styles.leftSideFont,
                rightSide: styles.rightSideFont,
              }}
            />
          </InfoRowsContainer>
          <InfoRowsContainer
            title={t(
              'views.NodeManager.views.NodeDetailsCard.infoRows.titles.fracShard',
              'Fractional Shard'
            )}
            // icon={<Question height={'14px'} width={'14px'} color={'#5A5D6B'} />}
          >
            <InfoRow
              leftSide={t(
                'views.NodeManager.views.NodeDetailsCard.infoRows.labels.initialStake',
                'Initial stake'
              )}
              rightSide={
                nodeHasFracShards
                  ? formatNumber(
                      new Decimal(node.programs[0].frac[0]).div(
                        Decimal.pow(
                          10,
                          NetworkCurrenciesUtils['constellation:dag'].decimals
                        )
                      ),
                      NumberFormat.MILLIFY
                    ) +
                    ' DAG ' +
                    '(' +
                    (
                      (node.programs[0].frac[0] / node.programs[0].nodePrice) *
                      100
                    ).toFixed(2) +
                    '%)'
                  : '--'
              }
              className={{
                leftSide: styles.leftSideFont,
                rightSide: styles.rightSideFont,
              }}
            />
            <InfoRow
              leftSide={t(
                'views.NodeManager.views.NodeDetailsCard.infoRows.labels.activeShard',
                'Active shard'
              )}
              rightSide={
                nodeHasFracShards
                  ? formatFracPercentage(
                      initialFracPercentage,
                      currentFracPercentage
                    )
                  : '--'
              }
              rightSideIcon={
                !nodeHasFracShards ? undefined : node.programs[0].frac[0] ===
                  node.programs[0].frac[1] ? (
                  <CheckFilledIcon
                    color="#19BD39"
                    height={'14px'}
                    width={'14px'}
                  />
                ) : (
                  <ErrorFilledIcon
                    color={'#FFCC00'}
                    height={'14px'}
                    width={'14px'}
                  />
                )
              }
              className={{
                leftSide: styles.leftSideFont,
                rightSide: styles.rightSideFont,
              }}
            />
            <InfoRow
              leftSide={t(
                'views.NodeManager.views.NodeDetailsCard.infoRows.labels.accruedRewards',
                'Accrued rewards'
              )}
              rightSide={
                dayjs(node.programs[0].stakingStartsAt).isBefore(dayjs()) &&
                dayjs(node.programs[0].stakingWindowEndsAt).isAfter(dayjs())
                  ? '--'
                  : nodeHasFracShards
                    ? formatNumber(
                        new Decimal(
                          node.programs[0].userFracShardsAccruedRewards
                        ).div(
                          Decimal.pow(
                            10,
                            NetworkCurrenciesUtils['constellation:dag'].decimals
                          )
                        ),
                        NumberFormat.DECIMALS
                      ) + ' DAG'
                    : '--'
              }
              className={{
                leftSide: styles.leftSideFont,
                rightSide: styles.rightSideFont,
              }}
            />
          </InfoRowsContainer>
        </CardSectionContainer>
      </DetailsCardWrapper>
    </BaseCard>
  )
}

export { NodeDetailsCard }
