import dayjs from 'dayjs'
import dayjs_utc from 'dayjs/plugin/utc'
import dayjs_duration from 'dayjs/plugin/duration'
import dayjs_relativetime from 'dayjs/plugin/relativeTime'

import 'dayjs/locale/en'
import 'dayjs/locale/es'

export const initializeDayjsLib = () => {
  dayjs.extend(dayjs_utc)
  dayjs.extend(dayjs_duration)
  dayjs.extend(dayjs_relativetime)
}

export const calcIntervalDuration = (start: Date, end: Date) => {
  const duration = dayjs.duration(dayjs(start).diff(end))

  if (duration.months()) {
    return `${Math.abs(duration.months())} months ${Math.abs(
      duration.days()
    )} days`
  }
  if (duration.days()) {
    return `${Math.abs(duration.days())} days ${Math.abs(
      duration.hours()
    )} hours`
  }
  if (duration.hours()) {
    return `${Math.abs(duration.hours())} hours ${Math.abs(
      duration.minutes()
    )} minutes`
  }
  return `${Math.abs(duration?.minutes()) || '0'} minutes ${Math.abs(
    duration.seconds()
  )} seconds`
}
