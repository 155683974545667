import { buildIsValidEnumMember } from '@lattice/utils'
import { DeploymentStage, EnvironmentContext } from '@lattice/runtime'
import EthereumLogoImg from '@lattice/assets/images/pngs/ethereum_logo.png'
import EthereumGoerliLogoImg from '@lattice/assets/images/pngs/ethereum_goerli_logo.png'
import PolygonLogoImg from '@lattice/assets/images/pngs/polygon_logo.png'
import PolygonMumbaiLogoImg from '@lattice/assets/images/pngs/polygon_mumbai_logo.png'
import ConstellationLogo from '@lattice/assets/images/pngs/constellation_logo.png'
import ConstellationTestnetLogo from '@lattice/assets/images/pngs/constellation_testnet_logo.png'

import { IEvmChainData } from './types'

export enum EvmNetwork {
  POLYGON = 'polygon',
  ETHEREUM = 'ethereum',
  CONSTELLATION = 'constellation', // Stargazer RPC support
}

export const isEvmNetwork = buildIsValidEnumMember<EvmNetwork>(EvmNetwork)

export enum EvmChain {
  ETHEREUM_MAINNET = 'ethereum_mainnet',
  ETHEREUM_TESTNET = 'ethereum_testnet',
  POLYGON_MAINNET = 'polygon_mainnet',
  POLYGON_TESTNET = 'polygon_testnet',
  CONSTELLATION_MAINNET = 'constellation_mainnet', // Stargazer RPC support
  CONSTELLATION_TESTNET = 'constellation_testnet', // Stargazer RPC support
}

export const isEvmChain = buildIsValidEnumMember<EvmChain>(EvmChain)

export enum EvmContractProtocol {
  ERC20 = 'erc20',
  ERC721 = 'erc721',
}

export const isEvmContractProtocol =
  buildIsValidEnumMember<EvmContractProtocol>(EvmContractProtocol)

export const EvmChainData: Record<EvmChain, IEvmChainData> = {
  [EvmChain.ETHEREUM_MAINNET]: {
    network: EvmNetwork.ETHEREUM,
    chainName: 'Ethereum Mainnet',
    rpcAvailable: true,
    rpcEndpoint: `${EnvironmentContext.SecuredProxiedApiUrl}/external-requests/node-ethereum-mainnet`,
    rpcChainId: 1,
    chainLogoUrl: EthereumLogoImg,
  },
  [EvmChain.ETHEREUM_TESTNET]: {
    network: EvmNetwork.ETHEREUM,
    chainName: 'Ethereum Sepolia',
    rpcAvailable: true,
    rpcEndpoint: `${EnvironmentContext.SecuredProxiedApiUrl}/external-requests/node-ethereum-sepolia`,
    rpcChainId: 11155111,
    chainLogoUrl: EthereumGoerliLogoImg,
  },
  [EvmChain.POLYGON_MAINNET]: {
    network: EvmNetwork.POLYGON,
    chainName: 'Polygon Mainnet',
    rpcAvailable: true,
    rpcEndpoint: `${EnvironmentContext.SecuredProxiedApiUrl}/external-requests/node-polygon-mainnet`,
    rpcChainId: 137,
    chainLogoUrl: PolygonLogoImg,
  },
  [EvmChain.POLYGON_TESTNET]: {
    network: EvmNetwork.POLYGON,
    chainName: 'Polygon Mumbai',
    rpcAvailable: true,
    rpcEndpoint: `${EnvironmentContext.SecuredProxiedApiUrl}/external-requests/node-polygon-amoy`,
    rpcChainId: 80001,
    chainLogoUrl: PolygonMumbaiLogoImg,
  },
  [EvmChain.CONSTELLATION_MAINNET]: {
    network: EvmNetwork.CONSTELLATION,
    chainName: 'Constellation Mainnet',
    rpcAvailable: false,
    get rpcEndpoint(): string {
      throw new Error('RPC endpoint is not available for ' + this.network)
    },
    get rpcChainId(): number {
      throw new Error('RPC chain id is not available for ' + this.network)
    },
    chainLogoUrl: ConstellationLogo,
  },
  [EvmChain.CONSTELLATION_TESTNET]: {
    network: EvmNetwork.CONSTELLATION,
    chainName: 'Constellation Testnet',
    rpcAvailable: false,
    get rpcEndpoint(): string {
      throw new Error('RPC endpoint is not available for ' + this.network)
    },
    get rpcChainId(): number {
      throw new Error('RPC chain id is not available for ' + this.network)
    },
    chainLogoUrl: ConstellationTestnetLogo,
  },
}

export const DeploymentEvmChains_PerEvmNetwork_PerStage: Record<
  DeploymentStage,
  Record<EvmNetwork, EvmChain>
> = {
  [DeploymentStage.PRODUCTION]: {
    [EvmNetwork.CONSTELLATION]: EvmChain.CONSTELLATION_MAINNET,
    [EvmNetwork.ETHEREUM]: EvmChain.ETHEREUM_MAINNET,
    [EvmNetwork.POLYGON]: EvmChain.POLYGON_MAINNET,
  },
  [DeploymentStage.STAGING]: {
    [EvmNetwork.CONSTELLATION]: EvmChain.CONSTELLATION_MAINNET,
    [EvmNetwork.ETHEREUM]: EvmChain.ETHEREUM_TESTNET,
    [EvmNetwork.POLYGON]: EvmChain.POLYGON_TESTNET,
  },
  [DeploymentStage.ALPHA]: {
    [EvmNetwork.CONSTELLATION]: EvmChain.CONSTELLATION_MAINNET,
    [EvmNetwork.ETHEREUM]: EvmChain.ETHEREUM_TESTNET,
    [EvmNetwork.POLYGON]: EvmChain.POLYGON_TESTNET,
  },
  [DeploymentStage.DELTA]: {
    [EvmNetwork.CONSTELLATION]: EvmChain.CONSTELLATION_MAINNET,
    [EvmNetwork.ETHEREUM]: EvmChain.ETHEREUM_TESTNET,
    [EvmNetwork.POLYGON]: EvmChain.POLYGON_TESTNET,
  },
  [DeploymentStage.THETA]: {
    [EvmNetwork.CONSTELLATION]: EvmChain.CONSTELLATION_MAINNET,
    [EvmNetwork.ETHEREUM]: EvmChain.ETHEREUM_TESTNET,
    [EvmNetwork.POLYGON]: EvmChain.POLYGON_TESTNET,
  },
  [DeploymentStage.TEST]: {
    [EvmNetwork.CONSTELLATION]: EvmChain.CONSTELLATION_MAINNET,
    [EvmNetwork.ETHEREUM]: EvmChain.ETHEREUM_TESTNET,
    [EvmNetwork.POLYGON]: EvmChain.POLYGON_TESTNET,
  },
}

export const DeploymentEvmChains_PerNetwork =
  DeploymentEvmChains_PerEvmNetwork_PerStage[EnvironmentContext.stage]

export const DeploymentEvmChain =
  DeploymentEvmChains_PerNetwork[EvmNetwork.ETHEREUM]
