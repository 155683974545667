import Decimal from 'decimal.js'
import { useEffect, useMemo } from 'react'

import { FetchStatus } from '../consts'
import {
  CurrencyNetwork,
  getERC20NetworkCurrencyUtil,
  getNetworkTypedContract,
  isEvmNetwork,
  NetworkCurrencyUtil,
} from '../lib'

import { useFetchableResource } from './useFetchableResource'

const useERC20CurrencyBalance = (
  accountAddress: string | null,
  contractAddress: string,
  currencySymbol: string,
  network: CurrencyNetwork
) => {
  if (!isEvmNetwork(network)) {
    throw new Error(`Invalid RPC network => ${network}`)
  }

  const currencyContract = useMemo(
    () => getNetworkTypedContract('ERC20', contractAddress, network),
    [contractAddress]
  )

  const currencyUtil = useFetchableResource<NetworkCurrencyUtil | null>(null)
  const balanceRaw = useFetchableResource<Decimal | null>(null)
  const balance = useFetchableResource<Decimal | null>(null)

  const fetchCurrencyUtil = currencyUtil.wrappedFetch(async () => {
    return await getERC20NetworkCurrencyUtil(
      currencySymbol,
      contractAddress,
      network
    )
  })

  const fetchAccountBalance = async () => {
    if (accountAddress === null) {
      balanceRaw.fetch({ status: FetchStatus.IDLE, value: null })
      balance.fetch({ status: FetchStatus.IDLE, value: null })
      return
    }

    if (currencyUtil.resource === null) {
      return
    }

    const balanceData =
      await currencyUtil.resource.getAddressBalance(accountAddress)

    balanceRaw.fetch(balanceData.balanceRaw)
    balance.fetch(balanceData.balance)
  }

  useEffect(() => {
    fetchCurrencyUtil()
  }, [contractAddress, network])

  useEffect(() => {
    fetchAccountBalance()
  }, [accountAddress])

  return {
    balance,
    balanceRaw,
    currencyUtil,
    currencyContract,
    fetchBalance: fetchAccountBalance,
  }
}

export { useERC20CurrencyBalance }
