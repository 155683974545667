import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'
import { useTranslation } from 'react-i18next'

import {
  Badge,
  BadgeColors,
  BlockStat,
  Button,
  CardLabelContainer,
  CardSectionContainer,
  Content,
  DetailsCardWrapper,
  DetailsInfoCard,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  FlexCenteredContainer,
  InfoRowsContainer,
  MainProjectCard,
  NoContent,
  NoContentContainer,
  PendingContent,
  ProgramDetailProjectContainer,
  ProgramDetailsTimerCard,
  Tabs,
  Typography,
} from '@lattice/common/components'
import {
  ISoftNodesProjectStatus,
  IUserSoftNodeProgram,
  IUserWalletSoftNodeAndPrograms,
  useSoftNodesProvider,
  useUserProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'
import {
  ProgramStatus,
  StatusColors,
} from '@lattice/common/consts/programStatus'
import { TitledHeader } from '@lattice/common/components/TitledHeaderCard'
import { InfoRow } from '@lattice/views/SwapBuyView/views/SwapExolixView/components/common/InfoRow'
import {
  dateRange,
  formatNumber,
  formatNumberAndCurrency,
  NumberFormat,
  shortenAddress,
} from '@lattice/utils'
import { ReactComponent as StatusDotIcon } from '@lattice/assets/icons/custom/statusdot.svg'
import { useProgressToasts, useSignInRedirect } from '@lattice/common/hooks'
import { ReactComponent as Stargazerlogo } from '@lattice/assets/images/svgs/StargazerLogo.svg'
import { formatFracPercentage } from '@lattice/views/NodeManagerView/utils'
import { NetworkCurrenciesUtils, NetworkCurrency } from '@lattice/common/lib'

import { HowItWorks } from './content'
import {
  ManageNodes,
  ProgramCard,
  ProgramCardTitle,
  RoundedFocusBox,
} from './components'
import styles from './view.module.scss'

const SoftNodeProgramDetailView = () => {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const { user, userSession } = useUserProvider()
  const {
    project,
    requestProject,
    projectUser,
    requestProjectUser,
    stakeWallet,
    stakeWalletOperation,
    MINIMUM_FRACTIONAL_STAKE_DAG,
    userHistoryProjects,
    requestUserHistoryProjects,
  } = useSoftNodesProvider()
  const { activeWallet, requestConnectorActivation, accountsNetworkAssets } =
    useWalletProvider()
  const signInUrl = useSignInRedirect()

  const [activeTab, setActiveTab] = useState<string | number | null>(0)
  const [stakingPeriod, setStakingPeriod] = useState<string>()
  const [enrollingPeriod, setEnrollingPeriod] = useState<string>()
  const [timerLabel, setTimerLabel] = useState<string>()
  const [endDate, setEndDate] = useState<Date>()
  const [endDateFormated, setEndDateFormated] = useState<string>()
  const [userProgramWalletsStaked, setUserProgramWalletsStaked] = useState<
    IUserSoftNodeProgram[]
  >([])
  const [userProgramWalletConnected, setUserProgramWalletConnected] =
    useState<IUserWalletSoftNodeAndPrograms>()
  const stakeOperationProgressToasts = useProgressToasts()

  const availableBalanceRaw =
    accountsNetworkAssets['constellation:dag']?.balanceRaw ?? new Decimal(0)

  const availableNodesToStake = project.resource
    ? availableBalanceRaw.div(project.resource.nodePrice).floor().toNumber()
    : 0

  const doStakeWallet = stakeWalletOperation.wrappedFetch(
    stakeOperationProgressToasts.wrappedErrorsAsync(async () => {
      if (activeWallet.status !== 'connected' || !activeWallet.constellation) {
        throw new Error('Wallet is not active')
      }

      if (project.resource === null) {
        throw new Error('Project resource is not available')
      }

      if (availableBalanceRaw.lt(MINIMUM_FRACTIONAL_STAKE_DAG)) {
        throw new Error(
          `Wallet does not have at least ${new Decimal(
            MINIMUM_FRACTIONAL_STAKE_DAG
          ).div(1e8)} $DAG to stake`
        )
      }

      stakeOperationProgressToasts.progress(
        'Requesting wallet ownership signature',
        'info',
        null
      )

      const ownershipToken =
        await activeWallet.constellation.requestWalletOwnershipToken()

      stakeOperationProgressToasts.progress('Staking shards', 'info', null)

      await stakeWallet(
        project.resource.id,
        activeWallet.constellation.account,
        availableNodesToStake,
        ownershipToken
      )

      stakeOperationProgressToasts.progress(
        'Successfully staked',
        'success',
        15000
      )

      requestProjectUser(project.resource.id)
    })
  )

  const getFullFracShard = () => {
    if (userProgramWalletsStaked && userProgramWalletsStaked.length > 0) {
      const addedPercentages = userProgramWalletsStaked.reduce(
        (sums, userWalletStaked) => {
          const currentPercentage =
            (userWalletStaked.frac[0] / userWalletStaked.nodePrice) * 100
          const initialPercentage =
            (userWalletStaked.frac[1] / userWalletStaked.nodePrice) * 100
          return {
            currentSum: sums.currentSum + currentPercentage,
            initialSum: sums.initialSum + initialPercentage,
          }
        },
        { currentSum: 0, initialSum: 0 }
      )

      return addedPercentages.currentSum.toFixed(2) + '%'
    } else {
      return '--'
    }
  }

  const getWalletConnectedFracShard = () => {
    if (!userProgramWalletConnected || !project.resource) {
      return '--'
    }

    const currentProgramInfo = userProgramWalletConnected.programs.find(
      (program) => program.id === project.resource?.id
    )

    if (!currentProgramInfo) {
      return '--'
    }

    return formatFracPercentage(
      (
        (currentProgramInfo.frac[0] / currentProgramInfo.nodePrice) *
        100
      ).toFixed(2),
      (
        (currentProgramInfo.frac[1] / currentProgramInfo.nodePrice) *
        100
      ).toFixed(2)
    )
  }

  const getUnstakedFracShard = () => {
    if (!project.resource) {
      return '--'
    }

    const fracBalance = availableBalanceRaw.sub(
      availableNodesToStake * project.resource.nodePrice
    )

    return `${fracBalance.div(project.resource.nodePrice).mul(100).toFixed(2)}%`
  }

  const getConnectedWalletTotalStaked = () => {
    if (!userProgramWalletConnected || !project.resource) {
      return 0
    }

    const currentProgramInfo = userProgramWalletConnected.programs.find(
      (program) => program.id === project.resource?.id
    )

    if (!currentProgramInfo) {
      return 0
    }

    const fracBalance = currentProgramInfo.frac[1]
    const fullShards = currentProgramInfo.shards[0]
    const nodePrice = currentProgramInfo.nodePrice

    return fullShards * nodePrice + fracBalance
  }

  const getConnectedWalletShards = () => {
    if (!userProgramWalletConnected || !project.resource) {
      return 0
    }

    const currentProgramInfo = userProgramWalletConnected.programs.find(
      (program) => program.id === project.resource?.id
    )

    if (!currentProgramInfo) {
      return 0
    }

    return currentProgramInfo.shards[0]
  }

  useEffect(() => {
    if (params.slug) {
      requestProject(params.slug)
    }
  }, [])

  useEffect(() => {
    if (project.resource && user) {
      requestProjectUser(project.resource.id)
      requestUserHistoryProjects()
    }
  }, [project.resource, user])

  useEffect(() => {
    if (
      userHistoryProjects.status === FetchStatus.DONE &&
      userHistoryProjects.resource &&
      project.resource
    ) {
      const programs: IUserSoftNodeProgram[] = []
      userHistoryProjects.resource.filter((userWalletSoftNode) => {
        const program = userWalletSoftNode.programs.filter(
          (program) => program.id === project.resource?.id
        )

        if (
          activeWallet.status === 'connected' &&
          activeWallet.constellation &&
          activeWallet.constellation.account ===
            userWalletSoftNode.walletAddress
        ) {
          setUserProgramWalletConnected(userWalletSoftNode)
        }

        if (program.length > 0) {
          programs.push(program[0])
        }
      })
      setUserProgramWalletsStaked(programs)
    }
  }, [
    userHistoryProjects.resource,
    project.resource,
    activeWallet,
    userHistoryProjects.status,
  ])

  useEffect(() => {
    if (project.resource) {
      const stakingStartsAt = dayjs(project.resource.stakingStartsAt).toDate()
      const stakingWindowEndsAt = dayjs(
        project.resource.stakingWindowEndsAt
      ).toDate()
      const stakingEndsAt = dayjs(project.resource.stakingEndsAt).toDate()

      setStakingPeriod(dateRange(stakingStartsAt, stakingEndsAt))
      setEnrollingPeriod(dateRange(stakingStartsAt, stakingWindowEndsAt))

      if (
        [
          ISoftNodesProjectStatus.DISTRIBUTING,
          ISoftNodesProjectStatus.COMPLETE,
        ].includes(project.resource.status)
      ) {
        setTimerLabel('Staking ended on')
        setEndDate(undefined)
        setEndDateFormated(
          dayjs(project.resource.stakingEndsAt).format('MMM DD, YYYY')
        )
      }
      if (project.resource.status === ISoftNodesProjectStatus.IN_PROGRESS) {
        setTimerLabel('Staking ends in')
        setEndDate(dayjs(project.resource.stakingEndsAt).toDate())
        setEndDateFormated(undefined)
      }
      if (project.resource.status === ISoftNodesProjectStatus.STAKING_OPEN) {
        setTimerLabel('Enroll within')
        setEndDate(dayjs(project.resource.stakingWindowEndsAt).toDate())
        setEndDateFormated(undefined)
      }
      if (project.resource.status === ISoftNodesProjectStatus.COMING_SOON) {
        setTimerLabel('Enrollment starts on')
        setEndDate(undefined)
        setEndDateFormated(
          dayjs(project.resource.stakingStartsAt).format('MMM DD, YYYY')
        )
      }
    }
  }, [project])

  const userProgram =
    userProgramWalletConnected &&
    userProgramWalletConnected.programs.find(
      (program) => program.id === project.resource!.id
    )
  let totalAccruedRewards = 0
  if (userProgram) {
    totalAccruedRewards = userProgram.userTotalAccruedRewards
  }

  return (
    <Content>
      <Typography.DetailsTitle
        childNameTitle={
          project.resource && project.status === FetchStatus.DONE
            ? project.resource.name
            : ''
        }
        parentNameTitle={'Rewards Programs'}
      />
      {(project.status === FetchStatus.PENDING ||
        projectUser.status === FetchStatus.PENDING) && <PendingContent />}
      {project.status === FetchStatus.DONE &&
        project.resource &&
        projectUser.status !== FetchStatus.PENDING && (
          <ProgramDetailProjectContainer>
            <DetailsMainCardContainer>
              <MainProjectCard
                title={project.resource.name}
                bannerUrl={project.resource.bannerUrl ?? ''}
                badge={{
                  text: ProgramStatus[project.resource.status],
                  color: StatusColors[project.resource.status],
                }}
              >
                <Tabs activeTabId={activeTab} onTabClick={setActiveTab}>
                  <Tabs.Tab
                    label="Program info"
                    content={
                      project.resource ? (
                        <Typography.MarkdownContent>
                          {project.resource.summaryText}
                        </Typography.MarkdownContent>
                      ) : (
                        <NoContent />
                      )
                    }
                  />
                  <Tabs.Tab
                    label="How it works"
                    content={
                      <Typography.MarkdownContent>
                        {HowItWorks}
                      </Typography.MarkdownContent>
                    }
                  />
                  <Tabs.Tab
                    label="Manage nodes"
                    isDefault={
                      (projectUser.resource &&
                        projectUser.resource.nodes.length > 0) ??
                      undefined
                    }
                    content={
                      project.status === FetchStatus.DONE &&
                      projectUser.status === FetchStatus.DONE &&
                      projectUser.resource &&
                      project.resource ? (
                        <ManageNodes
                          wallets={projectUser.resource.nodes}
                          totalShards={projectUser.resource.totalShards ?? 0}
                          fracShards={getFullFracShard()}
                          rewards={
                            userProgramWalletsStaked &&
                            userProgramWalletsStaked.length > 0
                              ? userProgramWalletsStaked.reduce(
                                  (sum, program) =>
                                    sum + program.userTotalAccruedRewards,
                                  0
                                )
                              : 0
                          }
                          nodePrice={project.resource.nodePrice}
                          isInEnrollingPeriod={
                            dayjs(project.resource.stakingStartsAt).isBefore(
                              dayjs()
                            ) &&
                            dayjs(project.resource.stakingWindowEndsAt).isAfter(
                              dayjs()
                            )
                          }
                        />
                      ) : (
                        <NoContentContainer>
                          <div>
                            {!userSession.resource &&
                              t(
                                'views.RewardsView.views.DtmNodeDetails.views.RedeemNftView.NftSelection.nftsNeeded',
                                'You need to sign in to access this section'
                              )}
                          </div>
                          {!userSession.resource && (
                            <Button.Link
                              to={signInUrl}
                              variants={['secondary', 'outlined', 'right-icon']}
                            >
                              Sign in
                            </Button.Link>
                          )}
                        </NoContentContainer>
                      )
                    }
                  />
                </Tabs>
              </MainProjectCard>
            </DetailsMainCardContainer>
            <DetailsSideCardsContainer>
              <ProgramDetailsTimerCard
                dateInfo={{
                  endDate: endDate,
                  endLabel: timerLabel ?? '',
                  formatedEndDate: endDateFormated,
                }}
              >
                <TitledHeader>Program details</TitledHeader>
                <InfoRowsContainer>
                  <InfoRow
                    leftSide={'Enrollment window'}
                    rightSide={enrollingPeriod!}
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                  />
                  <InfoRow
                    leftSide={'Staking period'}
                    rightSide={stakingPeriod!}
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                  />
                  <InfoRow
                    leftSide={'Requirements'}
                    rightSide={'100 DAG or more'}
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                  />
                  <InfoRow
                    leftSide={'Total shards staked'}
                    // leftSideIcon={
                    //   <Question
                    //     height={'14px'}
                    //     width={'14px'}
                    //     color={'#5A5D6B'}
                    //   />
                    // }
                    rightSide={formatNumber(
                      project.resource.actualShardsCount,
                      NumberFormat.WHOLE
                    )}
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                  />
                  <InfoRow
                    leftSide={'Total frac. shards staked'}
                    rightSide={formatNumber(
                      new Decimal(
                        project.resource.actualAmountStakedByFracsRaw
                      ).div(project.resource.nodePrice),
                      NumberFormat.DECIMALS
                    )}
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                  />
                  <InfoRow
                    leftSide={'Total amount staked'}
                    rightSide={formatNumberAndCurrency(
                      new Decimal(
                        project.resource.actualAmountStakedFullRaw
                      ).div(
                        NetworkCurrenciesUtils[
                          NetworkCurrency.CONSTELLATION__DAG
                        ].decimalFactor
                      ),
                      'DAG',
                      NumberFormat.MILLIFY_EXPANDED
                    )}
                    className={{
                      leftSide: styles.leftSideFont,
                      rightSide: styles.rightSideFont,
                    }}
                  />
                </InfoRowsContainer>
                {!user && (
                  <div className={styles.participateContent}>
                    <CardLabelContainer>
                      Sign in to participate
                    </CardLabelContainer>
                    <Button.Link
                      variants={['full-width', 'primary']}
                      to={signInUrl}
                    >
                      Sign in
                    </Button.Link>
                    <div className={styles.logoText}>
                      <div className={styles.text}>
                        Don’t have a Lattice account?
                        <Link to="/user/signup">Register now.</Link>
                      </div>
                    </div>
                  </div>
                )}
              </ProgramDetailsTimerCard>
              {project.resource &&
                dayjs().isAfter(dayjs(project.resource.stakingStartsAt)) && (
                  <>
                    {user &&
                      (activeWallet.status === 'disconnected' ||
                        (activeWallet.status === 'connected' &&
                          !activeWallet.constellation)) && (
                        <DetailsInfoCard
                          label={'Join now to earn rewards'}
                          actionElement={
                            <Button
                              variants={['full-width', 'primary']}
                              onClick={() => requestConnectorActivation()}
                            >
                              Connect wallet
                            </Button>
                          }
                          variants={['full-width']}
                        >
                          <div className={styles.logoText}>
                            <Stargazerlogo />
                            <div className={styles.text}>
                              Don’t have Stargazer wallet?
                              <a href="." rel="noreferrer" target="_blank">
                                Download now.
                              </a>
                            </div>
                          </div>
                        </DetailsInfoCard>
                      )}
                    {activeWallet.status === 'connected' &&
                      activeWallet.constellation && (
                        <div>
                          <DetailsCardWrapper variants={['as-header']}>
                            <CardSectionContainer>
                              <BlockStat
                                key={1}
                                label={'Accrued Rewards'}
                                className={{ labelFont: styles.labelFont }}
                                value={
                                  <FlexCenteredContainer>
                                    <span>
                                      {userProgramWalletConnected
                                        ? formatNumber(
                                            new Decimal(
                                              totalAccruedRewards
                                            ).div(
                                              Decimal.pow(
                                                10,
                                                NetworkCurrenciesUtils[
                                                  'constellation:dag'
                                                ].decimals
                                              )
                                            ),
                                            NumberFormat.DECIMALS
                                          )
                                        : '--'}
                                    </span>
                                    <Badge
                                      text={'DAG'}
                                      color={BadgeColors.green}
                                      className={{
                                        container: styles.greenBadgeContainer,
                                      }}
                                    />
                                  </FlexCenteredContainer>
                                }
                              />
                            </CardSectionContainer>
                            <div></div>
                          </DetailsCardWrapper>
                          <ProgramCard variants={['with-header']}>
                            <ProgramCardTitle>
                              Connected Wallet
                            </ProgramCardTitle>
                            {dayjs().isBefore(
                              dayjs(project.resource.stakingWindowEndsAt)
                            ) && (
                              <>
                                <span className={styles.stakeInfo}>
                                  To stake another wallet, switch to that wallet
                                  and reload the page.
                                </span>

                                <RoundedFocusBox
                                  label={
                                    userProgramWalletConnected
                                      ? 'Staked wallet'
                                      : 'Unstaked wallet'
                                  }
                                >
                                  <FlexCenteredContainer>
                                    <StatusDotIcon
                                      width={8}
                                      height={8}
                                      style={{ color: '#03BC3D' }}
                                    />
                                    {shortenAddress(
                                      activeWallet.constellation.account,
                                      5,
                                      5,
                                      '•••'
                                    )}
                                  </FlexCenteredContainer>
                                </RoundedFocusBox>
                                <Button
                                  variants={['full-width', 'primary']}
                                  loading={
                                    stakeWalletOperation.status ===
                                    FetchStatus.PENDING
                                  }
                                  disabled={
                                    stakeWalletOperation.status ===
                                      FetchStatus.PENDING ||
                                    availableBalanceRaw.lt(
                                      MINIMUM_FRACTIONAL_STAKE_DAG
                                    )
                                  }
                                  onClick={() =>
                                    availableBalanceRaw.gte(
                                      MINIMUM_FRACTIONAL_STAKE_DAG
                                    ) && doStakeWallet()
                                  }
                                >
                                  Stake wallet
                                </Button>
                              </>
                            )}
                            <InfoRowsContainer>
                              <InfoRow
                                leftSide={t(
                                  'views.RewardsView.views.SoftNodePorgramDetail.infoRows.labels.nodeAlias',
                                  'Node alias'
                                )}
                                linkTo={
                                  userProgramWalletConnected
                                    ? '/nodes/soft-node/' +
                                      userProgramWalletConnected.alias
                                    : undefined
                                }
                                rightSide={
                                  userProgramWalletConnected
                                    ? userProgramWalletConnected.alias
                                    : '--'
                                }
                                className={{
                                  leftSide: styles.leftSideFont,
                                  rightSide: styles.rightSideFont,
                                }}
                              />
                              <InfoRow
                                leftSide={
                                  userProgramWalletConnected
                                    ? t(
                                        'views.RewardsView.views.SoftNodePorgramDetail.infoRows.labels.activeShards',
                                        'Active shards'
                                      )
                                    : t(
                                        'views.RewardsView.views.SoftNodePorgramDetail.infoRows.labels.totalShards',
                                        'Total shards'
                                      )
                                }
                                rightSide={
                                  userProgramWalletConnected
                                    ? getConnectedWalletShards().toString()
                                    : availableNodesToStake.toString()
                                }
                                className={{
                                  leftSide: styles.leftSideFont,
                                  rightSide: styles.rightSideFont,
                                }}
                              />
                              <InfoRow
                                leftSide={
                                  userProgramWalletConnected
                                    ? t(
                                        'views.RewardsView.views.SoftNodePorgramDetail.infoRows.labels.totalInWallet',
                                        'Active frac. shard'
                                      )
                                    : 'Total frac. shard'
                                }
                                rightSide={
                                  userProgramWalletConnected
                                    ? getWalletConnectedFracShard()
                                    : getUnstakedFracShard()
                                }
                                className={{
                                  leftSide: styles.leftSideFont,
                                  rightSide: styles.rightSideFont,
                                }}
                              />
                              <InfoRow
                                leftSide={
                                  userProgramWalletConnected
                                    ? t(
                                        'views.RewardsView.views.SoftNodePorgramDetail.infoRows.labels.totalStaked',
                                        'Total staked'
                                      )
                                    : 'Wallet Balance'
                                }
                                rightSide={
                                  userProgramWalletConnected
                                    ? formatNumber(
                                        new Decimal(
                                          getConnectedWalletTotalStaked()
                                        ).div(
                                          Decimal.pow(
                                            10,
                                            NetworkCurrenciesUtils[
                                              'constellation:dag'
                                            ].decimals
                                          )
                                        ),
                                        NumberFormat.MILLIFY
                                      ) + ' DAG'
                                    : formatNumber(
                                        new Decimal(availableBalanceRaw).div(
                                          Decimal.pow(
                                            10,
                                            NetworkCurrenciesUtils[
                                              'constellation:dag'
                                            ].decimals
                                          )
                                        ),
                                        NumberFormat.DECIMALS
                                      ) + ' DAG'
                                }
                                className={{
                                  leftSide: styles.leftSideFont,
                                  rightSide: styles.rightSideFont,
                                }}
                              />
                            </InfoRowsContainer>
                            {stakeWalletOperation.error && (
                              <span className={styles.error}>
                                {String(stakeWalletOperation.error.message)}
                              </span>
                            )}
                            <Button
                              variants={[
                                'full-width',
                                'secondary',
                                'right-icon',
                              ]}
                              loading={
                                stakeWalletOperation.status ===
                                FetchStatus.PENDING
                              }
                              onClick={() =>
                                navigate(
                                  userProgramWalletConnected
                                    ? '/nodes/soft-node/' +
                                        userProgramWalletConnected.alias
                                    : '/nodes'
                                )
                              }
                            >
                              {userProgramWalletConnected
                                ? 'Manage soft node'
                                : 'Manage soft nodes'}
                            </Button>
                          </ProgramCard>
                        </div>
                      )}
                  </>
                )}
            </DetailsSideCardsContainer>
          </ProgramDetailProjectContainer>
        )}
    </Content>
  )
}

export { SoftNodeProgramDetailView }
