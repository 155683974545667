import React from 'react'
import cls from 'classnames'
import { useNavigate } from 'react-router-dom'

import { GridContainer } from '@lattice/common/components'
import { IDtmNode, INodeManagerGeneralNode } from '@lattice/common/providers'
import { getNodeProperty, NodePropertyName } from '@lattice/common/lib'

import { DtmNodeCard } from '../DtmNodeCard/component'

import styles from './component.module.scss'

const DtmNodeCardGrid = ({
  nodes,
  redeemedNfts,
  selectNode,
  nodesToRedeem,
  disableLinks = false,
}: {
  nodes: IDtmNode[] | INodeManagerGeneralNode[]
  redeemedNfts: string[]
  selectNode?: (node: IDtmNode) => void
  nodesToRedeem?: IDtmNode[]
  disableLinks?: boolean
}) => {
  const navigate = useNavigate()

  return (
    <GridContainer relative>
      {nodes.map((node) => {
        const nftSerial = node.nftSerial || node.externalNode.nftSerial
        const redeemedStatus = redeemedNfts.includes(nftSerial.toString())
          ? 'Redeemed'
          : undefined

        const nftsToRedeem =
          nodesToRedeem && nodesToRedeem.map((node) => node.nftSerial)
        const isSelected = nftsToRedeem && nftsToRedeem.includes(node.nftSerial)

        let dtmDevicesQuantity: number | null = null
        if (node.slug) {
          dtmDevicesQuantity = getNodeProperty(
            node,
            NodePropertyName.DTM_NODE__DTM_DEVICES,
            []
          ).length
        }

        return (
          <DtmNodeCard
            key={node.id}
            name={`DTM #${String(nftSerial).padStart(4, '0')}`}
            imageUrl={`https://constellation-nfts-assets.s3.amazonaws.com/dtm/${String(
              nftSerial
            ).padStart(4, '0')}.png`}
            redeemStatus={redeemedStatus}
            showCheckmark={isSelected ? true : false}
            disableLink={disableLinks}
            className={{
              root: cls(
                isSelected && styles.selected,
                styles.dtmCardContainer,
                !redeemedStatus && selectNode && styles.cursorPointer
              ),
              footer: styles.footer,
            }}
            onClick={() => {
              node.slug
                ? navigate(`/nodes/dtm-nodes/${node.slug}`)
                : !redeemedStatus && selectNode && selectNode(node)
            }}
            devicesLinkedQuantity={dtmDevicesQuantity}
          />
        )
      })}
    </GridContainer>
  )
}

export { DtmNodeCardGrid }
