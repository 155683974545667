import React from 'react'
import cls from 'classnames'

import { BaseCard, Button } from '@lattice/common/components'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import { APIError } from '@lattice/common/lib'
import { FetchStatus } from '@lattice/common/consts'
import {
  HgtpQueueMetagraph,
  useHgtpMetagraphsProvider,
} from '@lattice/common/providers'

import styles from './component.module.scss'

const NodeOperatorQueuePositionCard = () => {
  const { node, executeDeleteNode, requestNode } = useHgtpMetagraphsProvider()

  const executeDeleteNodeOperation = useFetchableOperation()
  const executeDeleteNodeOperationProgressToasts = useProgressToasts()

  const doExecuteDeleteNode = executeDeleteNodeOperation.wrappedFetch(
    executeDeleteNodeOperationProgressToasts.wrappedErrorsAsync(async () => {
      try {
        const result = window.confirm(
          'Leaving the queue will remove your spot as a potential Pacaswap Metagraph Node Operator. If you choose to rejoin later, you will be placed at the end of the queue.'
        )

        if (!result) {
          return
        }

        executeDeleteNodeOperationProgressToasts.progress(
          'Leaving queue',
          'info',
          null
        )

        await executeDeleteNode(HgtpQueueMetagraph.PACA_SWAP)

        executeDeleteNodeOperationProgressToasts.progress(
          'You have opted-out of the program',
          'success',
          10000
        )

        requestNode(HgtpQueueMetagraph.PACA_SWAP)
      } catch (e) {
        if (
          e instanceof APIError &&
          e.errorCode === 403 &&
          /User is forbidden to take this action/i.test(e.message)
        ) {
          throw new Error(
            "Oops, it seems you're not allowed to execute this action. Contact support for further details."
          )
        } else {
          throw new Error('An error ocurred')
        }
      }
    })
  )

  if (!node.resource) {
    return null
  }

  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        <div className={cls(styles.supertitleText, styles.greenText)}>
          {node.resource.queuePosition}/{node.resource.queueLength ?? 0}
        </div>
        <div className={styles.subtitleText}>Your queue position</div>
        <Button
          variants={['outlined', 'full-width']}
          type="button"
          disabled={executeDeleteNodeOperation.status === FetchStatus.PENDING}
          loading={executeDeleteNodeOperation.status === FetchStatus.PENDING}
          onClick={() => doExecuteDeleteNode()}
        >
          Leave the queue
        </Button>
      </div>
    </BaseCard>
  )
}

export { NodeOperatorQueuePositionCard }
