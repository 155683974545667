import { FetchStatus } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/common/lib'

import { IBounty, IBountyWithNodes } from './types'

const requestBounties = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/bounties',
    })
    return data as IBounty[]
  } catch (err) {
    console.log(err)
    throw err
  }
}

const requestBounty = async (entityId: string) => {
  try {
    const { data: bountyData } = await apiRequest({
      method: 'GET',
      endpoint: `/bounties/${entityId}`,
    })

    const { data: bountyStats } = await apiRequest({
      method: 'GET',
      endpoint: `/bounties/${entityId}/stats`,
    })

    bountyData.stats = bountyStats

    return bountyData as IBounty
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const requestBountyWithNodes = async (entityId: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/bounties/${entityId}/nodes`,
      isAuthenticated: true,
    })
    return data as IBountyWithNodes
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const joinBounty = async (entityId: string, nodeIds: string[]) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/bounties/${entityId}`,
      isAuthenticated: true,
      body: { nodes: nodeIds },
    })
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

export { requestBounties, requestBounty, requestBountyWithNodes, joinBounty }
