import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Decimal from 'decimal.js'

import {
  BaseCard,
  Button,
  CheckboxField,
  InputRow,
  Typography,
} from '@lattice/common/components'
import { useProgressToasts } from '@lattice/common/hooks'
import {
  OptInCollateralRequirementInDAG,
  useIntegrationsNetProvider,
} from '@lattice/common/providers/IntegrationsNetProvider'
import { useWalletProvider } from '@lattice/common/providers'
import { APIError, CurrencyNetwork, NetworkCurrency } from '@lattice/common/lib'

import styles from './component.module.scss'

type NodeOperatorOptInFormData = {
  walletBalance: number
  discordHandle: string
  consents: {
    acceptsRequirements: boolean
  }
}

const NodeOperatorOptInFormCard = () => {
  const executeApplicantOptInProgressToasts = useProgressToasts()
  const { activeWallet, requestConnectorActivation, accountsNetworkAssets } =
    useWalletProvider()
  const { executeApplicantOptIn, requestApplicant } =
    useIntegrationsNetProvider()

  const nodeOperatorOptInForm = useForm<NodeOperatorOptInFormData>({
    mode: 'onTouched',
    defaultValues: {
      walletBalance: 0,
      discordHandle: '',
      consents: { acceptsRequirements: false },
    },
  })

  const validActiveWalletAddress =
    activeWallet.status === 'connected' &&
    activeWallet.constellation &&
    activeWallet.constellation.account

  const doExecuteApplicantOptIn = nodeOperatorOptInForm.handleSubmit(
    executeApplicantOptInProgressToasts.wrappedErrorsAsync(
      async (data: NodeOperatorOptInFormData) => {
        try {
          if (
            activeWallet.status !== 'connected' ||
            !activeWallet.constellation
          ) {
            throw new Error('Wallet is not active')
          }

          executeApplicantOptInProgressToasts.progress(
            'Requesting wallet ownership signature',
            'info',
            null
          )

          const walletOwnershipToken =
            await activeWallet.constellation.requestWalletOwnershipToken()

          executeApplicantOptInProgressToasts.progress(
            'Saving application',
            'info',
            null
          )

          await executeApplicantOptIn({
            discordHandle: data.discordHandle,
            address: activeWallet.constellation.account,
            network: CurrencyNetwork.CONSTELLATION,
            walletOwnershipToken,
          })

          executeApplicantOptInProgressToasts.progress(
            'Your application was saved successfully',
            'success',
            10000
          )

          requestApplicant()
        } catch (e) {
          if (
            e instanceof APIError &&
            e.errorCode === 403 &&
            /User is forbidden to take this action/i.test(e.message)
          ) {
            throw new Error(
              "Oops, it seems you're not allowed to execute this action. Contact support for further details."
            )
          } else {
            throw new Error('An error ocurred')
          }
        }
      }
    )
  )

  useEffect(() => {
    nodeOperatorOptInForm.setValue(
      'walletBalance',
      accountsNetworkAssets[
        NetworkCurrency.CONSTELLATION__DAG
      ]?.balance.toNumber() ?? 0,
      { shouldValidate: true, shouldDirty: true, shouldTouch: true }
    )
  }, [
    validActiveWalletAddress,
    accountsNetworkAssets[NetworkCurrency.CONSTELLATION__DAG],
  ])

  return (
    <BaseCard variants={['bordered', 'section-bar']}>
      <div className={styles.container}>
        <Typography.HeaderCardTitle>
          Want to become a Node Operator?
        </Typography.HeaderCardTitle>
        <Typography.SubtextCard>
          Before joining the waitlist, please read the acceptance criteria
          outlined in the Program Requirements section. <br />
          <br />
          To start opt-in you will need to connect your Stargazer wallet. The
          wallet address will be the used to verify the required DAG balance to
          participate.
        </Typography.SubtextCard>
        {validActiveWalletAddress && (
          <form className={styles.form} onSubmit={doExecuteApplicantOptIn}>
            <Controller
              name="walletBalance"
              rules={{
                required: true,
                validate: {
                  programRequirement: (value) =>
                    new Decimal(value).gte(OptInCollateralRequirementInDAG) ||
                    'Current wallet balance is below the required amount.',
                },
              }}
              control={nodeOperatorOptInForm.control}
              render={({ field: { ref: __, ...rest }, fieldState }) => (
                <InputRow.Numeric
                  variants={['full-width']}
                  label={'Current wallet balance'}
                  error={fieldState.error?.message}
                  allowNegative={false}
                  suffix={' DAG'}
                  disabled
                  readOnly
                  {...rest}
                />
              )}
            />
            <InputRow
              variants={['full-width']}
              label={'Discord handle'}
              placeholder={'Enter your Discord handle'}
              error={
                nodeOperatorOptInForm.formState.errors.discordHandle?.message
              }
              {...nodeOperatorOptInForm.register('discordHandle', {
                required: 'Discord handle is required',
                validate: {
                  notBlank: (value) =>
                    value.trim() !== '' || 'Discord handle cannot be blank',
                },
              })}
            />
            <span className={styles.checkboxRow}>
              <CheckboxField
                {...nodeOperatorOptInForm.register(
                  'consents.acceptsRequirements',
                  {
                    required: true,
                  }
                )}
              />{' '}
              <span>I have read and understand the requirements.</span>
            </span>
            <Button
              variants={['primary', 'full-width']}
              type="submit"
              disabled={
                !nodeOperatorOptInForm.formState.isValid ||
                nodeOperatorOptInForm.formState.isSubmitting
              }
              loading={nodeOperatorOptInForm.formState.isSubmitting}
            >
              Yes, I want to opt-in
            </Button>
          </form>
        )}
        {!validActiveWalletAddress && (
          <Button
            variants={['outlined', 'full-width']}
            onClick={() => requestConnectorActivation()}
          >
            Connect Stargazer wallet
          </Button>
        )}
      </div>
    </BaseCard>
  )
}

export { NodeOperatorOptInFormCard }
