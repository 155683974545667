import React, { useEffect, useState } from 'react'
import cls from 'classnames'
import { ImpulseSpinner } from 'react-spinners-kit'
import { useTranslation } from 'react-i18next'

import { apiRequest } from '@lattice/common/lib'
import { GeneralColor } from '@lattice/components/common/Consts'
import { EnvironmentContext } from '@lattice/runtime'

import commonStyles from '../styles.module.scss'
import { ExolixNetwork, ExolixToken } from '../../types'
import { TokenRow } from '../common/TokenRow'

import styles from './component.module.scss'

const PAGE_SIZE = 25

/**
 * @uses z-index
 * @key SelectTokenModal
 * @value 10
 */
const SelectTokenModal = ({
  handleTokenSelection,
  closeModal,
}: {
  handleTokenSelection: (
    code: string,
    icon: string,
    network: ExolixNetwork
  ) => void
  closeModal: () => void
}) => {
  const { t } = useTranslation()
  const [tokens, setTokens] = useState<ExolixToken[]>([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [query, setQuery] = useState('')

  useEffect(() => {
    const modal = document.getElementById('tokensDisplay')
    modal && modal.addEventListener('scroll', handleScroll)
    return () =>
      modal
        ? modal.removeEventListener('scroll', handleScroll)
        : console.log('')
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setPage(1)
      setTokens([])
      setSearchQuery(query)
    }, 600)
    return () => clearTimeout(timeOutId)
  }, [query])

  const handleScroll = () => {
    const modal = document.getElementById('tokensDisplay')
    if (modal && modal.offsetHeight + modal.scrollTop < modal.scrollHeight) {
      return
    }
    setPage((p) => p + 1)
  }

  const getCurrencies = async (page: string, searchQuery: string) => {
    let data
    try {
      ;({ data } = await apiRequest({
        method: 'POST',
        headers: {
          'x-lattice-api-key': EnvironmentContext.ApiSharedKey,
        },
        endpoint: '/swapping/currencies',
        body: {
          page: page,
          size: PAGE_SIZE,
          search: searchQuery,
          withNetworks: true,
        },
      }))
      page === '1' ? setTokens(data) : setTokens(tokens.concat(data))
      setLoading(false)
    } catch (e) {
      throw e
    }
  }

  useEffect(() => {
    setLoading(true)
    getCurrencies(page.toString(), searchQuery)
  }, [page, searchQuery])

  const selectToken = (code: string, icon: string, network: ExolixNetwork) => {
    handleTokenSelection(code, icon, network)
  }

  return (
    <div className={commonStyles.modalOverlay} onClick={() => closeModal()}>
      <div
        className={styles.tokenSelector}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.searchContainer}>
          <div className={styles.titleRow}>
            <div className={styles.title}>
              {t(
                'components.ExolixSwap.SelectTokenModal.labels.selectToken',
                'Select token'
              )}
            </div>
            <div
              className={cls(styles.title, styles.close)}
              onClick={() => closeModal()}
            >
              x
            </div>
          </div>
          <div className={styles.searchRow}>
            <div className={styles.searchInput}>
              <input
                className={cls(
                  commonStyles.inputAmount,
                  commonStyles.pointer,
                  styles.inputVariant
                )}
                type={'text'}
                placeholder={t(
                  'components.ExolixSwap.SelectTokenModal.labels.searchToken',
                  'Search by name'
                )}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.tokensDisplay} id={'tokensDisplay'}>
          {loading && tokens.length === 0 ? (
            <div className={styles.spinner}>
              <ImpulseSpinner
                size={24}
                frontColor={GeneralColor.USC_GOLD.toString()}
              />
            </div>
          ) : (
            <>
              {tokens.map(
                (token) =>
                  token.networks &&
                  token.networks.map((network, idx) => (
                    <TokenRow
                      key={idx}
                      code={token.code}
                      icon={token.icon}
                      name={token.name}
                      network={network}
                      selectToken={selectToken}
                    />
                  ))
              )}
              {loading && tokens.length > 0 && (
                <div className={styles.spinner}>
                  <ImpulseSpinner
                    size={24}
                    frontColor={GeneralColor.USC_GOLD.toString()}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { SelectTokenModal }
