import { EvmChain } from '../web3'

import { IContractInstanceInfo } from './types'

export const buildUnavailableContractInstances = <T extends EvmChain>(
  ...chains: T[]
) => {
  const instances = {}

  for (const chain of chains) {
    Object.defineProperty(instances, chain, {
      get: () => {
        throw new Error(
          `Contract instance is not available for the ${chain} chain`
        )
      },
    })
  }

  return instances as {
    [K in T]: IContractInstanceInfo<K>
  }
}
