import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Decimal from 'decimal.js'
import cls from 'classnames'
import dayjs, { Dayjs } from 'dayjs'

import { BaseCard, Button, Table, TitleBar } from '@lattice/common/components'
import { ReactComponent as ArrowBackIcon } from '@lattice/assets/icons/feather/arrow-left.svg'
import { ReactComponent as ArrowForwardIcon } from '@lattice/assets/icons/feather/arrow-right.svg'
import { formatNumber, NumberFormat } from '@lattice/utils'
import { FetchStatus, isFetchStatus } from '@lattice/common/consts'
import { useWalletProvider } from '@lattice/common/providers'
import {
  IAirdrop,
  IAirdropAllocationAndAirdrop,
  useAirdropsProvider,
} from '@lattice/common/providers/AirdropsProvider'
import { useFetchableOperation, useProgressToasts } from '@lattice/common/hooks'
import { airdropsToFullAllocations } from '@lattice/common/providers/AirdropsProvider/utils'
import { LatticeGovernanceToken } from '@lattice/common/consts/ethereum/interfaces/LatticeGovernanceToken'
import {
  APIError,
  CurrencyNetwork,
  DeploymentEvmChain,
  DeploymentEvmChains_PerEvmNetwork_PerStage,
  EvmChainData,
  EvmNetwork,
  getNetworkTypedContract,
  isEvmNetwork,
  NetworkCurrenciesUtils,
  NetworkCurrency,
  RegisteredToken,
  RegisteredTokens,
  requestProviderChainUpdateIfNeeded,
} from '@lattice/common/lib'
import { EnvironmentContext } from '@lattice/runtime'

import styles from './view.module.scss'
import { useDagBasedAirdropClaimModal } from './components'

type ILockupData = {
  slotId: number
  lockup: LatticeGovernanceToken.LockupDataStructOutput
}

type ILockupRecord = {
  lockfrom: number
  lockuntil: number
  amount: number
  veltxReceived: number
  data: ILockupData
}

type IAirdropRecord = {
  program: string
  token: string
  distributionStartsAt: Dayjs
  vestingPeriodEndsAt: Dayjs
  totalAmount: Decimal
  claimableAmount: Decimal
  allocation: IAirdropAllocationAndAirdrop
}

const ClaimView = () => {
  const { t } = useTranslation()
  const { activeWallet } = useWalletProvider()
  const {
    airdrops,
    requestAirdropsAndAvailableAmountsPage: requestAirdropsAndAvailableAmounts,
    requestAirdropAllocationProof,
    requestAirdropAllocationClaim,
    requestAirdropAllocationClaimStatus,
  } = useAirdropsProvider()

  const [totalLtxLocked, setTotalLtxLocked] = useState<number | null>(null)
  const [totalVeltxDistributed, setTotalVeltxDistributed] = useState<
    number | null
  >(null)

  const [lockupRecords, setLockupRecords] = useState<ILockupRecord[]>([])
  const unlockOperation = useFetchableOperation()
  const unlockOperationProgressToasts = useProgressToasts()

  const airdropRecords = useMemo(() => {
    const allocations = airdropsToFullAllocations(airdrops.resource)
    const records: IAirdropRecord[] = []

    for (const allocation of allocations) {
      records.push({
        program: allocation.airdrop.name,
        token: allocation.airdrop.rewardCurrency,
        distributionStartsAt: dayjs(allocation.airdrop.distributionStartsAt),
        vestingPeriodEndsAt: dayjs(allocation.airdrop.distributionStartsAt).add(
          dayjs.duration(allocation.airdrop.vestingPeriod)
        ),
        totalAmount: new Decimal(allocation.rewardAmount),
        claimableAmount:
          allocation.airdrop.type === 'ethereum-based'
            ? new Decimal(allocation.amountAvailable ?? 0)
            : allocation.airdrop.type === 'dag-based'
              ? allocation.rewardId === null
                ? new Decimal(allocation.rewardAmount)
                : new Decimal(0)
              : new Decimal(0),
        allocation,
      })
    }

    return records
  }, [airdrops.resource])

  const [dagBasedAirdropModal, requestDagBasedAirdropClaimMessage] =
    useDagBasedAirdropClaimModal()

  const [airdropClaimsInProgress, setAirdropClaimsInProgress] = useState<
    string[]
  >([])
  const claimAirdropProgressToasts = useProgressToasts()

  const addAirdropClaimInProgress = (airdropId: string) => {
    setAirdropClaimsInProgress((s) => [...s, airdropId])
  }

  const removeAirdropClaimInProgress = (airdropId: string) => {
    setAirdropClaimsInProgress((s) => s.filter((i) => i !== airdropId))
  }

  const doClaimEthereumBasedAirdrop =
    claimAirdropProgressToasts.wrappedErrorsAsync(async (airdrop: IAirdrop) => {
      if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
        throw new Error('Wallet is not active')
      }

      if (airdrop.type !== 'ethereum-based') {
        throw new Error('Invalid airdrop type')
      }

      if (!airdrop.allocations) {
        throw new Error('Invalid airdrop data')
      }

      addAirdropClaimInProgress(airdrop.id)

      claimAirdropProgressToasts.progress('Claiming airdrop', 'info', null)

      await requestProviderChainUpdateIfNeeded(
        activeWallet.ethereum.library,
        EvmChainData[
          DeploymentEvmChains_PerEvmNetwork_PerStage[EnvironmentContext.stage][
            NetworkCurrenciesUtils[airdrop.rewardCurrency].network
          ]
        ].rpcChainId
      )

      let allocationProof: Awaited<
        ReturnType<typeof requestAirdropAllocationProof>
      > = FetchStatus.NOT_FOUND

      try {
        allocationProof = await requestAirdropAllocationProof(
          airdrop.slug,
          activeWallet.ethereum.account
        )
      } catch (err) {
        console.log(err)
        if (err instanceof APIError) {
          if (err.errorCode === 500) {
            throw new Error('Error while verifying data')
          }
        }
      }

      if (allocationProof === FetchStatus.NOT_FOUND) {
        throw new Error('User is not available to withdraw')
      }

      const airdropNetwork =
        NetworkCurrenciesUtils[airdrop.rewardCurrency].network

      if (!isEvmNetwork(airdropNetwork)) {
        throw new Error('Invalid RPC network for airdrop')
      }

      const userContract = getNetworkTypedContract(
        'LatticeAirdropsDistributor',
        airdrop.contractAddress,
        airdropNetwork
      ).connect(activeWallet.ethereum.library.getSigner())

      try {
        const trx = await userContract.claim(
          airdrop.contractAirdropId,
          new Decimal(airdrop.allocations[0].rewardAmount)
            .mul(NetworkCurrenciesUtils[airdrop.rewardCurrency].decimalFactor)
            .floor()
            .toFixed(),
          allocationProof.merkleProof
        )

        claimAirdropProgressToasts.progress(
          'Waiting for network confirmation',
          'info',
          null
        )

        await trx.wait(3)
      } catch (err) {
        if (err instanceof Object && err['code'] === 4001) {
          throw new Error('Transaction signature denied')
        } else {
          throw new Error('There was an error while claiming the airdrop')
        }
      }

      removeAirdropClaimInProgress(airdrop.id)

      claimAirdropProgressToasts.progress(
        'Successfully claimed',
        'success',
        15000
      )

      requestAirdropsAndAvailableAmounts(
        airdrops.currentPage,
        activeWallet.ethereum.account
      )
    })

  const doClaimDagBasedAirdrop = claimAirdropProgressToasts.wrappedErrorsAsync(
    async (airdrop: IAirdrop) => {
      if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
        throw new Error('Wallet is not active')
      }

      if (airdrop.type !== 'dag-based') {
        throw new Error('Invalid airdrop type')
      }

      addAirdropClaimInProgress(airdrop.id)

      claimAirdropProgressToasts.progress('Claiming airdrop', 'info', null)

      claimAirdropProgressToasts.progress(
        'Waiting for rewards target selection',
        'info',
        null
      )

      const messageAndSignature =
        await requestDagBasedAirdropClaimMessage(airdrop)

      if (!messageAndSignature) {
        claimAirdropProgressToasts.reset()
        removeAirdropClaimInProgress(airdrop.id)
        return
      }

      claimAirdropProgressToasts.progress(
        'Claiming DAG based airdrop',
        'info',
        null
      )

      await requestAirdropAllocationClaim(
        airdrop.slug,
        activeWallet.ethereum.account,
        messageAndSignature.signature,
        messageAndSignature.message
      )

      claimAirdropProgressToasts.progress(
        'Transaction scheduled for immediate distribution, waiting for confirmation',
        'info',
        null
      )

      let transactionConfirmed = false
      for (let i = 0; i < 500; i++) {
        const statusData = await requestAirdropAllocationClaimStatus(
          airdrop.slug,
          activeWallet.ethereum.account
        )

        if (isFetchStatus(statusData) && statusData === FetchStatus.NOT_FOUND) {
          throw new Error('State Inconsistency Error')
        }

        if (statusData.status === 'confirmed') {
          transactionConfirmed = true
          break
        }

        if (statusData.status === 'dropped') {
          throw new Error(
            'There was an error confirming the transaction, please contact support'
          )
        }

        if (statusData.status === 'unclaimed') {
          throw new Error('State Inconsistency Error')
        }

        await new Promise((rs) => setTimeout(rs, 10000))
      }

      requestAirdropsAndAvailableAmounts(
        airdrops.currentPage,
        activeWallet.ethereum.account
      )

      removeAirdropClaimInProgress(airdrop.id)

      if (transactionConfirmed) {
        claimAirdropProgressToasts.progress(
          'Confirmation received, successfully claimed',
          'success',
          15000
        )
      } else {
        claimAirdropProgressToasts.progress(
          'We were not able to confirm the transaction after some time, please contact support',
          'error',
          null
        )
      }
    }
  )

  const doUnlockTokens = unlockOperation.wrappedFetch(
    unlockOperationProgressToasts.wrappedErrorsAsync(async (slotId: number) => {
      if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
        throw new Error('Wallet is not connected')
      }

      await requestProviderChainUpdateIfNeeded(
        activeWallet.ethereum.library,
        EvmChainData[
          DeploymentEvmChains_PerEvmNetwork_PerStage[EnvironmentContext.stage][
            CurrencyNetwork.ETHEREUM
          ]
        ].rpcChainId
      )

      const veltxToken = getNetworkTypedContract(
        'LatticeGovernanceToken',
        RegisteredTokens[RegisteredToken.veLTX].instances[DeploymentEvmChain]
          .address,
        EvmNetwork.ETHEREUM
      ).connect(activeWallet.ethereum.library.getSigner())

      unlockOperationProgressToasts.progress(
        'Requesting transaction approval',
        'info',
        null
      )

      const trx = await veltxToken.unlock(slotId)

      unlockOperationProgressToasts.progress(
        t(
          'views.Governance.views.Lock.waitingNetwork',
          'Awaiting network confirmation'
        ),
        'info',
        null
      )

      await trx.wait(3)

      unlockOperationProgressToasts.progress(
        'Unlocked successfully',
        'success',
        10000
      )

      fetchStats()
    })
  )

  const fetchStats = async () => {
    if (activeWallet.status !== 'connected' || !activeWallet.ethereum) {
      return
    }

    const veltxToken = getNetworkTypedContract(
      'LatticeGovernanceToken',
      RegisteredTokens[RegisteredToken.veLTX].instances[DeploymentEvmChain]
        .address,
      EvmNetwork.ETHEREUM
    )

    const [veltxTotal, ltxTotal] = await Promise.all([
      veltxToken.balanceOf(activeWallet.ethereum.account),
      veltxToken.ltxLockedBalanceOf(activeWallet.ethereum.account),
    ])

    setTotalVeltxDistributed(
      new Decimal(veltxTotal.toString())
        .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.veLTX].decimals))
        .toNumber()
    )

    setTotalLtxLocked(
      new Decimal(ltxTotal.toString())
        .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals))
        .toNumber()
    )
    const lockupsLength = await veltxToken.lockupSlots(
      activeWallet.ethereum.account
    )

    const transactionRecords: ILockupRecord[] = []

    for (let slotId = 0; slotId < lockupsLength.toNumber(); slotId++) {
      const lockup = await veltxToken.lockups(
        activeWallet.ethereum.account,
        slotId
      )

      transactionRecords.push({
        lockfrom: lockup.fromTimestamp.toNumber(),
        lockuntil: lockup.toTimestamp.toNumber(),
        amount: new Decimal(lockup.amountLocked.toString())
          .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals))
          .toNumber(),
        veltxReceived: new Decimal(lockup.amountReleased.toString())
          .div(
            Decimal.pow(10, RegisteredTokens[RegisteredToken.veLTX].decimals)
          )
          .toNumber(),
        data: { lockup, slotId },
      })
    }

    transactionRecords.sort((a, b) => a.lockfrom - b.lockfrom)

    setLockupRecords(transactionRecords)
  }

  useEffect(() => {
    fetchStats()
    const iid = window.setInterval(fetchStats, 30000)
    return () => {
      window.clearInterval(iid)
    }
  }, [])

  useEffect(() => {
    if (activeWallet.status === 'connected' && activeWallet.ethereum) {
      requestAirdropsAndAvailableAmounts(0, activeWallet.ethereum.account)
    }
  }, [activeWallet])

  return (
    <div className={styles.main}>
      {dagBasedAirdropModal}
      <div className={styles.statsCardsContainer}>
        <BaseCard
          variants={['bordered', 'full-width']}
          className={{ root: styles.statCard, body: styles.body }}
        >
          <span>
            {t('views.Governance.views.Claim.yourLTX', 'Your LTX locked')}
          </span>
          <span className={styles.stat}>
            {totalLtxLocked !== null
              ? formatNumber(totalLtxLocked, NumberFormat.DECIMALS)
              : '--'}
          </span>
        </BaseCard>
        <BaseCard
          variants={['bordered', 'full-width']}
          className={{ root: styles.statCard, body: styles.body }}
        >
          <span>
            {t('views.Governance.views.Claim.yourVeLTX', 'Your veLTX balance')}
          </span>
          <span className={styles.stat}>
            {totalVeltxDistributed !== null
              ? formatNumber(totalVeltxDistributed, NumberFormat.DECIMALS)
              : '--'}
          </span>
        </BaseCard>
      </div>
      <div className={styles.tableContainer}>
        <TitleBar>
          {t('views.Governance.views.Claim.lockDetails', 'Lock details')}
        </TitleBar>
        <Table
          className={{
            root: styles.table,
            headerCell: styles.headerLockCell,
          }}
          data={lockupRecords}
          primaryKey={'amount'}
          emptyState={{
            lockfrom: '---',
            lockuntil: '---',
            amount: '---',
            veltxReceived: '---',
            data: '---',
          }}
          titles={{
            lockfrom: {
              content: t(
                'views.Governance.views.Claim.locksTable.columns.lockfrom',
                'Lock Date'
              ),
              sortable: false,
            },
            lockuntil: {
              content: t(
                'views.Governance.views.Claim.locksTable.columns.lockuntil',
                'Locked Until'
              ),
              sortable: false,
            },
            amount: {
              content: t(
                'views.Governance.views.Claim.locksTable.columns.amount',
                'LTX Locked'
              ),
              sortable: false,
            },
            veltxReceived: {
              content: t(
                'views.Governance.views.Claim.locksTable.columns.veltxReceived',
                'Veltx Received'
              ),
              sortable: false,
            },
            data: {
              content: t(
                'views.Governance.views.Claim.locksTable.columns.actions',
                'Actions'
              ),
              sortable: false,
            },
          }}
          formatData={{
            lockfrom: (value) => dayjs.unix(value).format('DD MMM, YYYY'),
            lockuntil: (value) => dayjs.unix(value).format('DD MMM, YYYY'),
            amount: (value) => formatNumber(value, NumberFormat.DECIMALS),
            veltxReceived: (value) =>
              formatNumber(value, NumberFormat.DECIMALS),
            data: ({ lockup, slotId }) =>
              lockup.withdrawn ? (
                <Button
                  disabled
                  variants={['primary', 'slim']}
                  className={styles.actionButton}
                >
                  Unlocked
                </Button>
              ) : dayjs().isAfter(dayjs.unix(lockup.toTimestamp.toNumber())) ? (
                <Button
                  disabled={unlockOperation.status === FetchStatus.PENDING}
                  onClick={
                    unlockOperation.status === FetchStatus.PENDING
                      ? undefined
                      : () => doUnlockTokens(slotId)
                  }
                  variants={['primary', 'slim']}
                  className={styles.actionButton}
                >
                  Unlock
                </Button>
              ) : (
                <Button
                  disabled
                  variants={['primary', 'slim']}
                  className={styles.actionButton}
                >
                  Locked
                </Button>
              ),
          }}
        />
      </div>
      <div className={styles.tableContainer}>
        <TitleBar>
          {t('views.Governance.views.Claim.airdrops', 'Airdrops')}
        </TitleBar>
        <Table
          className={{
            root: styles.table,
            headerCell: styles.headerAirdropsCell,
            bodyCell: styles.fixedBody,
            cells: {
              header: {
                token: {
                  cell: styles.tokenColumn,
                },
              },
              body: {
                token: {
                  cell: styles.tokenColumn,
                },
              },
            },
          }}
          data={
            airdrops.status !== FetchStatus.DONE
              ? []
              : airdropRecords.map((record) => ({
                  ...record,
                  actions:
                    record.claimableAmount.gt(0) &&
                    !airdropClaimsInProgress.includes(
                      record.allocation.airdrop.id
                    ) ? (
                      <Button
                        variants={['primary', 'full-width']}
                        onClick={
                          record.allocation.airdrop.type === 'ethereum-based'
                            ? () =>
                                doClaimEthereumBasedAirdrop(
                                  record.allocation.airdrop
                                )
                            : record.allocation.airdrop.type === 'dag-based'
                              ? () =>
                                  doClaimDagBasedAirdrop(
                                    record.allocation.airdrop
                                  )
                              : () => void 0
                        }
                        className={styles.actionButton}
                      >
                        Claim
                      </Button>
                    ) : (
                      <Button
                        disabled
                        variants={['primary', 'full-width']}
                        className={styles.actionButton}
                      >
                        {airdropClaimsInProgress.includes(
                          record.allocation.airdrop.id
                        )
                          ? 'Claiming'
                          : 'Claimed'}
                      </Button>
                    ),
                }))
          }
          primaryKey={'token'}
          emptyState={{
            program: '---',
            token: '---',
            distributionStartsAt: '---',
            vestingPeriodEndsAt: '---',
            totalAmount: '---',
            claimableAmount: '---',
            actions: '---',
          }}
          titles={{
            program: {
              content: t(
                'views.Governance.views.Claim.airdropsTable.columns.program',
                'Program'
              ),
              sortable: false,
            },
            token: {
              content: t(
                'views.Governance.views.Claim.airdropsTable.columns.token',
                'Token'
              ),
              sortable: false,
            },
            distributionStartsAt: {
              content: t(
                'views.Governance.views.Claim.airdropsTable.columns.distribution',
                'Distribution Date'
              ),
              sortable: false,
            },
            vestingPeriodEndsAt: {
              content: t(
                'views.Governance.views.Claim.airdropsTable.columns.distribution',
                'Vested Date'
              ),
              sortable: false,
            },
            totalAmount: {
              content: t(
                'views.Governance.views.Claim.airdropsTable.columns.totalAmount',
                'Total Amount'
              ),
              sortable: false,
            },
            claimableAmount: {
              content: t(
                'views.Governance.views.Claim.airdropsTable.columns.claimableAmount',
                'Claimable'
              ),
              sortable: false,
            },
            actions: {
              content: t(
                'views.Governance.views.Claim.airdropsTable.columns.actions',
                'Actions'
              ),
              sortable: false,
            },
          }}
          formatData={{
            token: (value) =>
              NetworkCurrenciesUtils[value as NetworkCurrency].symbol,
            distributionStartsAt: (value) => value.format('DD MMM, YYYY'),
            vestingPeriodEndsAt: (value) => value.format('DD MMM, YYYY'),
            totalAmount: (value) => formatNumber(value, NumberFormat.DECIMALS),
            claimableAmount: (value) =>
              formatNumber(value, NumberFormat.DECIMALS),
          }}
        />
        <div className={cls(styles.pagination)}>
          <div className={styles.paginationButtons}>
            <Button
              disabled={airdrops.currentPage === 0}
              className={styles.pageButton}
              onClick={() =>
                airdrops.currentPage !== 0 &&
                activeWallet.status === 'connected' &&
                activeWallet.ethereum &&
                requestAirdropsAndAvailableAmounts(
                  airdrops.currentPage - 1,
                  activeWallet.ethereum.account
                )
              }
            >
              <ArrowBackIcon className={styles.arrowIcon} />
            </Button>
            <Button
              disabled={airdrops.currentPage === airdrops.totalPages}
              className={styles.pageButton}
              onClick={() =>
                airdrops.currentPage !== airdrops.totalPages &&
                activeWallet.status === 'connected' &&
                activeWallet.ethereum &&
                requestAirdropsAndAvailableAmounts(
                  airdrops.currentPage + 1,
                  activeWallet.ethereum.account
                )
              }
            >
              <ArrowForwardIcon className={styles.arrowIcon} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ClaimView }
