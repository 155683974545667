import { IERC20 } from '@lattice/common/types'

import { EvmChainData, EvmChain, EvmNetwork } from './consts'
import { getNetworkTypedContract } from './contracts'
import { IEvmChainData } from './types'

export const getEvmChainByChainId = (chainId: number) => {
  for (const [chain, chaininfo] of Object.entries(EvmChainData) as [
    EvmChain,
    IEvmChainData,
  ][]) {
    if (!chaininfo.rpcAvailable) {
      continue
    }

    if (chaininfo.rpcChainId === chainId) {
      return chain
    }
  }

  return null
}

export const requestERC20Token = async (contractAddress: string) => {
  const contract = getNetworkTypedContract(
    'ERC20',
    contractAddress,
    EvmNetwork.ETHEREUM
  )

  const symbol = await contract.symbol()
  const decimals = await contract.decimals()

  const token: IERC20 = { address: contractAddress, contract, decimals, symbol }
  return token
}
