import ReactGA from 'react-ga4'

import { EnvironmentContext } from '@lattice/runtime'

const isProduction = EnvironmentContext.nodeEnv === 'production'

const initializeReactGaLib = async () => {
  await ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID ?? '', {
    testMode: !isProduction,
  })
}

export { initializeReactGaLib }
