import { FetchStatus } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/common/lib'

import { IWalletOwnershipToken } from '../WalletProvider'

import {
  ISoftNodesProjectWithStats,
  ISoftNodesUserData,
  IUserWalletSoftNode,
  IUserWalletSoftNodeAndPrograms,
} from './types'

const requestProjects = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/softnode-projects',
    })
    return data as ISoftNodesProjectWithStats[]
  } catch (err) {
    console.log(err)
    throw err
  }
}

const requestProject = async (projectId: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/softnode-projects/${projectId}`,
    })
    return data as ISoftNodesProjectWithStats
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const requestProjectUser = async (projectId: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/softnode-projects/${projectId}/user`,
      isAuthenticated: true,
    })
    return data as ISoftNodesUserData
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const requestUserHistoryProjects = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/softnode-projects/user-history`,
      isAuthenticated: true,
    })
    return data as IUserWalletSoftNodeAndPrograms[]
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const requestUserNodes = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/softnode-projects/user-nodes`,
      isAuthenticated: true,
    })
    return data as IUserWalletSoftNode[]
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}

const stakeWallet = async (
  projectId: string,
  address: string,
  nodesToStake: number,
  walletOwnershipToken: IWalletOwnershipToken
) => {
  try {
    await apiRequest({
      method: 'POST',
      endpoint: `/softnode-projects/${projectId}/stake`,
      isAuthenticated: true,
      body: {
        address,
        nodesToStake,
        walletOwnershipToken,
      },
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

export {
  requestProjects,
  requestProject,
  requestProjectUser,
  stakeWallet,
  requestUserHistoryProjects,
  requestUserNodes,
}
