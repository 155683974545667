import Decimal from 'decimal.js'
import dayjs, { Dayjs } from 'dayjs'

import {
  HgtpNetworkAddresses,
  HgtpNetworkSnapshots,
  isHgtpNetwork,
} from '../hgtp'
import {
  getNetworkProvider,
  getNetworkTypedContract,
  isEvmNetwork,
} from '../web3'

import { CurrencyProtocol } from './consts'
import { INetworkCurrency } from './types'

const EVM_BASE_DECIMALS = 18
const EVM_BASE_FACTOR = new Decimal(10).pow(EVM_BASE_DECIMALS)

const CONSTELLATION_BASE_DECIMALS = 8
const CONSTELLATION_BASE_FACTOR = new Decimal(10).pow(
  CONSTELLATION_BASE_DECIMALS
)

export class NetworkCurrencies {
  static async getCurrencyBlock(
    currency: INetworkCurrency,
    blockRef: number | `${number}` | 'latest'
  ) {
    /**
     * Constellation Protocol
     */
    if (currency.protocol === CurrencyProtocol.CONSTELLATION) {
      if (!isHgtpNetwork(currency.stage)) {
        throw new Error(
          `Value Error: Unable to get currency block, bad currency stage => ${currency.id}`
        )
      }

      if (currency.address === null) {
        const snapshot =
          blockRef === 'latest'
            ? await HgtpNetworkSnapshots.getLatestBESnapshot(currency.stage)
            : await HgtpNetworkSnapshots.getBESnapshot(
                currency.stage,
                parseInt(String(blockRef))
              )

        return {
          block: snapshot.ordinal,
          timestamp: dayjs(snapshot.timestamp),
        }
      }

      const snapshot =
        blockRef === 'latest'
          ? await HgtpNetworkSnapshots.getMetagraphLatestBESnapshot(
              currency.stage,
              currency.address
            )
          : await HgtpNetworkSnapshots.getMetagraphBESnapshot(
              currency.stage,
              currency.address,
              parseInt(String(blockRef))
            )

      return {
        block: snapshot.ordinal,
        timestamp: dayjs(snapshot.timestamp),
      }
    }

    /**
     * EVM Protocol
     */
    if (currency.protocol === CurrencyProtocol.EVM) {
      console.log(
        'NetworkCurrencies: on EVM protocol stage is evaluated from deployment context, upcomming releases will use the currency object value'
      )

      if (!isEvmNetwork(currency.network)) {
        throw new Error(
          `Value Error: Unable to get currency block, bad currency network => ${currency.id}`
        )
      }

      const provider = getNetworkProvider(currency.network)

      const block = await provider.getBlock(
        blockRef === 'latest' ? blockRef : parseInt(String(blockRef))
      )

      return { block: block.number, timestamp: dayjs.unix(block.timestamp) }
    }

    throw new Error(
      `Value Error: Invalid protocol => ${currency.protocol} at => ${currency.id}`
    )
  }

  static async getCurrencyBlockAtTimestamp(
    currency: INetworkCurrency,
    timestamp: Dayjs
  ) {
    const latestBlock = await this.getCurrencyBlock(currency, 'latest')

    // Binary Search
    let startBlock = latestBlock.block
    let endBlock = 0
    let midBlock: number

    while (startBlock >= endBlock) {
      midBlock = Math.floor((startBlock + endBlock) / 2)

      const block = await this.getCurrencyBlock(currency, midBlock)

      if (!block) {
        throw new Error('Unknown Error: Block not found')
      }

      const blockTimestamp = block.timestamp.unix()

      if (blockTimestamp >= timestamp.unix()) {
        startBlock = midBlock - 1
      } else {
        endBlock = midBlock + 1
      }
    }

    return this.getCurrencyBlock(currency, startBlock)
  }

  static async getBalanceAndData(
    currency: INetworkCurrency,
    address: string,
    options?: {
      blockRef?: number | `${number}` | 'latest'
      atTimestamp?: Dayjs
    }
  ) {
    options = options ?? {}
    options.blockRef = options?.blockRef ?? 'latest'
    const block = options.atTimestamp
      ? await this.getCurrencyBlockAtTimestamp(currency, options.atTimestamp)
      : await this.getCurrencyBlock(currency, options.blockRef)

    /**
     * Constellation Protocol
     */
    if (currency.protocol === CurrencyProtocol.CONSTELLATION) {
      if (!isHgtpNetwork(currency.stage)) {
        throw new Error(
          `Value Error: Unable to get currency block, bad currency stage => ${currency.id}`
        )
      }

      if (currency.address === null) {
        const balance = await HgtpNetworkAddresses.getBEAddressBalance(
          currency.stage,
          address,
          block.block
        )

        return {
          block: block.block,
          timestamp: block.timestamp,
          balance: new Decimal(balance.balance).div(CONSTELLATION_BASE_FACTOR),
          decimals: CONSTELLATION_BASE_DECIMALS,
        }
      }

      const balance = await HgtpNetworkAddresses.getMetagraphBEAddressBalance(
        currency.stage,
        currency.address,
        address,
        block.block
      )

      return {
        block: block.block,
        timestamp: block.timestamp,
        balance: new Decimal(balance.balance).div(CONSTELLATION_BASE_FACTOR),
        decimals: CONSTELLATION_BASE_DECIMALS,
      }
    }

    /**
     * EVM Protocol
     */
    if (currency.protocol === CurrencyProtocol.EVM) {
      console.log(
        'NetworkCurrencies: on EVM protocol stage is evaluated from deployment context, upcomming releases will use the currency object value'
      )

      if (!isEvmNetwork(currency.network)) {
        throw new Error(
          `Value Error: Unable to get currency block, bad currency network => ${currency.id}`
        )
      }

      const provider = getNetworkProvider(currency.network)

      if (currency.address === null) {
        const balance = await provider.getBalance(address)

        return {
          block: block.block,
          timestamp: block.timestamp,
          balance: new Decimal(balance.toString()).div(EVM_BASE_FACTOR),
          decimals: EVM_BASE_DECIMALS,
        }
      }

      const contract = getNetworkTypedContract(
        'ERC20',
        currency.address,
        currency.network
      )

      const decimals = await contract.decimals()

      const balance = await contract.balanceOf(address)

      return {
        block: block.block,
        timestamp: block.timestamp,
        balance: new Decimal(balance.toString()).div(
          new Decimal(10).pow(decimals)
        ),
        decimals,
      }
    }

    throw new Error(
      `Value Error: Invalid protocol => ${currency.protocol} at => ${currency.id}`
    )
  }

  static async getBalance(
    currency: INetworkCurrency,
    address: string,
    options?: {
      blockRef?: number | `${number}` | 'latest'
      atTimestamp?: Dayjs
    }
  ) {
    const balance = await this.getBalanceAndData(currency, address, options)

    return balance.balance
  }
}
