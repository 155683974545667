import { AxiosInstance } from 'axios'
import axiosRetry, { exponentialDelay } from 'axios-retry'

export const addRetryBehavior = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: exponentialDelay,
    onRetry: (retryCount, error, config) => {
      console.log(`Request Retry: ${retryCount}`)
      console.log(`Error: ${error.status} - ${error.message}`)
      console.log(
        `Request: ${config.method} - ${config.baseURL ?? '' + config.url ?? ''}`
      )
    },
    shouldResetTimeout: true,
  })
  return axiosInstance
}
