import React, { useEffect } from 'react'

import NodeOperatorBanner from '@lattice/assets/images/pngs/hgtp_metagraphs/pacaswap-operator-banner.png'
import {
  Button,
  Content,
  DetailsMainCardContainer,
  DetailsSideCardsContainer,
  MainProjectCard,
  ProgramDetailProjectContainer,
  Typography,
  DetailsInfoCard,
  Tabs,
} from '@lattice/common/components'
import {
  useUserProvider,
  HgtpMetagraphNodeOperatorStatus,
  HgtpQueueMetagraph,
  useHgtpMetagraphsProvider,
} from '@lattice/common/providers'
import { useSignInRedirect } from '@lattice/common/hooks'

import { Overview, Requirements } from './content'
import {
  NodeOperatorPostNodeFormCard,
  NodeOperatorInfoCard,
  NodeOperatorConfirmationFormCard,
  NodeOperatorQueuePositionCard,
  NodeOperatorNextStepsCard,
} from './components'
import styles from './view.module.scss'

export const PacaSwapNodeOperatorProgramDetailView = () => {
  const { user } = useUserProvider()
  const signInUrl = useSignInRedirect()

  const { node, requestMetagraph, requestNode } = useHgtpMetagraphsProvider()

  useEffect(() => {
    requestMetagraph(HgtpQueueMetagraph.PACA_SWAP)
    requestNode(HgtpQueueMetagraph.PACA_SWAP)
  }, [])

  return (
    <Content>
      <Typography.DetailsTitle
        childNameTitle={'Pacaswap Node Operator Program'}
        parentNameTitle={'Rewards Programs'}
      />
      <ProgramDetailProjectContainer>
        <DetailsMainCardContainer>
          <MainProjectCard
            title={'Pacaswap Node Operator Program'}
            bannerUrl={NodeOperatorBanner}
          >
            <Tabs>
              <Tabs.Tab
                label={'Overview'}
                content={
                  <Typography.MarkdownContent
                    className={styles.overviewSection}
                  >
                    {Overview}
                  </Typography.MarkdownContent>
                }
              />
              <Tabs.Tab
                label={'Requirements'}
                content={
                  <Typography.MarkdownContent>
                    {Requirements}
                  </Typography.MarkdownContent>
                }
              />
            </Tabs>
          </MainProjectCard>
        </DetailsMainCardContainer>
        <DetailsSideCardsContainer>
          {!user && (
            <DetailsInfoCard
              label={'Please sign in to your account'}
              actionElement={
                <Button.Link
                  variants={['full-width', 'secondary', 'right-icon']}
                  to={signInUrl}
                >
                  Sign in
                </Button.Link>
              }
              variants={['full-width']}
            />
          )}

          {user && !node.resource && <NodeOperatorPostNodeFormCard />}

          {user &&
            node.resource &&
            node.resource.status ===
              HgtpMetagraphNodeOperatorStatus.IN_QUEUE && (
              <NodeOperatorQueuePositionCard />
            )}

          {user &&
            node.resource &&
            node.resource.status ===
              HgtpMetagraphNodeOperatorStatus.SELECTED && (
              <>
                <NodeOperatorNextStepsCard />
                <NodeOperatorConfirmationFormCard />
              </>
            )}

          {user &&
            node.resource &&
            node.resource.status ===
              HgtpMetagraphNodeOperatorStatus.OPERATOR_REGISTERED && (
              <NodeOperatorInfoCard
                variants={['white-title']}
                title="Node details received!"
                secondaryContent="A team lead will reach out to you with onboarding steps through our Constellation Discord soon."
              />
            )}
        </DetailsSideCardsContainer>
      </ProgramDetailProjectContainer>
    </Content>
  )
}
