import dayjs from 'dayjs'

import { FetchStatus } from '@lattice/common/consts'
import { APIError, apiRequest } from '@lattice/common/lib'

import { IOnChainStakingProject } from './types'

export const getTimelineText = (
  status: IOnChainStakingProject['status'],
  stakingStartsAt: string,
  stakingWindowEndsAt: string,
  stakingEndsAt: string
) => {
  if (['distributing', 'complete'].includes(status)) {
    return 'Ended on ' + dayjs(stakingEndsAt).format('MMM DD, YYYY')
  }
  if (status === 'in_progress') {
    return (
      'Ends in ' + dayjs.duration(dayjs(stakingEndsAt).diff(dayjs())).humanize()
    )
  }
  if (status === 'staking_open') {
    return (
      'Enroll within ' +
      dayjs.duration(dayjs(stakingWindowEndsAt).diff(dayjs())).humanize()
    )
  }
  if (status === 'coming_soon') {
    return (
      'Enrollment starts on ' + dayjs(stakingStartsAt).format('MMM DD, YYYY')
    )
  }

  return '...'
}

export const requestProjects = async () => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: '/staking-projects',
    })
    return data as IOnChainStakingProject[]
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const requestProject = async (projectId: string) => {
  try {
    const { data } = await apiRequest({
      method: 'GET',
      endpoint: `/staking-projects/${projectId}`,
    })
    return data as IOnChainStakingProject
  } catch (err) {
    if (err instanceof APIError) {
      if (err.errorCode === 404) {
        return FetchStatus.NOT_FOUND
      }
    }

    throw err
  }
}
