import * as ethers from 'ethers'
import { deepCopy } from '@ethersproject/properties'
import { ConnectionInfo } from '@ethersproject/web'
import type { providers } from 'ethers'
import { fetchJson } from 'ethers/lib/utils'

import { EnvironmentContext } from '@lattice/runtime'

import {
  generateRequestSignature,
  getOrFetchRequestToken,
  SecuredProxyApiConfig as SecuredProxiedApiConfig,
} from '../secured_proxied_api'

import {
  DeploymentEvmChains_PerNetwork,
  EvmChainData,
  EvmNetwork,
} from './consts'

export class ProxiedRpcProvider extends ethers.providers.JsonRpcProvider {
  private getResult(payload: {
    error?: { code?: number; data?: any; message?: string }
    result?: any
  }): any {
    if (payload.error) {
      // @TODO: not any
      const error: any = new Error(payload.error.message)
      error.code = payload.error.code
      error.data = payload.error.data
      throw error
    }

    return payload.result
  }

  async getTokenAndSignature(body: any) {
    if (
      !this.connection.url.startsWith(EnvironmentContext.SecuredProxiedApiUrl)
    ) {
      return [undefined, undefined] as const
    }

    const url = new URL(this.connection.url)

    const [method, service, ...paths] = url.pathname
      .split('/')
      .filter((p) => p.trim() !== '')

    if (method !== 'external-requests') {
      return [undefined, undefined] as const
    }

    const token = await getOrFetchRequestToken()
    const signature = generateRequestSignature(token, {
      service,
      path: '/' + paths.join('/'),
      searchParams: '?' + url.searchParams,
      rawBody: JSON.stringify(body),
    })

    return [token, signature] as const
  }

  async send(method: string, params: Array<any>): Promise<any> {
    const request = {
      method: method,
      params: params,
      id: this._nextId++,
      jsonrpc: '2.0',
    }

    this.emit('debug', {
      action: 'request',
      request: deepCopy(request),
      provider: this,
    })

    // We can expand this in the future to any call, but for now these
    // are the biggest wins and do not require any serializing parameters.
    const cache = ['eth_chainId', 'eth_blockNumber'].indexOf(method) >= 0
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (cache && this._cache[method]) {
      return this._cache[method]
    }

    const [token, signature] = await this.getTokenAndSignature(request)

    const connectionObject: ConnectionInfo = {
      ...this.connection,
      // url: token ? this.connection.url + '/' : this.connection.url,
      headers: {
        ...this.connection.headers,
        [SecuredProxiedApiConfig.tokenHeader]: token ?? '',
        [SecuredProxiedApiConfig.signatureHeader]: signature ?? '',
      },
    }

    const result = await fetchJson(
      connectionObject,
      JSON.stringify(request),
      this.getResult
    ).then((result) => {
      this.emit('debug', {
        action: 'response',
        request,
        response: result,
        provider: this,
      })
      return result
    })

    // Cache the fetch, but clear it on the next event loop
    if (cache) {
      this._cache[method] = result
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this._cache[method] = null
      }, 0)
    }

    return result
  }
}

export const getNetworkProvider = (
  network: EvmNetwork
): ethers.providers.JsonRpcProvider => {
  const chainData = EvmChainData[DeploymentEvmChains_PerNetwork[network]]

  return new ProxiedRpcProvider(chainData.rpcEndpoint, chainData.rpcChainId)
}

export const requestProviderChainUpdateIfNeeded = async (
  provider: providers.Web3Provider,
  targetChain: number
) => {
  const currentNetwork = await provider.getNetwork()

  if (currentNetwork.chainId === targetChain) {
    return
  }

  await provider.send('wallet_switchEthereumChain', [
    {
      chainId: '0x' + targetChain.toString(16),
    },
  ])
}
