// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpJyAqf9xlrDDAl2EYDA,.UpJyAqf9xlrDDAl2EYDA>div{font-size:16px;font-weight:400;color:rgba(255,255,255,.96);text-align:center}.dWPkbXCVrEp5oSWf6OqD{font-size:20px;font-weight:300}`, "",{"version":3,"sources":["webpack://./src/common/components/Typography/components/HeaderCardTitle/component.module.scss"],"names":[],"mappings":"AAEA,gDAEE,cAAA,CACA,eAAA,CACA,2BAAA,CACA,iBAAA,CAGF,sBACE,cAAA,CACA,eAAA","sourcesContent":["@use 'common/styles/colors' as *;\n\n.headerCardTitle,\n.headerCardTitle > div {\n  font-size: 16px;\n  font-weight: 400;\n  color: rgba(#fff, 0.96);\n  text-align: center;\n}\n\n.bigger {\n  font-size: 20px;\n  font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerCardTitle": `UpJyAqf9xlrDDAl2EYDA`,
	"bigger": `dWPkbXCVrEp5oSWf6OqD`
};
export default ___CSS_LOADER_EXPORT___;
