import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Decimal from 'decimal.js'
import cls from 'classnames'

import { CardRow, InfoCard } from '@lattice/common/components'
import { formatNumber, NumberFormat } from '@lattice/utils'
import {
  DeploymentEvmChain,
  EvmNetwork,
  getNetworkTypedContract,
  RegisteredToken,
  RegisteredTokens,
} from '@lattice/common/lib'

import styles from './component.module.scss'

const GovernanceCard = () => {
  const { t } = useTranslation()
  const [totalLtxLocked, setTotalLtxLocked] = useState<number | null>(null)
  const [totalVeltxDistributed, setTotalVeltxDistributed] = useState<
    number | null
  >(null)
  const fetchStats = async () => {
    const veltxToken = getNetworkTypedContract(
      'LatticeGovernanceToken',
      RegisteredTokens[RegisteredToken.veLTX].instances[DeploymentEvmChain]
        .address,
      EvmNetwork.ETHEREUM
    )
    const [veltxTotal, ltxTotal] = await Promise.all([
      veltxToken.totalSupply(),
      veltxToken.totalLtxLockedSupply(),
    ])

    setTotalVeltxDistributed(
      new Decimal(veltxTotal.toString())
        .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.veLTX].decimals))
        .toNumber()
    )

    setTotalLtxLocked(
      new Decimal(ltxTotal.toString())
        .div(Decimal.pow(10, RegisteredTokens[RegisteredToken.LTX].decimals))
        .toNumber()
    )
  }

  useEffect(() => {
    fetchStats()
    const iid = window.setInterval(fetchStats, 30000)
    return () => {
      window.clearInterval(iid)
    }
  }, [])

  return (
    <InfoCard
      headerText={t('views.Governance.title.Governance', 'Governance')}
      linkTo={'/governance/stats'}
    >
      <CardRow>
        <div className={styles.row}>
          <div className={styles.sideBlock}>
            <div className={styles.governanceTitle}>
              {t('views.Governance.views.Stats.totalLTX', 'Total LTX locked')}
            </div>
            <div className={styles.governanceAmount}>
              {totalLtxLocked !== null
                ? formatNumber(totalLtxLocked, NumberFormat.MILLIFY_EXPANDED)
                : '--'}
            </div>
          </div>
          <div className={styles.sideBlock}>
            <div className={styles.governanceTitle}>
              {t(
                'views.Governance.views.Stats.totalVeLTX',
                'Total veLTX distributed'
              )}
            </div>
            <div className={cls(styles.governanceAmount, styles.rightAligned)}>
              {totalVeltxDistributed !== null
                ? formatNumber(
                    totalVeltxDistributed,
                    NumberFormat.MILLIFY_EXPANDED
                  )
                : '--'}
            </div>
          </div>
        </div>
        <div className={styles.text}></div>
      </CardRow>
      <div className={styles.governanceTextWrapper}>
        <div className={styles.text}>
          {t(
            'views.Dashboard.governanceInvitationText',
            `Participate in Lattice Governance by locking up LTX to get veLTX. The
                longer you lock, the more veLTX and airdrop rewards you’ll receive
                through our fee sharing model. veLTX holders can also vote on
                proposals and stake locked LTX feelessly in Launchpad projects.`
          )}
        </div>
      </div>
    </InfoCard>
  )
}

export { GovernanceCard }
