import { buildIsValidEnumMember } from '@lattice/utils'

export enum CurrencyProtocol {
  CONSTELLATION = 'constellation',
  EVM = 'evm',
}

export const isCurrencyProtocol =
  buildIsValidEnumMember<CurrencyProtocol>(CurrencyProtocol)

export enum CurrencyNetwork {
  CONSTELLATION = 'constellation',
  POLYGON = 'polygon',
  ETHEREUM = 'ethereum',
}

export const isCurrencyNetwork =
  buildIsValidEnumMember<CurrencyNetwork>(CurrencyNetwork)
