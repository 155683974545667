import { DeploymentStage, EnvironmentContext } from '@lattice/runtime'
import { buildIsValidEnumMember } from '@lattice/utils'

import { IHgtpNetworkUrls } from './types'

export enum HgtpNetwork {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
  INTEGRATIONNET = 'integrationnet',
}

export const isHgtpNetwork = buildIsValidEnumMember<HgtpNetwork>(HgtpNetwork)

export const DeploymentHgtpNetworks: Record<DeploymentStage, HgtpNetwork> = {
  [DeploymentStage.PRODUCTION]: HgtpNetwork.MAINNET,
  [DeploymentStage.STAGING]: HgtpNetwork.INTEGRATIONNET,
  [DeploymentStage.DELTA]: HgtpNetwork.TESTNET,
  [DeploymentStage.ALPHA]: HgtpNetwork.TESTNET,
  [DeploymentStage.THETA]: HgtpNetwork.TESTNET,
  [DeploymentStage.TEST]: HgtpNetwork.INTEGRATIONNET,
}

export const DeploymentHgtpNetwork =
  DeploymentHgtpNetworks[EnvironmentContext.stage]

export const HgtpNetworksUrls: Record<HgtpNetwork, IHgtpNetworkUrls> = {
  [HgtpNetwork.MAINNET]: {
    SnapshotsUrl:
      'https://be-mainnet.constellationnetwork.io/global-snapshots/',
    TransactionsUrl: 'https://be-mainnet.constellationnetwork.io/transactions',
    CurrencyUrl: 'https://be-mainnet.constellationnetwork.io/currency',
    BlockExplorerUrl: 'https://be-mainnet.constellationnetwork.io',
    WalletNetworkInfo: {
      id: 'mainnet',
      networkVersion: '2.0',
      l0Url: 'https://l0-lb-mainnet.constellationnetwork.io',
      l1Url: 'https://l1-lb-mainnet.constellationnetwork.io',
      beUrl: 'https://be-mainnet.constellationnetwork.io',
    },
  },
  [HgtpNetwork.TESTNET]: {
    SnapshotsUrl:
      'https://be-testnet.constellationnetwork.io/global-snapshots/',
    TransactionsUrl: 'https://be-testnet.constellationnetwork.io/transactions',
    CurrencyUrl: 'https://be-testnet.constellationnetwork.io/currency',
    BlockExplorerUrl: 'https://be-testnet.constellationnetwork.io',
    WalletNetworkInfo: {
      id: 'testnet',
      networkVersion: '2.0',
      l0Url: 'https://l0-lb-testnet.constellationnetwork.io',
      l1Url: 'https://l1-lb-testnet.constellationnetwork.io',
      beUrl: 'https://be-testnet.constellationnetwork.io',
    },
  },
  [HgtpNetwork.INTEGRATIONNET]: {
    SnapshotsUrl:
      'https://be-integrationnet.constellationnetwork.io/global-snapshots/',
    TransactionsUrl:
      'https://be-integrationnet.constellationnetwork.io/transactions',
    CurrencyUrl: 'https://be-integrationnet.constellationnetwork.io/currency',
    BlockExplorerUrl: 'https://be-integrationnet.constellationnetwork.io',
    WalletNetworkInfo: {
      id: 'integrationnet',
      networkVersion: '2.0',
      l0Url: 'https://l0-lb-integrationnet.constellationnetwork.io',
      l1Url: 'https://l1-lb-integrationnet.constellationnetwork.io',
      beUrl: 'https://be-integrationnet.constellationnetwork.io',
    },
  },
}
