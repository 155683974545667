export const isValidEnumMember = <E>(base: any, value: any): value is E =>
  Object.values(base).includes(value)

export const buildIsValidEnumMember =
  <E>(base: any) =>
  (value: any): value is E =>
    isValidEnumMember(base, value)

export const mixinIsValidEnumMember = <E>(base: any) =>
  Object.assign(base, { isMember: buildIsValidEnumMember<E>(base) })
